import React, { useState, useEffect } from "react";
import axios from "axios";
import makeAnimated from "react-select/animated";
import { useLocation } from "react-router-dom";

import { Col, Row, Card, Dropdown, Button } from "react-bootstrap";
import Select from "react-select";
import { Base_Url } from "../../../../misc";

/*...*/
const Editpage = () => {
  const [listData, setListData] = useState();
  const location = useLocation();
  const id = location.state;
  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState();
  const [cat, setCat] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [SecondselectedOptions, setTwoSelectedOptions] = useState();

  console.log(location.state);
  useEffect(() => {
    axios.get(`${Base_Url}/get-single-home-page-sections/${id}`).then((res) => {
      setListData(res.data.data);
    });
    getCat();
  }, []);

  useEffect(() => {
    if (cat && cat !== undefined) {
      const tttt = [];
      cat.forEach((el) => {
        const data = { value: el._id, label: el.categoryName };

        tttt.push(data);
        setOptions(tttt);
      });
    }
  }, [cat]);
  const getCat = () => {
    axios
      .get(`${Base_Url}/get-category`)
      .then((res) => {
        setCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [layoutOne, setLayoutOne] = useState({
    layoutName: "",
    layoutOrderby: "",
    layoutDisplayLimit: "",
    layoutTwoName: "",
    layoutTwoOrderby: "",
    layoutTwoDisplayLimit: "",
  });

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" &&
    newContact.layoutOrderby !== "" &&
    newContact.layoutDisplayLimit !== "" &&
    newContact.layoutTwoName !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  let finalPostdata = {
    sectionTitle1: layoutOne.layoutName,
    sectionTitle2: layoutOne.layoutTwoName,
    sectionTitle3: "sectionTitle3",
    sectionTitle4: "sectionTitle4",
    sectionStyle: "Style1",
    sectionCategoriesColor: ["black"],
    orderBy: layoutOne.layoutOrderby,
    status: true,
    adImage: "",
    adUrl: "",
    adCode: "123213",
    displayLimit: layoutOne.layoutDisplayLimit,
    sectionCategories1: selectedOptions,
    sectionCategories2: SecondselectedOptions,
    sectionCategories3: [
      {
        title: "",
      },
    ],
    sectionCategories4: [
      {
        title: "",
      },
    ],
  };
  console.log(finalPostdata);

  const updateStyle = () => {
    axios
      .post(`${Base_Url}/update-home-page-sections/${id}`, finalPostdata)
      .then((res) => {
        console.log("Updated", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h4>Design Section One (Two Column 4 Foods)</h4>
      {listData !== undefined ? (
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-block">
                <Card.Title>Section Layout One </Card.Title>
                <Card.Text className="m-0 subtitle">
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Section Title
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              type="text"
                              className="form-control"
                              id="val-username"
                              placeholder="Enter a section Title.."
                              label="layoutName:"
                              name="layoutName"
                              defaultValue={listData[0]?.sectionTitle1}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Section Category
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <Select
                              defaultValue={listData[0].sectionCategories1}
                              value={selectedOptions}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              // defaultValue={selectedOptions}
                              onChange={(item) => setSelectedOptions(item)}
                              classNamePrefix="select"
                              // defaultValue={[Options[4], Options[5]]}
                              isMulti
                              options={options}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header className="d-block">
                <Card.Title>Section Layout Two</Card.Title>
                <Card.Text className="m-0 subtitle">
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Section Title
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              type="text"
                              className="form-control"
                              id="val-username"
                              placeholder="Enter a Section Title.."
                              label="layoutTwoName:"
                              name="layoutTwoName"
                              defaultValue={listData[0]?.sectionTitle2}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Section Category
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <Select
                              value={SecondselectedOptions}
                              defaultValue={listData[0].sectionCategories2}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              // defaultValue={selectedOptions}
                              onChange={(item) => setTwoSelectedOptions(item)}
                              classNamePrefix="select"
                              // defaultValue={[Options[4], Options[5]]}
                              isMulti
                              options={options}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-username">
              Section Orderby
              <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <input
                type="text"
                className="form-control"
                id="val-username"
                placeholder="Enter a section Orderby.."
                label="layoutOrderby:"
                name="layoutOrderby"
                defaultValue={listData[0].orderBy}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-username">
              Section Displaylimit
              <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <input
                type="text"
                className="form-control"
                id="val-username"
                placeholder="Enter a section Display Limit.."
                label="layoutDisplayLimit:"
                name="layoutDisplayLimit"
                defaultValue={listData[0].displayLimit}
                onChange={handleChange}
              />
            </div>
          </div>
        </Row>
      ) : (
        ""
      )}

      <Button
        onClick={() => {
          updateStyle();
        }}
      >
        Update
      </Button>
    </div>
  );
};

export default Editpage;
