import axios from "axios";
import React, { useState, useEffect } from "react";
import labels from "./recipe.json";
import {
  Button,
  Dropdown,
  Pagination,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { Base_Url } from "../../../misc";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  AiFillCodepenSquare,
  AiFillDelete,
  AiFillEdit,
  AiFillEye,
  AiFillInteraction,
} from "react-icons/ai";
import MyPagination from "../Pagination/MyPagination";
import { getUserDetails } from "../../../Helper/SessionHelper";
import moment from "moment";
//const PAGE_SIZE = 5;

const RecipeList = () => {
  const [sellerData, setSellerData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sellerApprovalFilter, setSellerApprovalFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [dropValue, setDropValue] = useState("Order by");
  const [filter, setfilter] = useState("filter by");
  const [apiPagination, setApiPagination] = useState("Sort by");
  const [PAGE_SIZE, setPAGE_SIZE] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    axios
      .get(`${Base_Url}/get-recipe`)
      .then((res) => {
        setSellerData(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${Base_Url}/delete-recipe/${id}`)
      .then((res) => {
        console.log(res.data.data.deletedCount);
        if (res.data.data.deletedCount === 1) {
          const updateData = sellerData.filter((r) => r._id !== id);
          setSellerData(updateData);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
      }
    });
  };
  console.log("Total Pages: " + totalPages);
  const showComment = async (_id, comment) => {
    console.log(_id);
    console.log(comment);
    await Swal.fire({
      title: "Update comment",
      html: `
      <div class="row justify-content-start">
      <div class="col-12">
          <label for="comment" class="text-left" style="position: relative; left: -154px;">Comment: </label>
          <textarea id="comment" height="40" class="form-control" >${
            comment.message
          }</textarea>
      </div>
      <div class="col-12">
          <label for="hightlight" class="text-left" style="position: relative; left: -125px;">hightlights: ${
            comment.hightlight
          }</label>
          <select id="hightlight" class="form-control" defaultValue=${
            comment.hightlight
          } >
              <option value="urgent">Urgent</option>
              <option value="normal">Normal</option>
          </select>
      </div>
      <div class="col-12 mt-2">
      <div style="text-align: left;">
     
  <label for="forAdmin" class="text-left"> For Admin: ${
    comment.forAdmin ? "Yes" : "No"
  }</label><br>
  <select id="forAdmin" class="form-control" defaultValue=${comment.forAdmin} >
  <option value="true">Yes</option>
  <option value="false">No</option>
</select>
  </div>
  <div style="text-align: left;">
     
      <label for="forUser" class="text-left"> For User: ${
        comment.forUser ? "Yes" : "No"
      }</label><br>
      <select id="forUser" class="form-control" defaultValue=${
        comment.forUser
      } >
  <option value="true">Yes</option>
  <option value="false">No</option>
</select>
  </div>

  <div style="text-align: left;">
     
      <label for="adminResolved" class="text-left"> Admin Resolved: ${
        comment.adminResolved ? "Yes" : "No"
      }</label><br>
      <select id="adminResolved" class="form-control" defaultValue=${
        comment.adminResolved
      } >
  <option value="true">Yes</option>
  <option value="false">No</option>
</select>
  </div>

  <div style="text-align: left;">
     
  <label for="userResolved" class="text-left"> User Resolved:  ${
    comment.userResolved ? "Yes" : "No"
  }</label><br>
  <select id="userResolved" class="form-control" defaultValue=${
    comment.userResolved
  } >
  <option value="true">Yes</option>
  <option value="false">No</option>
</select>
  </div>
      </div>
  </div>
  
</div>

    `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Update",
      preConfirm: () => {
        return {
          comment: document.getElementById("comment").value,
          hightlight: document.getElementById("hightlight").value,

          forAdmin: document.getElementById("forAdmin").value,
          forUser: document.getElementById("forUser").value,
          adminResolved: document.getElementById("adminResolved").value,
          userResolved: document.getElementById("userResolved").value,
        };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const {
          comment,
          hightlight,
          forAdmin,
          forUser,
          adminResolved,
          userResolved,
        } = result.value;
        console.log(
          comment,
          hightlight,
          forAdmin,
          forUser,
          adminResolved,
          userResolved
        );

        // handle comments and hightlights
        handleComment(
          _id,
          comment,
          hightlight,
          forAdmin,
          forUser,
          adminResolved,
          userResolved
        );
      }
    });
  };
  const comment = async (_id) => {
    await Swal.fire({
      title: "Add comment",
      html: `
      <div class="row justify-content-start">
      <div class="col-12">
          <label for="comment" class="text-left" style="position: relative; left: -154px;">Comment:</label>
          <textarea id="comment" height="40" class="form-control"></textarea>
      </div>
      <div class="col-12">
          <label for="hightlight" class="text-left" style="position: relative; left: -154px;">hightlights:</label>
          <select id="hightlight" class="form-control">
              <option value="urgent">Urgent</option>
              <option value="normal">Normal</option>
          </select>
      </div>
      <div class="col-12 mt-2">
      <div style="text-align: left;">
      <input type="checkbox" id="forAdmin" name="forAdmin" value="true">
  <label for="forAdmin" class="text-left"> For Admin</label><br>
  </div>
  <div style="text-align: left;">
      <input type="checkbox" id="forUser" name="forUser" value="true">
      <label for="forUser" class="text-left"> For User</label><br>
  </div>

  <div style="text-align: left;">
      <input type="checkbox" id="adminResolved" name="adminResolved" value="true">
      <label for="userResolved" class="text-left"> Admin Resolved</label><br>
  </div>

  <div style="text-align: left;">
      <input type="checkbox" id="userResolved" name="userResolved" value="true">
  <label for="userResolved" class="text-left"> User Resolved</label><br>
  </div>
      </div>
  </div>
  
</div>

    `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Add them!",
      preConfirm: () => {
        return {
          comment: document.getElementById("comment").value,
          hightlight: document.getElementById("hightlight").value,

          forAdmin: document.getElementById("forAdmin").checked,
          forUser: document.getElementById("forUser").checked,
          adminResolved: document.getElementById("adminResolved").checked,
          userResolved: document.getElementById("userResolved").checked,
        };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const {
          comment,
          hightlight,
          forAdmin,
          forUser,
          adminResolved,
          userResolved,
        } = result.value;
        console.log(
          comment,
          hightlight,
          forAdmin,
          forUser,
          adminResolved,
          userResolved
        );

        // handle comments and hightlights
        handleComment(
          _id,
          comment,
          hightlight,
          forAdmin,
          forUser,
          adminResolved,
          userResolved
        );
      }
    });
  };

  const handleComment = (
    id,
    comment,
    hightlight,
    forAdmin,
    forUser,
    adminResolved,
    userResolved
  ) => {
    console.log(comment);
    console.log(hightlight);
    console.log(forAdmin);
    console.log(forUser);
    console.log(adminResolved);
    console.log(userResolved);

    axios
      .post(`${Base_Url}/update-recipe/${id}`, {
        comment: {
          message: comment,
          hightlight: hightlight,
          forAdmin: forAdmin,
          adminID: getUserDetails()._id,
          forUser: forUser,
          adminResolved: adminResolved,
          userResolved: userResolved,
        },
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = sellerData.filter((r) => r._id !== id);
          setSellerData(updateData);
          Swal.fire({
            icon: "success",
            title: "Comment added Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Filtering Logic
  useEffect(() => {
    let filtered = sellerData;

    if (searchTerm.trim() !== "") {
      filtered = filtered.filter(
        (item) =>
          item?.recipeName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.seller[0]?.kitchenName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, sellerData]);

  const [resCat, setResCat] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  console.log(selectedCat);

  useEffect(() => {
    console.log("Fire!");
    let filtered = sellerData;
    filtered.filter((item) => {
      return item._id === selectedCat;
    });
    setFilteredData(filtered);
  }, [selectedCat]);

  useEffect(() => {
    const getResCategory = (async) => {
      fetch(`${Base_Url}/get-recipe-categories`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data, "get-recipe-category");
          setResCat(data.data);
        });
    };
    getResCategory();
  }, []);

  // Pagination Logic
  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    const indexOfLastItem = currentPage * PAGE_SIZE;
    const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
    let itemsToDisplay = [];

    if (selectedCat) {
      // itemsToDisplay = sellerData.filter(item => item.recipeCategoryID === selectedCat)
      //   .slice(indexOfFirstItem, indexOfLastItem);
      itemsToDisplay = sellerData.filter(
        (item) => item.recipeCategoryID === selectedCat
      );
    } else {
      // itemsToDisplay = (filteredData.length > 0 ? filteredData : sellerData)
      //   .slice(indexOfFirstItem, indexOfLastItem);
      itemsToDisplay = filteredData.length > 0 ? filteredData : sellerData;
    }

    setCurrentItems(itemsToDisplay.slice(indexOfFirstItem, indexOfLastItem));
    console.log("Items to Display: " + itemsToDisplay.length);
    setTotalPages(Math.ceil(itemsToDisplay.length / PAGE_SIZE));
  }, [currentPage, filteredData, sellerData, selectedCat]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // const totalPages = Math.ceil(
  //   (filteredData.length > 0 ? filteredData.length : sellerData.length) /
  //     PAGE_SIZE
  // );

  return (
    <div>
      <div
        className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="input-group search-area2 style-1 w-[75%] border d-flex"
          style={{ marginRight: "15px" }}
        >
          <span className="input-group-text p-0">
            <Link to={"#"}>
              <svg
                className="me-1"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                  fill="#FC8019"
                />
              </svg>
            </Link>
          </span>

          <input
            type="text"
            className="form-control p-2 "
            placeholder="Filter by Recipe name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div
          className="form-group "
          style={{ position: "relative", left: "-1px", paddingRight: "5px" }}
        >
          <select
            required
            name="recipeCategory"
            id="inputState"
            className="form-control"
            onChange={(e) => {
              setSelectedCat(e.target.value);
            }}
          >
            <option value="">{"All Category"}</option>
            {resCat.map((item, index) => (
              <option key={index} value={item._id}>
                {item.categoryName}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group ml-2 pl-2 style-1 w-50">
          {/* Search filter */}
          <Dropdown
            className="notification-select "
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {dropValue}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setDropValue("Date")}
                eventKey="Activity"
              >
                Date
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setDropValue("Ratings")}
                eventKey="Activity"
              >
                Ratings
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Filter by */}
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {filter}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("Quantity")}
                eventKey="Activity"
              >
                Quantity
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("Preparation Time")}
                eventKey="Activity"
              >
                Preparation Time
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/*  Sort by */}
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {apiPagination}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                1-50
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                51-100
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                101-150
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("151-200")}
                eventKey="Activity"
              >
                151-200
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      </div>

      <div
        className="card h-auto "
        //style={{ marginTop: 10, marginLeft: -160, width: "127%" }}
      >
        <div className="card-body p-0">
          <div className="table-responsive">
            {sellerData === undefined ? (
              "Loading..."
            ) : (
              <>
                <table
                  className="table table-list i-table style-1 mb-4 border-0"
                  id="guestTable-all3"
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Recipe ID</th>
                      <th>Recipe Name</th>
                      <th>Recipe Photo</th>
                      <th>Posted By</th>
                      {/* <th>Preparation Time</th> */}
                      {/* <th>Recipe Tips</th> */}
                      <th>Created Date</th>
                      <th>View Count</th>
                      <th>Action</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          {/* food Name */}
                          <td>
                            <div className="media-bx d-flex py-3 align-items-center">
                              <div>
                                {index + 1 + PAGE_SIZE * (currentPage - 1)}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-bx d-flex py-3 align-items-center">
                              <div>{item?.recipeID}</div>
                            </div>
                          </td>
                          <td>
                            <img
                              className="me-3 rounded-circle"
                              crossOrigin="anonymous"
                              src={
                                item?.recipeReadyFoodImage[0]?.small?.imageUrl
                              }
                            />
                          </td>
                          <td>
                            <div className="media-bx d-flex py-3 align-items-center">
                              <div>{item?.recipeName}</div>
                            </div>
                          </td>

                          {/* kitchen name */}
                          <td>
                            <div className="media-bx d-flex py-3 align-items-center">
                              <div>
                                <Link
                                  to={`/sellerdetails/${item?.seller[0]?._id}`}
                                >
                                  {item?.seller[0]?.kitchenName}
                                </Link>
                              </div>
                            </div>
                          </td>
                          {/* food Quantity */}
                          {/* <td>
                            <div className="media-bx d-flex py-3 align-items-center">
                              <div>
                                {item?.preparationTime
                                  ? item?.preparationTime
                                  : "N/A"}
                              </div>
                            </div>
                          </td> */}

                          {/* <td>
    <div className="media-bx d-flex py-3 align-items-center">
      <div>{item?.recipeTips}</div>
    </div>
  </td> */}

                          <td>
                            <div className="media-bx d-flex py-3 align-items-center">
                              <div>
                                {item?.createdDate
                                  ? moment(item?.createdDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  : "N/A"}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-bx d-flex py-3 align-items-center">
                              <div>
                                {item?.viewCount !== 0
                                  ? "Not available"
                                  : item?.viewCount}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex gap-2">
                              {/* <Link
                                onClick={() => {
                                  comment(item._id);
                                }}
                                style={{
                                  border: 0,
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#d97706",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <AiFillCodepenSquare
                                    size={18}
                                    color="white"
                                  />
                                </div>
                              </Link> */}
                              <Link
                                to={`/edit-recipe/${item._id}`}
                                state={item}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#d97706",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <AiFillEdit size={18} color="white" />
                                </div>
                              </Link>

                              <Link
                                onClick={() => {
                                  del(item._id);
                                }}
                                style={{
                                  backgroundColor: "#db2777",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <AiFillDelete size={18} color="white" />
                              </Link>
                            </div>
                          </td>
                          <td>
                            <Button>
                              {item?.comment && (
                                <div
                                  onClick={() => {
                                    showComment(item._id, item?.comment);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Update
                                </div>
                              )}

                              {!item?.comment && (
                                <div
                                  onClick={() => {
                                    comment(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Add
                                </div>
                              )}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <MyPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeList;
