import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import moment from "moment";
const EditCoupon = () => {
  const [list, setListData] = useState();
  const location = useLocation();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    axios.get(`${Base_Url}/get-single-coupon/${id}`).then((res) => {
      setListData(res.data.data);
    });
  }, []);

  console.log(list);

  const [layoutOne, setLayoutOne] = useState({
    CouponName: "",
    layoutCouponAmount: "",
    StartDate: "",
    EndDate: "",
  });

  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" &&
    newContact.layoutOrderby !== "" &&
    newContact.layoutDisplayLimit !== "" &&
    newContact.layoutCouponAmount !== "" &&
    newContact.layoutTwoName !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  console.log(list);

  return (
    <>
      {" "}
      {list !== undefined ? (
        <div>
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-username">
              Coupon Code
              <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <input
                type="text"
                className="form-control"
                id="val-username"
                placeholder="Enter a Coupon Code "
                label="CouponName:"
                name="CouponName"
                defaultValue={list[0]?.couponCode}
                //   value={layoutOne.CouponName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-username">
              Coupon Amount
              <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <input
                type="text"
                className="form-control"
                id="val-username"
                placeholder="Enter a Coupon Code "
                label="layoutCouponAmount:"
                name="layoutCouponAmount"
                //   value={layoutOne.layoutCouponAmount}
                defaultValue={list[0]?.couponAmount}
                onChange={handleChange}
              />
            </div>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              // defaultChecked={isCouponForAllProduct}
              defaultChecked={list[0]?.isCouponForAllProduct}
              //   onChange={() => setisCouponForAllProduct(!isCouponForAllProduct)}
            />
            <label class="form-check-label" for="flexCheckDefault">
              Is For All Product
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              // defaultChecked={isForReview}
              defaultChecked={list[0]?.isForReview}
              // onChange={() => setisForReview(!isForReview)}
            />
            <label class="form-check-label" for="flexCheckDefault">
              Is for Review
            </label>
          </div>
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-username">
              Coupon Start Date
              <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <span>
                {moment(list[0]?.couponStartDate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </span>
              <input
                type="text"
                className="form-control"
                id="val-username"
                placeholder="Change Start date"
                label="StartDate:"
                name="StartDate"
                //   value={layoutOne.StartDate}
                // defaultValue={list[0]?.couponStartDate}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-username">
              Coupon End Date
              <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <span>
                {moment(list[0]?.couponEndDate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </span>
              <input
                type="text"
                className="form-control"
                id="val-username"
                placeholder="Change Coupon End date"
                label="EndDate:"
                name="EndDate"
                // defaultValue={list[0]?.couponEndDate}
                onChange={handleChange}
              />
            </div>
          </div>
          <Button
          //   disabled={disable}
          //   onClick={() => {
          //     handleSubmit();
          //   }}
          >
            Save Coupon
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EditCoupon;
