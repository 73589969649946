import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import PageTitle from "../../../layouts/PageTitle";

class CkEditor extends Component {
  render() {
    return (
      <>
        <PageTitle
          activeMenu="Write about a new Recipe or Your Cooking Feelings"
          motherMenu="Blog"
          pageContent="Write about a new Recipe or Your Cooking Feelings"
        />
        <div class="row">
          <div class="col-xl-12 col-xxl-12">
            <div className="form-group mb-3 row">
              <label className="col-12 col-form-label" htmlFor="val-username">
                Title
                <span className="text-danger">*</span>
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  placeholder="Enter a Blog Title.."
                />
              </div>
            </div>
            <div class="card">
              <div class="card-header"></div>
              <div class="card-body custom-ekeditor">
                {/* <h2>Using CKEditor 5 build in React</h2> */}
                <CKEditor
                  editor={ClassicEditor}
                  // data="<p>Hello from CKEditor 5!</p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CkEditor;
