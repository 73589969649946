import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cropper from "react-easy-crop";
import axios from "axios";
import { useEffect } from "react";
import { Base_Url } from "../../../misc";
const EditSubCategory = () => {
  const [catID, setCatID] = useState([]);
  const [selectedCatID, setSelectedCatID] = useState("");
  const [disable, setdisable] = useState(true);
  const [currentStatus, setCurrentStatus] = useState(true);
  const params = useParams();
  const curItem = useLocation().state;
  const [data, setData] = useState({
    categoryName: curItem?.categoryName,
    categorySlug: curItem?.categorySlug,
    categoryID: curItem?.categoryID,
    status: curItem?.status,
  });
  console.log(curItem);

  useEffect(() => {
    setSelectedCatID(data.categoryID);
  }, []);
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const changeStatus = (newStatus) => {
    setCurrentStatus(newStatus);
  };

  const submit = () => {
    let subdata = {
      categoryName: data.categoryName,
      categorySlug: data.categorySlug,
      status: currentStatus,
      categoryID: selectedCatID,
    };

    axios
      .post(`${Base_Url}/update-subcategory/${params.id}`, subdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Edited", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCat = (e) => {
    axios
      .get(`${Base_Url}/get-category`)
      .then((res) => setCatID(res.data.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const [cat, setcat] = useState();
  const Fcat = (newcat) => {
    setcat(newcat);
  };

  return (
    <>
      <ToastContainer />{" "}
      <div className="container d-flex ">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit Sub Category</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3">
                    <label>SubCategory Name</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder="SubCategory Name"
                      label="Name:"
                      name="categoryName"
                      value={data.categoryName}
                      onChange={handleChange}
                    />
                  </div>

                  {/* <div className="form-group mb-3">
                    <label>SubCategory Slug</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder="SubCategory Slug"
                      label="Name:"
                      name="categorySlug"
                      value={data.categorySlug}
                      onChange={handleChange}
                    />
                  </div> */}

                  <div className="row d-flex">
                    <div className="form-group mb-3 col-md-4">
                      <label>Status</label>
                      <select
                        defaultValue={"option"}
                        onChange={(event) => changeStatus(event.target.value)}
                        value={currentStatus}
                        id="inputState"
                        className="form-control"
                      >
                        <option value="option" disabled>
                          Choose Status
                        </option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>

                    <div className="form-group mb-3 col-md-4">
                      <label>Category </label>
                      <select
                        defaultValue={"option"}
                        onClick={getCat}
                        onChange={(event) =>
                          setSelectedCatID(event.target.value)
                        }
                        id="inputCategory"
                        className="form-control"
                      >
                        <option value="option" disabled>
                          Choose Category
                        </option>

                        {catID.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.categoryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="formFile" className="form-label">
                        Upload User Image
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          submit();
        }}
      >
        {" "}
        Save
      </Button>
    </>
  );
};

export default EditSubCategory;
