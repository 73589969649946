import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import FoodOrder from "./components/Dashboard/FoodOrder";
import FavoriteMenu from "./components/Dashboard/FavoriteMenu";
import Message from "./components/Dashboard/Message";
import OrderHistory from "./components/Dashboard/OrderHistory";
import Notification from "./components/Dashboard/Notification";
import Bill from "./components/Dashboard/Bill";
import HomeSetting from "./components/Dashboard/Setting";
import CheckoutPage from "./components/Dashboard/CheckoutPage";

//Restaurant
import Restaurant from "./components/Dashboard/Restaurant/Restaurant";
import Withdrow from "./components/Dashboard/Restaurant/Withdrow";
import Menu from "./components/Dashboard/Restaurant/Menu";
import Orders from "./components/Dashboard/Restaurant/Orders";
import CustomerReviews from "./components/Dashboard/Restaurant/CustomerReviews";

//Drivers
import DeliverMain from "./components/Dashboard/Drivers/DeliverMain";
import DeliverOrder from "./components/Dashboard/Drivers/DeliverOrder";
import Feedback from "./components/Dashboard/Drivers/Feedback";

/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";
import Theme3 from "./components/Dashboard/Demo/Theme3";
import Theme4 from "./components/Dashboard/Demo/Theme4";
import Theme5 from "./components/Dashboard/Demo/Theme5";
import Theme6 from "./components/Dashboard/Demo/Theme6";
import Theme7 from "./components/Dashboard/Demo/Theme7";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

import Updateuser from "./components/Dashboard/userUpdate";

import ActiveRider from "./components/Dashboard/activeRider";
import CreateDesignation from "./components/Designation/createDesignation";
import CreateCategory from "./components/category/category";
import CreateSubCategory from "./components/createSubCategory/createSubCategory";
import CreateFoodType from "./components/FoodType/CreateFoodType";
import CreatePortion from "./components/Portion/createportion";
import CreateOrderStatus from "./components/orderstatus/orderstatus";
import CreateCuisine from "./components/Dashboard/cuisine/cuisine";
import CategoryList from "./components/category/categoryList";
import CreateFood from "./components/Create Food/createfood";
import CreateAdditionalTaq from "./components/AdditionalTaq/additionalTaq";
import HomepageDesign from "./components/HomepageDesign/homepagedesign";
import StyleThree from "./components/HomepageDesign/styleThree";
import CreateCountry from "./components/createCountry/createCountry";
import EditHomepage from "./components/HomepageDesign/homepageDesignList/edithomepage";
import Editpage from "./components/HomepageDesign/homepageDesignList/Editpage";
import CreateCoupon from "./components/Cupon/cupon";
import EditCoupon from "./components/Cupon/editCoupon";
import SubCategoryList from "./components/createSubCategory/subcategoryList";
import FoodManagement from "./components/Create Food/foodmanagement";
import CategoryEdit from "./components/category/categoryedit";
import EditDesignation from "./components/Designation/editdesignation";
import Editadditionaltags from "./components/AdditionalTaq/editAdditionalTag";
import EditFoodType from "./components/FoodType/foodtypeedit";
import EditPortion from "./components/Portion/editportion";
import EditOrderStatus from "./components/orderstatus/editorderstatus";
import EditCountry from "./components/createCountry/editCountry";
import SellerManagement from "./components/sellermanagement/sellermanagement";
import RiderManagement from "./components/RiderManagement/riderlist";
import SellerFoodPage from "./components/Dashboard/Restaurant/sellerfoodpage";
import EditCircleLayout from "./components/HomepageDesign/CircleComponent/editcircle";
import EditCirclePage from "./components/HomepageDesign/CircleComponent/editpage";
import PendingCategory from "./components/category/pendingCategory";
import ActiveCategory from "./components/category/activeCategory";
import ActiveSeller from "./components/sellermanagement/activeSeller";
import ActiveSubCategory from "./components/createSubCategory/active_subcategory";
import PendingSubCategory from "./components/createSubCategory/pending_subcategory";
import Seller_View from "./components/sellermanagement/sellerView";
import Create_Recipe from "./components/create_recipe/create_recipe";
import Recipe_List from "./components/create_recipe/recipe_List";
import HowDoWeDo from "./components/Howdowedo/howdowedo";
import HOW_DO_WE_DO from "./components/Howdowedo/howdowedoList";
import HowDoWeDoEdit from "./components/Howdowedo/howdowedoEdit";
import FoodDairy from "./components/postfoodDairy/foodDairy";
import FoodDairyList from "./components/postfoodDairy/listFoodDairy";
import EditFoodDairy from "./components/postfoodDairy/editFoodDairy";
import RiderDetails from "./components/RiderManagement/RiderDetails";
import Inactiveseller from "./components/sellermanagement/Inactiveseller";
import PendingOrder from "./components/Order/PendingOrder";
import CreateBanner from "./components/banner/Banner";
import CreateRecipeCategory from "./components/create_recipe/CreateRecipeCategory";
import BuyerList from "./components/BuyerManagement/BuyerList";
import BuyerView from "./components/BuyerManagement/BuyerView";
import AllSeller from "./components/sellermanagement/AllSeller";
import EditSeller from "./components/sellermanagement/EditSeller/EditSeller";
import FoodList from "./components/foodManagement/foodlist";
import RecipeList from "./components/foodManagement/recipelist";
import Update_Seller from "./components/sellermanagement/updateSeller";
import FoodDetailsPage from "./components/foodManagement/editFood";
import EditFood from "./components/foodManagement/editFood";
import { PendingSeller } from "./components/sellermanagement/PendingSeller";
import { DraftSeller } from "./components/sellermanagement/DraftSeller";
import { ApprovedSeller } from "./components/sellermanagement/ApprovedSeller";
import { RecipeCategoryList } from "./components/recipeCategory/RecipeCategoryList";
import { OrderDetails } from "./components/Order/OrderDetails";
import Details from "./components/Order/OrderDetails2";
import { CreatePlan } from "./components/Delivery_plans/CreatePlan";
import { PlanList } from "./components/Delivery_plans/PlanList";
import { EditPlan } from "./components/Delivery_plans/EditPlan";
import { TestImageUploader } from "./components/foodManagement/TestImageUploader";
import PlanList2 from "./components/Delivery_plans/PlanList2";
import EditRecipeCategory from "./components/CreateRecipeCategory/EditRecipeCategory";
import CreateFoodCategory from "./components/foodManagement/CreateFoodCategory";
import EditFoodCategory from "./components/foodManagement/EditFoodCategory";
import EditRecipe from "./components/create_recipe/EditRecipe";
import FoodEdit from "./components/foodManagement/foodEdit";
import InActiveSeller from "./components/sellermanagement/Inactiveseller";
import InactiveBuyer from "./components/BuyerManagement/InActive";
import Food_View from "./components/foodManagement/foodDetails";
import ReadyOrder from "./components/Order/ReadyOrder";
import MenuList from "./components/Dashboard/Favorite/MenuList";
import PendingRider from "./components/RiderManagement/PendingRider";
import ApprovedRider from "./components/RiderManagement/ApprovedRider";
import EditSubCategory from "./components/createSubCategory/editSubcategory";
import CreateDeliveryPlan from "./components/DeliveryPlan/CreateDeliveryPlan";
import AllDeliveryPlan from "./components/DeliveryPlan/AllDeliveryPlan";
import CreateServiceArea from "./components/ServiceArea/CreateServiceArea";
import WithDrawList from "./components/Withdraw/WithDrawList";
import WithDrawDetails from "./components/Withdraw/WithDrawDetails";
import CreateTransactionDetails from "./components/TransactionDetails/CreateTransactionDetails";
import SellerPage from "./components/sellermanagement/SellerPage";
import BuyerEdit from "./components/BuyerManagement/BuyerEdit";
import EditDeliveryPlan from "./components/DeliveryPlan/EditDeliveryPlan";
import AssignOrder from "./components/Order/AssignOrder/AssignOrder";
import AssignOrderDetails from "./components/Order/AssignOrder/AssignOrderDetails";
import IssueOrder from "./components/Order/AssignOrder/IssueOrder";

const Markup = () => {
  const allroutes = [
    { url: "riderdetails/:id", component: <RiderDetails /> },
    { url: "sellerdetails/:id", component: <Seller_View /> },
    { url: "seller-page/:id", component: <SellerPage /> },
    { url: "update-seller", component: <Update_Seller /> },

    { url: "pending-seller", component: <PendingSeller /> },
    { url: "draft-seller", component: <DraftSeller /> },
    { url: "approved-seller", component: <ApprovedSeller /> },

    { url: "user-list", component: <BuyerList /> },
    { url: "buyer-view/:id", component: <BuyerView /> },
    { url: "buyer-edit/:id", component: <BuyerEdit /> },
    { url: "inactive-buyer", component: <InactiveBuyer /> },

    { url: "all-seller", component: <AllSeller /> },
    { url: "edit-seller/:id", component: <EditSeller /> },
    //inactive-seller
    { url: "inactive-seller", component: <InActiveSeller /> },

    { url: "food-list", component: <FoodList /> },
    { url: "edit-food/:id", component: <EditFood /> },
    { url: "food-edit/:id", component: <FoodEdit /> },
    { url: "food-details/:id", component: <Food_View /> },

    { url: "create-food-category", component: <CreateFoodCategory /> },
    { url: "edit-food-category/:id", component: <EditFoodCategory /> },

    { url: "Create_recipe", component: <Create_Recipe /> },
    { url: "recipe-list", component: <RecipeList /> },

    { url: "recipe-category-list", component: <RecipeCategoryList /> },
    { url: "create-recipe-category", component: <CreateRecipeCategory /> },
    { url: "edit-recipe-category/:id", component: <EditRecipeCategory /> },
    //Food Management

    // Coupon

    // Withdraw
    { url: "withdraw-list", component: <WithDrawList /> },
    { url: "withDrawListdetails/:id", component: <WithDrawDetails /> },
    /// Dashboard
    { url: "/", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "dashboard-dark", component: <DashboardDark /> },
    { url: "food-order", component: <FoodOrder /> },
    { url: "admin-list", component: <MenuList /> },
    { url: "message", component: <Message /> },
    { url: "order-history", component: <OrderHistory /> },
    { url: "notification", component: <Notification /> },
    { url: "bill", component: <Bill /> },
    { url: "setting", component: <HomeSetting /> },
    { url: "checkout", component: <CheckoutPage /> },
    {
      url: "pending-order",
      component: <PendingOrder />,
    },
    {
      url: "ready-order",
      component: <ReadyOrder />,
    },
    {
      url: "orderDetails/:id",
      component: <OrderDetails />,
    },
    {
      url: "orderDetails2/:id",
      component: <Details />,
    },
    {
      url: "/assign-order",
      component: <AssignOrder />,
    },
    {
      url: "/assign-order-details/:id",
      component: <AssignOrderDetails />,
    },
    {
      url:"/issue-order",
      component:<IssueOrder/>
    },
    // Delivery Plans
    { url: "create-delivery-plans", component: <CreatePlan /> },
    { url: "delivery-plans-list", component: <PlanList /> },
    { url: "delivery-plans-list2", component: <PlanList2 /> },
    { url: "edit-delivery-plans/:id", component: <EditPlan /> },

    { url: "createBanner", component: <CreateBanner /> },
    { url: "createRecipeCategory", component: <CreateRecipeCategory /> },

    // Restaurant
    { url: "restaurant", component: <Restaurant /> },
    { url: "withdrow", component: <Withdrow /> },
    { url: "menu", component: <Menu /> },
    { url: "orders", component: <Orders /> },
    { url: "customer-reviews", component: <CustomerReviews /> },

    //Drivers
    { url: "deliver-main", component: <DeliverMain /> },
    { url: "deliver-order", component: <DeliverOrder /> },
    { url: "feedback", component: <Feedback /> },

    /////Demo
    { url: "container-wide", component: <Theme1 /> },
    { url: "horizontal-sidebar", component: <Theme2 /> },
    { url: "nav-header", component: <Theme3 /> },
    { url: "secondary-sidebar", component: <Theme4 /> },
    { url: "mini-sidebar", component: <Theme5 /> },
    { url: "sidebar-theme", component: <Theme6 /> },
    { url: "header-theme", component: <Theme7 /> },

    /// Apps
    { url: "app-profile", component: <AppProfile /> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },
    { url: "post-details", component: <PostDetails /> },

    /// Shop
    { url: "ecom-product-grid", component: <ProductGrid /> },
    { url: "ecom-product-list", component: <ProductList /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-product-order", component: <ProductOrder /> },
    { url: "ecom-checkout", component: <Checkout /> },
    { url: "ecom-invoice", component: <Invoice /> },
    { url: "ecom-customers", component: <Customers /> },
    //
    ///// Chart
    { url: "chart-sparkline", component: <SparklineChart /> },
    { url: "chart-chartjs", component: <ChartJs /> },
    { url: "chart-apexchart", component: <ApexChart /> },
    { url: "chart-rechart", component: <RechartJs /> },
    //
    ///// Bootstrap
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    { url: "ui-card", component: <UiCards /> },
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },
    ///// Plugin
    { url: "uc-select2", component: <Select2 /> },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },

    ///// Widget
    { url: "widget-basic", component: <Widget /> },

    ///// Form
    { url: "form-element", component: <Element /> },
    { url: "form-wizard", component: <Wizard /> },
    { url: "form-ckeditor", component: <CkEditor /> },
    { url: "form-pickers", component: <Pickers /> },
    { url: "form-validation", component: <FormValidation /> },
    //
    ///// table
    { url: "table-filtering", component: <FilteringTable /> },
    { url: "table-sorting", component: <SortingTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },
    { url: "updateuser", component: <Updateuser /> },
    { url: "activeSeller", component: <ActiveSeller /> },
    { url: "activerider", component: <ActiveRider /> },
    { url: "createdesignation", component: <CreateDesignation /> },
    { url: "createCategory", component: <CreateCategory /> },
    { url: "categoryList", component: <CategoryList /> },

    { url: "create-SubCategory", component: <CreateSubCategory /> },
    { url: "edit-SubCategory/:id", component: <EditSubCategory /> },
    { url: "SubCategoryList", component: <SubCategoryList /> },

    { url: "create-FoodType", component: <CreateFoodType /> },
    { url: "create-portion", component: <CreatePortion /> },
    { url: "create-orderStatus", component: <CreateOrderStatus /> },
    { url: "create-cuisine", component: <CreateCuisine /> },
    { url: "create-food", component: <CreateFood /> },
    { url: "create-AdditionalTaq", component: <CreateAdditionalTaq /> },
    { url: "homepagedesign", component: <HomepageDesign /> },
    { url: "Circle-Setting", component: <StyleThree /> },
    { url: "createCountry", component: <CreateCountry /> },
    { url: "editHomePage", component: <EditHomepage /> },
    { url: "HomeDesingEditorPage", component: <Editpage /> },
    { url: "create-coupon", component: <CreateCoupon /> },
    { url: "EditCoupon/:id", component: <EditCoupon /> },
    { url: "FoodManagement", component: <FoodManagement /> },
    { url: "categoryEdit", component: <CategoryEdit /> },
    { url: "editDesignation", component: <EditDesignation /> },
    { url: "editaAdditionalTags", component: <Editadditionaltags /> },
    { url: "EditFoodType", component: <EditFoodType /> },
    { url: "EditPortion", component: <EditPortion /> },
    { url: "EditOrderStatus", component: <EditOrderStatus /> },
    { url: "EditCountry", component: <EditCountry /> },
    { url: "All-Seller", component: <Inactiveseller /> },
    { url: "All-Rider", component: <RiderManagement /> },
    { url: "FoodBySeller", component: <SellerFoodPage /> },
    { url: "EditCircleLayout", component: <EditCircleLayout /> },
    { url: "EditCirlcePage", component: <EditCirclePage /> },
    { url: "pendingCategory", component: <PendingCategory /> },
    { url: "ActiveCategory", component: <ActiveCategory /> },
    { url: "ActiveSeller", component: <ActiveSeller /> },
    { url: "InactiveSeller", component: <Inactiveseller /> },
    { url: "ActiveSub-Category", component: <ActiveSubCategory /> },
    { url: "PendingSub-Category", component: <PendingSubCategory /> },
    { url: "SellerDetaislView", component: <Seller_View /> },

    { url: "recipeList", component: <Recipe_List /> },
    { url: "edit-recipe/:id", component: <EditRecipe /> },
    { url: "howdowedo", component: <HowDoWeDo /> },
    { url: "HowdowedoList", component: <HOW_DO_WE_DO /> },
    { url: "HowdowedoEdit/:id", component: <HowDoWeDoEdit /> },
    { url: "foodDairy", component: <FoodDairy /> },
    { url: "foodDairyList", component: <FoodDairyList /> },
    { url: "EditFoodDairy/:id", component: <EditFoodDairy /> },

    { url: "rider-list", component: <RiderManagement /> },
    { url: "pending-rider", component: <PendingRider /> },
    { url: "approved-rider", component: <ApprovedRider /> },
    // { url: "rider-list", component: <RiderManagement /> },

    { url: "test-image-uploader", component: <TestImageUploader /> },

    ///deliverypaln route
    { url: "create-delivery-plan", component: <CreateDeliveryPlan /> },
    { url: "edit-delivery-plan/:id", component: <EditDeliveryPlan /> },
    { url: "all-delivery-plan", component: <AllDeliveryPlan /> },
    { url: "create-service-area", component: <CreateServiceArea /> },
    {
      url: "create-transaction-details",
      component: <CreateTransactionDetails />,
    },
  ];

  return (
    <>
      <Routes>
        <Route path='page-lock-screen' element={<LockScreen />} />
        <Route path='page-error-400' element={<Error400 />} />
        <Route path='page-error-403' element={<Error403 />} />
        <Route path='page-error-404' element={<Error404 />} />
        <Route path='page-error-500' element={<Error500 />} />
        <Route path='page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
