import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const CreateTransactionDetails = () => {
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [region, setRegion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const params = useParams();

  useEffect(() => {
    setValue("nestedValue", {
      paymentMethod: "eee",
      //   fromBankName: 2,
    });
    setValue("paymentMethod","ee")
    // setItem(location.state);
    getwithdrawDetails();
  }, []);
  console.log(item);
  //   console.log(item, "withdraw-details");
  const getwithdrawDetails = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/get-single-withdrawal/${"642d49b0ba389beab6b46bf8"}`
      );
      if (response.data.status === "Success") {
        setItem(response.data.data[0]);
        // reset({ ...response.data.data[0] });
      }
    } catch (e) {}
  };

  const onSubmit = async (value) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    console.log(signal, "debug-plan");
    try {
      const response = await axios.post(
        `${Base_Url}/create-deliveryplan`,
        value,
        {
          signal,
        }
      );
      console.log(signal, "debug-plan", response.data.status);
      if (response.data.status == "Success") {
        Swal.fire({
          icon: "success",
          title: "Delivery Plan Created",
          timer: 3000,
        });
      }
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error posting data:", error);
        // setError(error.message);
        setLoading(false);
      }
    }
  };
  return (
    <div>
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Create New Service Area</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Payment method</label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Payment method'
                          label='paymentMethod'
                          name='paymentMethod'
                          {...register("paymentMethod", { required: true })}
                        />
                        {errors.serviceAreaTitle && (
                          <p className='text-danger'> Title is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>From Bank name</label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='From Bank name'
                          label='fromBankName'
                          name='fromBankName'
                          {...register("fromBankName", { required: true })}
                        />
                        {errors.fromBankName && (
                          <p className='text-danger'> Title is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>From Mobile Number</label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='From Mobile Number'
                          label='fromMobileNumber'
                          name='fromMobileNumber'
                          {...register("fromMobileNumber", { required: true })}
                        />
                        {errors.fromBankName && (
                          <p className='text-danger'> Title is required</p>
                        )}
                      </div>
                    </div>
                    {/* <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
                      <div className='form-group mb-3'>
                        <label>Status</label>
                        <select
                          {...register("status", {
                            required: true,
                          })}
                          className='form-control input-default'
                        >
                          <option value='true'>Instant</option>
                          <option value='false'>Preorder</option>
                          <option value='false'>Wish</option>
                          <option value='false'>Catering</option>
                        </select>
                        {errors.status && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>From Account Number </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='From Account Number '
                          label='fromAccountNo'
                          name='fromAccountNo'
                          {...register("fromAccountNo", {
                            required: true,
                          })}
                        />
                        {errors.fromAccountNo && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>

                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>From Mobile Banking Operator </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='From Account Number '
                          label='fromMobileBankingOperator'
                          name='fromMobileBankingOperator'
                          {...register("fromMobileBankingOperator", {
                            required: true,
                          })}
                        />
                        {errors.fromMobileBankingOperator && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>From Mobile Banking Type</label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='From Mobile Banking Type'
                          label='fromMobileBankingType'
                          name='fromMobileBankingType'
                          {...register("fromMobileBankingType", {
                            required: true,
                          })}
                        />
                        {errors.fromMobileBankingType && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Bank Name </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Bank Name '
                          label='toBankName'
                          name='toBankName'
                          {...register("toBankName", {
                            required: true,
                          })}
                        />
                        {errors.toBankName && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Bank Account number </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Bank Account number '
                          label='To Bank Account number'
                          name='toBankAccountNo'
                          {...register("toBankAccountNo", {
                            required: true,
                          })}
                        />
                        {errors.toBankAccountNo && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Mobile Number </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Mobile Number'
                          label='toMobileNumber'
                          name='toMobileNumber'
                          {...register("toMobileNumber", {
                            required: true,
                          })}
                        />
                        {errors.toMobileNumber && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div> */}

                  {/* <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Mobile Banking Operator </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Mobile Banking Operator '
                          label='To Mobile Banking Operator'
                          name='toMobileBankingOperator'
                          {...register("toMobileBankingOperator", {
                            required: true,
                          })}
                        />
                        {errors.toMobileBankingOperator && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Amount </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Amount '
                          label='Amount'
                          name='amount'
                          {...register("amount", {
                            required: true,
                          })}
                        />
                        {errors.amount && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Currency </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Currency '
                          label='Currency'
                          name='currency'
                          {...register("currency", {
                            required: true,
                          })}
                        />
                        {errors.amount && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-primary w-100'
                      type='submit'
                      disabled={loading}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTransactionDetails;
