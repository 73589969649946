import React, { useState } from "react";
import { Row, Col, Card, Dropdown, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { Base_Url } from "../../../misc";
const StyleSeven = () => {
  const [layoutOne, setLayoutOne] = useState({
    layoutFirstName: "",
    layoutSecondName: "",
    layoutThirdName: "",
    layoutOrderby: "",
    layoutDisplayLimit: "",
  });

  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutFirstName !== "" &&
    newContact.layoutSecondName !== "" &&
    newContact.layoutThirdName !== "" &&
    newContact.layoutOrderby !== "" &&
    newContact.layoutDisplayLimit !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  let finalPostdata = {
    sectionTitle1: layoutOne.layoutFirstName,
    sectionTitle2: layoutOne.layoutSecondName,
    sectionTitle3: layoutOne.layoutThirdName,
    sectionTitle4: "sectionTitle4",
    sectionStyle: "Style7",
    sectionCategoriesColor: ["black"],
    orderBy: layoutOne.layoutOrderby,
    status: true,
    adImage: "",
    adUrl: "",
    adCode: "123213",
    displayLimit: layoutOne.layoutDisplayLimit,
    sectionCategories1: [
      {
        title: "",
      },
    ],
    sectionCategories2: [
      {
        title: "",
      },
    ],
    sectionCategories3: [
      {
        title: "",
      },
    ],
    sectionCategories4: [
      {
        title: "",
      },
    ],
  };

  const handleSubmit = () => {
    axios
      .post(`${Base_Url}/create-home-page-sections`, finalPostdata)
      .then((res) => {
        if (res.data.status === "Success") {
          created();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h4>Design Section Seven (Style Three Column)</h4>
      <Row>
        <Col>
          <Card>
            <Card.Header className='d-block'>
              <Card.Title>Section Layout Design (Three Column)</Card.Title>
              <Card.Text className='m-0 subtitle'>
                Fill Your Section Data
              </Card.Text>
            </Card.Header>

            <h3 style={{ marginLeft: 30 }}>First Column Design</h3>
            <Row>
              <Col>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Title
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <input
                            type='text'
                            className='form-control'
                            id='val-username'
                            name='layoutFirstName'
                            placeholder='Enter a section Title..'
                            value={layoutOne.layoutFirstName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
            <h3 style={{ marginLeft: 30 }}>Second Column Design</h3>
            <Row>
              <Col>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Title
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <input
                            type='text'
                            className='form-control'
                            id='val-username'
                            name='layoutSecondName'
                            placeholder='Enter a section Title..'
                            value={layoutOne.layoutSecondName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
            <h3 style={{ marginLeft: 30 }}>Third Column Design</h3>
            <Row>
              <Col>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Title
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <input
                            type='text'
                            className='form-control'
                            id='val-username'
                            name='layoutThirdName'
                            placeholder='Enter a section Title..'
                            value={layoutOne.layoutThirdName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Orderby
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <input
                            type='text'
                            className='form-control'
                            id='val-username'
                            name='layoutOrderby'
                            placeholder='Enter a section Orderby..'
                            value={layoutOne.layoutOrderby}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Displaylimit
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <input
                            type='text'
                            className='form-control'
                            id='val-username'
                            name='layoutDisplayLimit'
                            placeholder='Enter a section Display Limit..'
                            value={layoutOne.layoutDisplayLimit}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Button
        disabled={disable}
        onClick={() => {
          handleSubmit();
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default StyleSeven;
