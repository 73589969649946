import React, { useEffect, useState } from 'react';
import { Base_Url } from '../../../misc';
import axios from 'axios';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Swal from 'sweetalert2';
const Style11 = () => {
    const animatedComponents = makeAnimated();
    const [recipeCategory,setRecipeCategory] = useState([])
    const [selectedRecipeCategory, SetselectedRecipeCategory] = useState();
    const [options, setOptions] = useState([]);

  const getCat = () => {
    axios

      .get(`${Base_Url}/get-recipe-category`)
      .then((res) => {
        setRecipeCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(()=>{
    const customCategory =[]

    if(recipeCategory.length>0){

        recipeCategory.map(recipe=>{
            const data = { value: recipe._id, label: recipe.categoryName };
            console.log(data);
            customCategory.push(data)
            setOptions(customCategory)

        }
)




    }
},[recipeCategory])

  useEffect(() => {
    getCat();
  }, []);
  const handleStyleFive =async (e)=>{
    e.preventDefault();
    const form = e.target;
    const title1= form.sectionTitle1.value;
    // const title2= form.sectionTitle2.value;
    // const title3= form.sectionTitle3.value;
    // const title4= form.sectionTitle4.value;
    const orderby = form.Orderby.value;
    const displayLimit= form.Displaylimit.value;
    // const category1=form.category1.value;
    // const category2 = form.category2.value;
    // const category3 = form.category3.value;
    // const category4 = form.category4.value;
    // const category1data=await FilterdData(category1)
    // const category2data=await FilterdData(category2)
    // const category3data=await FilterdData(category3)
    // const category4data=await FilterdData(category4)
    // console.log(category1data)
    const data={
      sectionTitle1: title1,
    //   sectionTitle2: title2,
    //   sectionTitle3: title3,
    //   sectionTitle4: title4,
      sectionStyle: "Style11",

      orderBy: orderby,
      // status: true,

      displayLimit: displayLimit,

      sectionCategories1: options,
    //   sectionCategories2: [
    //     { label:category2data[0].categoryName,
    //       value:category2}


    //   ],
    //   sectionCategories3: [
    //     { label:category3data[0].categoryName,
    //       value:category3}

    //   ],
    //   sectionCategories4: [
    //     { label:category4data[0].categoryName,
    //       value:category4}

    //   ],

    }
    console.log(data);
   await axios
      .post(`${Base_Url}/create-home-page-sections`, data)
      .then((res) => {
        console.log("sucess", res.data.status);
        if(res.data.status==='Success'){
          Swal.fire({
            icon: "success",
            title: "Style has Created",
            showConfirmButton: false,
            timer: 1500,
          });
          form.reset()
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }
  console.log(selectedRecipeCategory,recipeCategory,options);

    return (
        <div>
        <form  onSubmit={handleStyleFive}>
        <h4>Design Section Eleven </h4>
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-block">
                <Card.Title>Section Layout One (Four Food)</Card.Title>
                <Card.Text className="m-0 subtitle">
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username1"
                          >
                            Section Title one
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              type="text"
                              className="form-control"
                              id="val-username1"
                              name="sectionTitle1"
                              placeholder="Enter a section Title.."
                            />
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username1"
                          >
                            Section Category
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                          <Select
                            // value={selectedRecipeCategory}
                            closeMenuOnSelect={false}
                            components={animatedComponents}

                            onChange={(item) => SetselectedRecipeCategory(item)}
                            classNamePrefix='select'

                            isMulti
                            options={options}
                          />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>

          <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username5">
            Section Orderby
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username5"
              name="Orderby"
              placeholder="Enter a section Orderby.."
            />
          </div>
        </div>

        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username6">
            Section Displaylimit
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username6"
              name="Displaylimit"
              placeholder="Enter a section Display Limit.."
            />
          </div>
        </div>
        </Row>

        <Button type="submit">Save</Button>
        </form>
      </div>
    );
};

export default Style11;