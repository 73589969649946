import axios from "axios";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Base_Url } from "../../../misc";
import Swal from "sweetalert2";
const EditDeliveryPlan = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (value) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    console.log(signal, "debug-plan");
    try {
      const response = await axios.post(
        `${Base_Url}/create-deliveryplan`,
        value,
        {
          signal,
        }
      );
      console.log(signal, "debug-plan", response.data.status);
      if (response.data.status == "Success") {
        Swal.fire({
          icon: "success",
          title: "Delivery Plan Created",
          timer: 3000,
        });
      }
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error posting data:", error);
        // setError(error.message);
        setLoading(false);
      }
    }
  };
  return (
    <div>
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Create New DeliveryPlan</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='form-group mb-3'>
                    <label>Plant Title</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Plant Title'
                      label='planTitle'
                      name='planTitle'
                      {...register("planTitle", { required: true })}
                    />
                    {errors.planTitle && (
                      <p className='text-danger'>Plant Title is required</p>
                    )}
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
                      <div className='form-group mb-3'>
                        <label>Maximum Delivery Charge For Buyer</label>
                        <input
                          type='number'
                          className='form-control input-default '
                          placeholder='Maximum Delivery Charge For Buyer'
                          label='maximumdeliveryChargeForBuyer'
                          name='maximumdeliveryChargeForBuyer'
                          {...register("maximumdeliveryChargeForBuyer", {
                            required: true,
                          })}
                        />
                        {errors.maximumdeliveryChargeForBuyer && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
                      {" "}
                      <div className='form-group mb-3'>
                        <label>Fixed Price</label>
                        <input
                          type='number'
                          className='form-control input-default '
                          placeholder='Fixed Price'
                          label='fixedPrice'
                          name='fixedPrice'
                          {...register("fixedPrice", {
                            required: true,
                          })}
                        />
                        {errors.fixedPrice && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      {" "}
                      <div className='form-group mb-3'>
                        <label>Is Fixed Price</label>

                        <select
                          {...register("isFixedPrice", {
                            required: true,
                          })}
                          className='form-control input-default'
                        >
                          <option value='true'>Yes</option>
                          <option value='false'>No</option>
                        </select>
                        {errors.isFixedPrice && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      {" "}
                      <div className='form-group mb-3'>
                        <label>Service Area </label>

                        <select
                          className='form-control input-default'
                          {...register("serviceAreaId", {
                            required: true,
                          })}
                        >
                          <option value='true'>Yes</option>
                          <option value='false'>No</option>
                        </select>
                        {errors.serviceAreaId && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>

                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      {" "}
                      <div className='form-group mb-3'>
                        <label>Order Labels </label>

                        <select
                          {...register("applicableForOrderLabels", {
                            required: true,
                          })}
                          className='form-control input-default'
                        >
                          <option value='true'>Instant</option>
                          <option value='false'>Preorder</option>
                          <option value='false'>Wish</option>
                          <option value='false'>Catering</option>
                        </select>
                        {errors.applicableForOrderLabels && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Currency </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Currency'
                          label='currency'
                          name='currency'
                          {...register("currency", {
                            required: true,
                          })}
                        />
                        {errors.currency && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Base Price </label>
                        <input
                          type='number'
                          className='form-control input-default '
                          placeholder='Base Price'
                          label='Base Price'
                          name='basePrice'
                          {...register("basePrice", {
                            required: true,
                          })}
                        />
                        {errors.basePrice && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Price per Distance </label>
                        <input
                          type='number'
                          className='form-control input-default '
                          placeholder='Price per Distance'
                          label='pricePerDistance'
                          name='pricePerDistance'
                          {...register("pricePerDistance", {
                            required: true,
                          })}
                        />
                        {errors.pricePerDistance && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Platform Fee</label>
                        <input
                          type='number'
                          className='form-control input-default '
                          placeholder='Platform Fee'
                          label='platformFee'
                          name='platformFee'
                          {...register("platformFee", {
                            required: true,
                          })}
                        />
                        {errors.platformFee && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Distance Unit</label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Distance Unit'
                          label='distanceUnit'
                          name='distanceUnit'
                          {...register("distanceUnit", {
                            required: true,
                          })}
                        />
                        {errors.distanceUnit && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Order Items Limit</label>
                        <input
                          type='number'
                          className='form-control input-default '
                          placeholder='Order Items Limit '
                          label='orderItemsLimit'
                          name='orderItemsLimit'
                          {...register("orderItemsLimit", {
                            required: true,
                          })}
                        />
                        {errors.orderItemsLimit && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-primary w-100'
                      type='submit'
                      disabled={loading}
                    >
                      {loading ? "Loading...."

                       : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDeliveryPlan;
