import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";

// import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import { Base_Url } from "../../../misc";
// import { Base_Url } from "../../../misc";

import labels from "./recipe.json";

const CreateFoodCategory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    categoryName: "",
    categorySlug: "",
  });

  const [EditorData, setEditorData] = useState();
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState(true);

  console.log(typeof currentFruit);
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  // const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const [selectedImage, setSelectedImage] = useState([]);
  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedImage(fileList);
  };

  const [selectedMetaImage, setSelectedMetaImage] = useState([]);
  const handleMetaFileChange = (event) => {
    const metaFile = Array.from(event.target.files);
    setSelectedMetaImage(metaFile);
  };

  const getseller = JSON.parse(localStorage.getItem("UserDetails"));
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedImage);
    const form = e.target;
    const name = form.categoryName.value;
    const categoryDescription = EditorData;
    const seoMetaTitle = form.metaTitle.value;
    const seoMetaDescription = form.metaDescription.value;
    const seoMetaKeywords = form.metaKeywords.value;

    const formData = new FormData();

    formData.append("folder", "Admin/Category"); // folder name
    selectedImage?.forEach((file) => {
      formData.append("cover[]", file);
    });

    fetch(`${Base_Url}/imguploads`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then(async function (data) {
        console.log(data, "ll");
        let subdata = {
          categoryName: name,
          categoryDescription: categoryDescription,
          isTopCategory: false,
          seo: {
            metaTitle: seoMetaTitle,
            // metaDescription: seoMetaDescription,
            metaKeywords: seoMetaKeywords,
            metaImage: data?.images,
          },

          status: false,
          categoryImage: data?.images[0].orginalImageUrl,
        };
        if (selectedMetaImage) {
          setIsFileUploading(true);
          await ImageUploader(
            "admin",
            "SEO/MetaImage/",
            selectedMetaImage
          ).then((data) => {
            if (data.status === "Success") {
              subdata.seo.metaImage = data.images;
            } else {
              setIsFileUploading(false);
            }
          });
        }
        if (!isFileUploading) {
          await axios
            .post(`${Base_Url}/create-category`, subdata)
            .then((res) => {
              console.log(subdata);
              if (res.status === 200) {
                toast.success("Category Created", {
                  position: "bottom-center",
                  autoClose: 5000,
                  // hideProgressBar: false,
                  // closeOnClick: true,
                  // pauseOnHover: true,
                  // draggable: true,
                  // progress: undefined,
                  // theme: "light",
                });
                navigate("/categoryList");
              }
            })
            .catch((err) => {
              console.log(err);
              toast.error("❌ Error Notify !", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      });
  };

  return (
    <div className="container  ">
      <ToastContainer />
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Create Food Category</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label>{labels.catTitle.en}</label>
                  <input
                    type="text"
                    className="form-control input-default required"
                    placeholder={labels.catTitle.en}
                    label="Name:"
                    name="categoryName"
                    onChange={handleChange}
                  />
                </div>

                <div className="row d-flex">
                  <div className="col-xl-2">
                    <div className="contact-title">
                      <label>{labels.catImage.en}</label>
                    </div>
                  </div>

                  <div className="col-xl-7 mb-3">
                    <form id="form-file-upload">
                      <input
                        type="file"
                        id="input-file-upload"
                        // multiple
                        onChange={handleFileChange}
                      />
                      <label id="label-file-upload" htmlFor="input-file-upload">
                        <div>
                          <p>Drag and drop your file here or</p>
                          <span className="upload-button">Upload a file</span>
                        </div>
                      </label>
                    </form>
                  </div>
                  <div className="col-xl-3 mb-3">
                    {selectedImage && (
                      <div>
                        {selectedImage?.map((item, index) => {
                          return (
                            <>
                              <img
                                className="img-fluid"
                                alt="Category Image"
                                width={""}
                                src={URL.createObjectURL(item)}
                              />
                              <br />
                              <Button
                                style={{ marginLeft: 25 }}
                                onClick={() => setSelectedImage(null)}
                              >
                                Remove
                              </Button>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-xl-2">
                    <div className="contact-title">
                      <h4 className="mb-xl-0 mb-2">SEO </h4>
                      <span>
                        সার্চ ইঞ্জিন অপ্টিমাইজেশান (Search Engine Optimization)
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-10">
                    <div className="setting-input mb-3">
                      <label className="form-label">
                        {labels.metaTitle.en}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={labels.metaTitle.en}
                        name="metaTitle"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">
                        {labels.metaDescription.en}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={labels.metaDescription.en}
                        name="metaDescription"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">
                        {labels.metaKeywords.en}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={labels.metaKeywords.en}
                        name="metaKeywords"
                      />
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="form-label">
                          {labels.metaImage.en}
                        </label>

                        <input
                          type="file"
                          className="form-control input-default "
                          placeholder={labels.metaImage.en}
                          label="metaImage"
                          name="metaImage"
                          // value={data?.price}
                          onChange={handleMetaFileChange}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        {selectedMetaImage && (
                          <div>
                            {selectedMetaImage?.map((item, index) => {
                              return (
                                <>
                                  <img
                                    className="img-fluid"
                                    alt="Meta Image"
                                    width={""}
                                    src={URL.createObjectURL(item)}
                                  />
                                  <br />
                                  <Button
                                    style={{ marginLeft: 25 }}
                                    onClick={() => setSelectedMetaImage(null)}
                                  >
                                    Remove
                                  </Button>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Button type="submit"> Save</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFoodCategory;
