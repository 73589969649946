import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { Base_Url } from "../../../misc";

// import { BaseURL } from "../../../Helper/config";
const CreateBanner = () => {
  const navigate = useNavigate();

  const [EditorData, setEditorData] = useState();

  const [data, setData] = useState({
    bannerTitle: ""
    
  });

  const [disable, setdisable] = useState(true);
  const [currentStatus, setStatus] = useState(true);

  const changeStatus = (statusValue) => {
    setStatus(statusValue);
  };
  // const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const [selectedImage, setSelectedImage] = useState([]);
  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedImage(fileList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedImage);
    const form = e.target;
    const bannerTitle = form.bannerTitle.value;
    const bannerBackgroundColor = form.bannerBackgroundColor.value;
    const bannerButtonLabel = form.bannerButtonLabel.value;
    const bannerButtonColor = form.bannerButtonColor.value;
    const bannerButtonURL = form.bannerButtonURL.value;
    const bannerExpiry = form.bannerExpiry.value;
    const orderBy = form.orderBy.value;

    const formData = new FormData();

    formData.append("folder", "Admin/Banners"); // folder name
    selectedImage?.forEach((file) => {
      formData.append("cover[]", file);
    });

    fetch(`${Base_Url}/imguploads`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then(async function (data) {
        console.log(data, "ll");
        let subdata = {
          bannerTitle: bannerTitle,
          bannerDesc: EditorData,
          bannerBackgroundColor: bannerBackgroundColor,
          bannerButtonLabel: bannerButtonLabel,
          bannerButtonColor: bannerButtonColor,
          bannerButtonURL: bannerButtonURL,
          bannerExpiry: bannerExpiry,
          orderBy: orderBy,

          status: currentStatus,
          bannerImage: data?.images[0].orginalImageUrl,

        };
        await axios
          .post(`${Base_Url}/create-banners`, subdata)
          .then((res) => {
            console.log(res);
            // if (res.data.status === "Success") {

            toast.success("Banner Created", {
              position: "bottom-right",
              autoClose: 5000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,
              // theme: "light",
            });
            //}
            // if (res.data.data.keyPattern?.categorySlug === 1) {
            //   toast.error("Slug Should be unique", {
            //     position: "bottom-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //   });
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };


  return (
    <>
      <ToastContainer />
      <div className='container  '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Create Banner</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group mb-3'>
                    <label>Banner Title </label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Banner Title'
                      label='Name:'
                      name='bannerTitle'

                      // onChange={handleChange}
                    />
                  </div>
                  <div className='form-group mb-3'>
                   <label>Banner Description</label> 
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"],
                  }}
                  //   data={form.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data);
                    // setForm((prev) => ({ ...prev, description: data }));
                    // console.log({ event, editor, data });
                  }}
                />
                  </div>
                  <div className='form-group mb-3'>
                    <label>Banner Background Color </label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='banner BackgroundC Color'
                      label='Name:'
                      name='bannerBackgroundColor'
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label> Button Label</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Button Label'
                      label='Name:'
                      name='bannerButtonLabel'
                    />
                  </div>

                  
                  <div className='form-group mb-3'>
                    <label> Button Color</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Button Color'
                      label='Name:'
                      name='bannerButtonColor'
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label> Button Link</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Button URL'
                      label='Name:'
                      name='bannerButtonURL'
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label> Banner Expiry</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Expiry Date'
                      label='Name:'
                      name='bannerExpiry'
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label> Order </label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Button URL'
                      label='Name:'
                      name='orderBy'
                    />
                  </div>

                  
                  
                  
                  


                  <div className='row d-flex'>
                    <div
                      className=' justify-content-center mt-3 mb-5'
                      style={{ marginLeft: "30%" }}
                    >
                      {selectedImage && (
                        <div>
                          {selectedImage?.map((item, index) => {
                            return (
                              <img
                                alt='not found'
                                width={"80px"}
                                src={URL.createObjectURL(item)}
                              />
                            );
                          })}
                          <br />
                          <Button
                            style={{ marginLeft: 25 }}
                            onClick={() => setSelectedImage(null)}
                          >
                            Remove
                          </Button>
                        </div>
                      )}

                      <br />
                      <br />

                      <form id='form-file-upload'>
                        <input
                          type='file'
                          id='input-file-upload'
                          // multiple
                          onChange={handleFileChange}
                        />
                        <label
                          id='label-file-upload'
                          htmlFor='input-file-upload'
                        >
                          <div>
                            <p>Drag and drop your file here or</p>
                            <span className='upload-button'>Upload a file</span>
                          </div>
                        </label>
                      </form>

                      <div className='form-group mb-3 col-md-4'>
                      <label>Status</label>
                      <select
                        
                        onChange={(event) => changeStatus(event.target.value)}
                        value={currentStatus}
                        id='inputStatus'
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Choose Status
                        </option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>


                    </div>
                  </div>
                  <Button type='submit' >
                    {" "}
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBanner;