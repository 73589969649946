import React, { useState, useEffect } from "react";
import { Col, Row, Card, Dropdown, Button } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";

const CircleTitle = () => {
  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [layoutOne, setLayoutOne] = useState({
    layoutName: "",
  });

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" ? setdisable(false) : setdisable(true);
    setLayoutOne(newContact);
  };

  let finalPostdata = {
    sectionTitle1: layoutOne.layoutName,
    sectionStyle: "Style3",
  };

  console.log(layoutOne);
  const handleSubmit = () => {
    axios
      .post(`${Base_Url}/create-home-page-sections`, finalPostdata)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='form-group mb-3 row'>
      <label className='col-lg-4 col-form-label' htmlFor='val-username'>
        Section Title
        <span className='text-danger'>*</span>
      </label>
      <div className='col-lg-6'>
        <input
          type='text'
          className='form-control'
          id='val-username'
          name='layoutName'
          placeholder='Enter a section Title..'
          value={layoutOne.layoutName}
          onChange={handleChange}
        />
      </div>
      <div>
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          {" "}
          Save
        </Button>
      </div>
    </div>
  );
};

export default CircleTitle;
