import React, { useEffect, useState } from "react";
import { AiFillEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";
import { Base_Url } from "../../../../misc";
import { PhotoProvider, PhotoView } from "react-photo-view";

export const BasicInfo = ({ item,id }) => {
  const [formData, setFormData] = useState({
    userFullName:  "",
    email: "",
    userMobileNo:  "",
  });
  useEffect( ()=>{
    if(item?.userData){
      setFormData({
        userFullName: item?.userData[0]?.userFullName || "",
        email: item?.userData[0]?.email || "",
        userMobileNo: item?.userData[0]?.userMobileNo || "",
      });
    }

  },[])
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  console.log(formData)
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    // Reset form data
    setFormData({
      userFullName: item?.userData[0]?.userFullName || "",
      email: item?.userData[0]?.email || "",
      userMobileNo: item?.userData[0]?.userMobileNo || "",
    });
    setIsEditing(false);
  };


  
  const submitUserUpdate = async () => {
    const { userFullName, email, userMobileNo } = formData;
    const id = item?.userData[0]?._id;
    console.log()
    try {
      const res = await axios.post(`${Base_Url}/update-users/${id}`, {
        userFullName: userFullName,
        email: email,
        userMobileNo: userMobileNo,
      });

      console.log(res.data);
      Swal.fire({
        icon: "success",
        title: "Seller data updated successfully!!!",
        showConfirmButton: false,
        timer: 1500,
      });

      // Update state or perform any other actions upon successful update
    } catch (error) {
      console.log(error);
      // Handle error
    }
  };

  const handleSave = () => {
    submitUserUpdate();
    setIsEditing(false);
  };

  console.log(formData)
  return (<>
    {item?.userData &&
    <div className="card-body border-0">
      <div className="">
        <div className="card-body rounded border mb-4">
          <h3 className="mb-4">
            {"Basic Info"} {!isEditing && <AiFillEdit size={18} color="orange" onClick={handleEdit} />}
          </h3>

          <div className="row">
          <div className="setting-img d-flex align-items-center mb-4">
              <div className="avatar-upload d-flex align-items-center">
                <div className=" change position-relative ">
                  <div className="avatar-preview d-flex ml-2">
                    {console.log(
                      item?.userData[0]?.userProfilePhoto,
                      "debug-seller-view"
                    )}
                    {item?.userData[0]?.userProfilePhoto &&
                    item?.userData[0]?.userProfilePhoto.length > 0 ? (
                      <PhotoProvider>
                        <div className="m-3 rounded">
                          <PhotoView
                            src={
                              item?.userData[0]?.userProfilePhoto[0]?.large
                                ?.imageUrl
                            }
                          >
                            <img
                              style={{
                                height: "100px",
                                width: "100px",
                              }}
                              src={
                                item?.userData[0]?.userProfilePhoto[0]?.small
                                  ?.imageUrl
                              }
                              alt=""
                            />
                          </PhotoView>
                        </div>
                      </PhotoProvider>
                    ) : (
                      <img
                        id="saveImageFile"
                        src={""} // Replace this with the actual path to your noImage file
                        alt="No Image"
                      />
                    )}{" "}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="setting-input">


                <label htmlFor="username" className="form-label">
                  <b>{"Your Name"}</b>
                </label>
                <input
                  id="username"
                  type="text"
                  className="form-control input-default"
                  placeholder="username"
                  name="userFullName"
                  defaultValue={item?.userData[0]?.userFullName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="setting-input">
                <label htmlFor="email" className="form-label">
                  <b>{"Email Address"}</b>
                </label>
                <input
                  id="email"
                  type="email"
                  className="form-control input-default"
                  placeholder="email"
                  name="email"
                  defaultValue={item?.userData[0]?.email}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="setting-input">
                <label htmlFor="mobile" className="form-label">
                  <b>{"Mobile Number"}</b>
                </label>
                <input
                  id="mobile"
                  type="text"
                  className="form-control input-default"
                  placeholder="Mobile Number"
                  name="userMobileNo"
                  defaultValue={item?.userData[0]?.userMobileNo}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
            </div>
          </div>

          {isEditing && (
            <div className="mt-3">
              <button className="btn btn-primary mr-2" onClick={handleSave}>
                <AiOutlineCheck /> Save
              </button>{"  "}
              <button className="btn btn-secondary" onClick={handleCancel}>
                <AiOutlineClose /> Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
}
    </>
  );
};
