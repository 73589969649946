import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Dropdown, Pagination } from "react-bootstrap";
import { Base_Url } from "../../../misc";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import MyPagination from "../Pagination/MyPagination";

const FoodList = () => {
  const [foodData, setFoodData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropValue, setDropValue] = useState("Order by");
  const [filter, setfilter] = useState("filter by");
  const [apiPagination, setApiPagination] = useState("Sort by");
  const [PAGE_SIZE, setPAGE_SIZE] = useState(20);

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-food`)
      .then((res) => {
        setFoodData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        // Handle error, show user-friendly message
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${Base_Url}/delete-food/${id}`)
      .then((res) => {
        console.log(res.data.data.deletedCount);
        if (res.data.data.deletedCount === 1) {
          const updateData = foodData.filter((r) => r._id !== id);
          setFoodData(updateData);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // Handle error, show user-friendly message
      });
  };

  const confirmDelete = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
      }
    });
  };

  const handleApproved = (id) => {
    axios
      .post(`${Base_Url}/update-become-seller/${id}`, {
        sellerApproval: "Approved",
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = foodData.filter((r) => r._id !== id);
          setFoodData(updateData);
          Swal.fire({
            icon: "success",
            title: "Seller Approved Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // Handle error, show user-friendly message
      });
  };

  // Filtering Logic
  useEffect(() => {
    let filtered = foodData;

    if (searchTerm.trim() !== "") {
      filtered = filtered.filter((item) =>
        item?.foodName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, foodData]);

  // Pagination Logic
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentItems = (
    filteredData.length > 0 ? filteredData : foodData
  ).slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData.length : foodData.length) /
      PAGE_SIZE
  );

  return (
    <div>
      <div
        className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="input-group search-area2 style-1 w-[75%] border d-flex"
          style={{ marginRight: "15px" }}
        >
          <span className="input-group-text p-0">
            <Link to={"#"}>
              <svg
                className="me-1"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                  fill="#FC8019"
                />
              </svg>
            </Link>
          </span>

          <input
            type="text"
            className="form-control p-2 "
            placeholder="Filter by Food Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="input-group ml-2 pl-2 style-1 w-50">
          {/* Search filter */}
          <Dropdown
            className="notification-select "
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {dropValue}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setDropValue("Date")}
                eventKey="Activity"
              >
                Date
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setDropValue("Ratings")}
                eventKey="Activity"
              >
                Ratings
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Filter by */}
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {filter}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("Blocked")}
                eventKey="Activity"
              >
                Blocked
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("Active")}
                eventKey="Activity"
              >
                Active
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/*  Sort by */}
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {apiPagination}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                1-50
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                51-100
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                101-150
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("151-200")}
                eventKey="Activity"
              >
                151-200
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              <i
                className="fa-solid fa-location-dot mx-2"
                style={{ color: "orange" }}
              />{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                Thana
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                Region
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                City
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      </div>

      <div
        className="card h-auto"
        // style={{ marginTop: 10, marginLeft: -160, width: "127%" }}
      >
        <div className="card-body p-0">
          <div className="table-responsive">
            {foodData === undefined ? (
              "Loading..."
            ) : (
              <>
                <table
                  className="table table-list i-table style-1 mb-4 border-0"
                  id="guestTable-all3"
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Food Name</th>
                      <th>Food Quantity</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Action</th>
                      <th>View Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1 + PAGE_SIZE * (currentPage - 1)}</td>
                        <td>{item?.foodName}</td>
                        <td>{item?.foodQty}</td>
                        <td>{item?.foodPrice}</td>
                        <td>
                          {item?.foodDiscountPrice
                            ? item?.foodDiscountPrice
                            : 0}
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <Link to={`/edit-food/${item?._id}`} state={item}>
                              <div
                                style={{
                                  backgroundColor: "#d97706",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <AiFillEdit size={18} color="white" />
                              </div>
                            </Link>
                            <div
                              onClick={() => {
                                confirmDelete(item._id);
                              }}
                              style={{
                                backgroundColor: "#db2777",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <AiFillDelete size={18} color="white" />
                            </div>
                            {/* <div>
                              <Link
                                to={`/food-details/${item?._id}`}
                                state={item?._id}
                              >
                                <AiFillEye size={30} color="orange" />
                              </Link>
                            </div> */}
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={`/food-details/${item?._id}`}
                              state={item?._id}
                            >
                              <AiFillEye size={30} color="orange" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <MyPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodList;
