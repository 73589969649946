import React, { useState, useEffect } from "react";
import { Col, Row, Card, Dropdown, Button } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import makeAnimated from "react-select/animated";
import axios from "axios";
import CircleCategories from "./CircleComponent/Categories";
import BasedCountryComponent from "./CircleComponent/basedonCountryComponent";
import BestSeller from "./CircleComponent/bestSeller";
const StyleThree = () => {
  const [customStyle, setCustomStyle] = useState("Select Style");
 

  return (
    <div>
      <div>
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-block">
                <Card.Title>Select Circle Design Layout</Card.Title>
                <Card.Text className="m-0 subtitle">
                  Select Your Design Layout and Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Card.Body>
                <div className="basic-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary">
                      {customStyle}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style One (Category)");
                        }}
                      >
                        Style One (Category)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Two (Based on Country)");
                        }}
                      >
                        Style Two (Based on Country)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Three Best Seller");
                        }}
                      >
                        Style Three Best Seller
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {customStyle === "Select Style" ? (
        <div style={{ marginTop: 150 }}> Select Your Design Layout</div>
      ) : (
        <div>
          {customStyle === "Style One (Category)" ? <CircleCategories /> : ""}
          {customStyle === "Style Two (Based on Country)" ? (
            <BasedCountryComponent />
          ) : (
            ""
          )}
          {customStyle === "Style Three Best Seller" ? <BestSeller /> : ""}
        </div>
      )}
    </div>
  );
};

export default StyleThree;
