import React, { useState, useEffect } from "react";
import { Col, Row, Card, Dropdown, Button } from "react-bootstrap";
import Select from "react-select";

import Swal from "sweetalert2";
import makeAnimated from "react-select/animated";

import axios from "axios";
import { Base_Url } from "../../../misc";
const StyleNine = () => {
  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState();
  const [cat, setCat] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [SecondselectedOptions, setTwoSelectedOptions] = useState();

  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [layoutOne, setLayoutOne] = useState({
    layoutName: "",
    layoutOrderby: "",
    layoutDisplayLimit: "",
  });

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" &&
    newContact.layoutOrderby !== "" &&
    newContact.layoutDisplayLimit !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  const getCat = () => {
    axios
      .get(`${Base_Url}/get-category`)
      .then((res) => {
        setCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCat();
  }, []);
  useEffect(() => {
    if (cat && cat !== undefined) {
      const tttt = [];
      cat.forEach((el) => {
        const data = { value: el._id, label: el.categoryName };

        tttt.push(data);
        setOptions(tttt);
      });
    }
  }, [cat]);
  console.log(selectedOptions);

  let finalPostdata = {
    sectionTitle1: layoutOne.layoutName,
    sectionTitle2: layoutOne.layoutTwoName,
    sectionTitle3: "sectionTitle3",
    sectionTitle4: "sectionTitle4",
    sectionStyle: "Style9",
    sectionCategoriesColor: ["black"],
    orderBy: layoutOne.layoutOrderby,
    status: true,
    adImage: "",
    adUrl: "",
    adCode: "123213",
    displayLimit: 4,
    sectionCategories1: selectedOptions,
    sectionCategories2: [
      {
        title: "",
      },
    ],
    sectionCategories3: [
      {
        title: "",
      },
    ],
    sectionCategories4: [
      {
        title: "",
      },
    ],
  };

  const handleSubmit = () => {
    axios
      .post(`${Base_Url}/create-home-page-sections`, finalPostdata)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <h4>Design Section One (Two Column 4 Foods)</h4>
      <Row>
        <Col>
          <Card>
            <Card.Header className='d-block'>
              <Card.Title>Section Layout One </Card.Title>
              <Card.Text className='m-0 subtitle'>
                Fill Your Section Data
              </Card.Text>
            </Card.Header>
            <Row>
              <Col>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Title
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <input
                            type='text'
                            className='form-control'
                            id='val-username'
                            placeholder='Enter a section Title..'
                            label='layoutName:'
                            name='layoutName'
                            value={layoutOne.layoutName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Category
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <Select
                            value={selectedOptions}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            // defaultValue={selectedOptions}
                            onChange={(item) => setSelectedOptions(item)}
                            classNamePrefix='select'
                            // defaultValue={[Options[4], Options[5]]}
                            isMulti
                            options={options}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>

        <div className='form-group mb-3 row'>
          <label className='col-lg-4 col-form-label' htmlFor='val-username'>
            Section Orderby
            <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-6'>
            <input
              type='text'
              className='form-control'
              id='val-username'
              placeholder='Enter a section Orderby..'
              label='layoutOrderby:'
              name='layoutOrderby'
              value={layoutOne.layoutOrderby}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='form-group mb-3 row'>
          <label className='col-lg-4 col-form-label' htmlFor='val-username'>
            Section Displaylimit
            <span className='text-danger'>*</span>
          </label>
          <div className='col-lg-6'>
            <input
              type='text'
              className='form-control'
              id='val-username'
              placeholder='Enter a section Display Limit..'
              label='layoutDisplayLimit:'
              name='layoutDisplayLimit'
              value={layoutOne.layoutDisplayLimit}
              onChange={handleChange}
            />
          </div>
        </div>
      </Row>
      <Button
        disabled={disable}
        onClick={() => {
          handleSubmit();
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default StyleNine;
