import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { Base_Url } from "../../../../misc";
const EditHomepage = () => {
  const [Homepagelist, setHomePagelist] = useState();

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };
  useEffect(() => {
    axios
      .get(`${Base_Url}/get-home-page-sections`)
      .then((res) => {
        setHomePagelist(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (data) => {
    console.log(data);
    axios
      .delete(`${Base_Url}/delete-home-page-sections/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <div className="card h-auto">
        <div className="card-body p-0">
          <div className="table-responsive">
            {Homepagelist === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th className="bg-none sorting_asc">
                      <div className="form-check style-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="checkAll"
                          onClick={() => chackboxFun("all")}
                        />
                      </div>
                    </th>
                    <th>Orderby</th>
                    <th>Section Title 1</th>
                    <th>Section Title 2</th>
                    <th>Style</th>
                    <th>Status</th>
                    <th>Display Limit</th>
                    <th className="text-center">Action</th>

                    <th className="bg-none"></th>
                  </tr>
                </thead>
                <tbody>
                  {Homepagelist.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <h5 className="mb-0">{item.orderBy}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <h5 className="mb-0">{item.sectionTitle1}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <h5 className="mb-0">{item.sectionTitle2}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">{item.sectionStyle}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">
                              {item.status === true ? "Active" : "Deactive"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">{item.displayLimit}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to="/HomeDesingEditorPage"
                              state={item._id}
                              className="btn btn-outline-primary"
                            >
                              Edit
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={"#"}
                              onClick={() => {
                                // handleDelete(item._id);
                                del(item._id);
                              }}
                              className="btn btn-outline-primary"
                            >
                              Delete
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHomepage;
