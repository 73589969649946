import axios from "axios";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";

import { AiFillDelete } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import moment from "moment";

export const CreateCategory = () => {
  const [isForReview, setisForReview] = useState(false);
  const [isCouponForAllProduct, setisCouponForAllProduct] = useState(false);
  const [coupon, setCoupon] = useState();
  const [selectedUnit, setSelectedUnit] = useState("kilometers");

  const [isActive, setIsActive] = useState(true); // Default status
  const [selectedImage, setSelectedImage] = useState([]);
  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedImage(fileList);
    console.log(selectedImage);
  };

  const handleStatusChange = (e) => {
    setIsActive(e.target.value === "true");
  };

  const [selectedLabel, setSelectedLabel] = useState("INSTANT"); // Default label

  const handleLabelChange = (e) => {
    setSelectedLabel(e.target.value);
  };

  // const [layoutOne, setLayoutOne] = useState({
  //   CouponName: "",
  //   layoutCouponAmount: "",
  //   StartDate: "",
  //   EndDate: "",
  // });
  const [layoutTwo, setLayoutTwo] = useState({
    categoryName: "",
    categoryImage: "",
    status: "",
  });
  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    // const newContact = { ...layoutOne };
    // newContact[e.target.name] = e.target.value;

    // newContact.layoutName !== "" &&
    // newContact.layoutOrderby !== "" &&
    // newContact.layoutDisplayLimit !== "" &&
    // newContact.layoutCouponAmount !== "" &&
    // newContact.layoutTwoName !== ""
    //   ? setdisable(false)
    //   : setdisable(true);
    // setLayoutOne(newContact);

    const newContact2 = { ...layoutTwo };
    newContact2[e.target.name] = e.target.value;
    newContact2.layoutName !== "" &&
      newContact2.layoutOrderby !== "" &&
      newContact2.layoutDisplayLimit !== "" &&
      setLayoutTwo(newContact2);
  };

  const handleChange2 = (e) => {
    const newContact2 = { ...layoutTwo };
    newContact2[e.target.name] = e.target.value;
    console.log(e.target.name);
    console.log(e.target.value);
    setLayoutTwo(newContact2);
  };

  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.value);
  };

  let finalPostdata2 = {
    categoryName: layoutTwo.categoryName,
    categoryImage: "image string",
    status: isActive,
    seo: {
      metaTitle: "test",
      metaDescription: "test description",
      metaKeywords: ["burger"],
    },
  };

  const handleSubmit2 = () => {
    axios
      .post(`${Base_Url}/create-category`, finalPostdata2)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <br></br>
      {/* Delivery Plans */}
      <h1>Create Category</h1>
      <div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Category Name
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter categoryName "
              label="categoryName:"
              name="categoryName"
              value={layoutTwo.categoryName}
              onChange={handleChange2}
            />
          </div>
        </div>

        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Category Image
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6"></div>
        </div>
        <div className="form-group mb-3 row">
          <div className="col-lg-6">
            <div
              className=" justify-content-center mt-3 mb-5  category-upload"
              style={{ width: "100%" }}
            >
              {selectedImage && (
                <div>
                  {selectedImage?.map((item, index) => {
                    return (
                      <img
                        alt="not found"
                        width={"80px"}
                        src={URL.createObjectURL(item)}
                      />
                    );
                  })}
                  <br />
                  <Button
                    style={{ marginLeft: 25 }}
                    onClick={() => setSelectedImage(null)}
                  >
                    Remove
                  </Button>
                </div>
              )}

              <br />
              <br />

              <form id="form-file-upload">
                <input
                  type="file"
                  id="input-file-upload"
                  // multiple
                  onChange={handleFileChange}
                />
                <label id="label-file-upload" htmlFor="input-file-upload">
                  <div>
                    <p>Drag and drop your file here or</p>
                    <span className="upload-button">Upload a file</span>
                  </div>
                </label>
              </form>
            </div>
          </div>
        </div>

        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Status
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <label className="m-3">
              <input
                type="radio"
                value="true"
                checked={isActive}
                onChange={handleStatusChange}
                className="p-2"
              />
              Active
            </label>
            <label>
              <input
                type="radio"
                value="false"
                checked={!isActive}
                onChange={handleStatusChange}
                className="p-2"
              />
              Inactive
            </label>
          </div>
        </div>

        <div className="row d-flex">
          <Accordion
            className="accordion accordion-rounded-stylish accordion-bordered"
            defaultActiveKey="0"
          >
            <Accordion.Item key="seo" eventKey="seo">
              <Accordion.Header className="accordion-header accordion-header-primary">
                <h5>Advance - Search Engine Optimization</h5>

                <span className="accordion-header-indicator "></span>
                {/* <span className="accordion-header-icon"></span>
                          <span className="accordion-header-text">{d.title}</span>
                          <span className="accordion-header-indicator "></span> */}
              </Accordion.Header>
              <Accordion.Collapse eventKey="seo" className="accordion__body">
                <div className="accordion-body">
                  <div className="col-xl-12">
                    <div className="setting-input mb-3">
                      <label className="form-label"> meta title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="metaTitle"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label"> meta description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="metaDescription"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label"> meta keywords</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Keywords"
                        name="metaKeywords"
                      />
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion.Item>
          </Accordion>
        </div>

        <Button
          //disabled={disable}
          onClick={() => {
            handleSubmit2();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CreateCategory;
