import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import moment from "moment";
export const CreatePlan = () => {
  const [isForReview, setisForReview] = useState(false);
  const [isCouponForAllProduct, setisCouponForAllProduct] = useState(false);
  const [coupon, setCoupon] = useState();
  const [selectedUnit, setSelectedUnit] = useState("kilometers");

  const [isActive, setIsActive] = useState(true); // Default status

  const handleStatusChange = (e) => {
    setIsActive(e.target.value === "true");
  };

  const [selectedLabel, setSelectedLabel] = useState("INSTANT"); // Default label

  const handleLabelChange = (e) => {
    setSelectedLabel(e.target.value);
  };

  // const [layoutOne, setLayoutOne] = useState({
  //   CouponName: "",
  //   layoutCouponAmount: "",
  //   StartDate: "",
  //   EndDate: "",
  // });
  const [layoutTwo, setLayoutTwo] = useState({
    PlanTitle: "",
    Currency: "",
    BasePrice: "",
    PricePerDistance: "",
    DistanceUnit: "",
    PlatformFee: "",
    OrderItemsLimit: "",
    OrderLabels: "",
    FixedPrice: "",
    Status: "",
  });
  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    // const newContact = { ...layoutOne };
    // newContact[e.target.name] = e.target.value;

    // newContact.layoutName !== "" &&
    // newContact.layoutOrderby !== "" &&
    // newContact.layoutDisplayLimit !== "" &&
    // newContact.layoutCouponAmount !== "" &&
    // newContact.layoutTwoName !== ""
    //   ? setdisable(false)
    //   : setdisable(true);
    // setLayoutOne(newContact);

    const newContact2 = { ...layoutTwo };
    newContact2[e.target.name] = e.target.value;
    newContact2.layoutName !== "" &&
      newContact2.layoutOrderby !== "" &&
      newContact2.layoutDisplayLimit !== "" &&
      newContact2.layoutCurrency !== "" &&
      setLayoutTwo(newContact2);
  };

  const handleChange2 = (e) => {
    const newContact2 = { ...layoutTwo };
    newContact2[e.target.name] = e.target.value;
    console.log(e.target.name);
    console.log(e.target.value);
    setLayoutTwo(newContact2);
  };
  //console.log(layoutOne);
  //console.log(layoutTwo);

  // let finalPostdata = {
  //   isForReview: isForReview,
  //   isCouponForAllProduct: isCouponForAllProduct,
  //   couponCode: layoutOne.CouponName,
  //   couponStartDate: layoutOne.StartDate,
  //   couponEndDate: layoutOne.EndDate,
  //   couponAmount: layoutOne.layoutCouponAmount,
  //   couponIsPercentage: true,
  //   couponMinPurchase: 3,
  // };

  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.value);
  };

  let finalPostdata2 = {
    planTitle: layoutTwo.PlanTitle,
    currency: layoutTwo.Currency,
    basePrice: layoutTwo.BasePrice,
    pricePerDistance: layoutTwo.PricePerDistance,
    distanceUnit: selectedUnit,
    platformFee: layoutTwo.PlatformFee,
    orderItemsLimit: layoutTwo.OrderItemsLimit,
    applicableForOrderLabels: selectedLabel,
    isFixedPrice: false,
    status: isActive,
  };

  // const handleSubmit = () => {
  //   axios
  //     .post(`${Base_Url}/create-coupon`, finalPostdata)
  //     .then((res) => {
  //       console.log("sucess", res.data);
  //       created();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const handleSubmit2 = () => {
    axios
      .post(`${Base_Url}/create-deliveryplan`, finalPostdata2)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <br></br>
      {/* Delivery Plans */}
      <h1>Create Delivery Plan</h1>
      <div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Delivery Plan
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter Delivery Plan "
              label="PlanTitle:"
              name="PlanTitle"
              value={layoutTwo.PlanTitle}
              onChange={handleChange2}
            />
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Currency
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter Currency "
              label="layoutCurrency:"
              name="Currency"
              value={layoutTwo.Currency}
              onChange={handleChange2}
            />
          </div>
        </div>

        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Base Price
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter BasePrice "
              label="layoutBasePrice:"
              name="BasePrice"
              value={layoutTwo.BasePrice}
              onChange={handleChange2}
            />
          </div>
        </div>

        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Price Per Distance
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter Price Per Distance "
              label="layoutPricePerDistance:"
              name="PricePerDistance"
              value={layoutTwo.PricePerDistance}
              onChange={handleChange2}
            />
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Distance Unit
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <select
              className="form-select"
              id="val-username"
              placeholder="Enter Distance Unit "
              label="layoutDistanceUnit:"
              name="DistanceUnit"
              value={selectedUnit}
              onChange={handleUnitChange}
            >
              <option value="km">Kilometer</option>
              <option value="mile">Mile</option>
              <option value="m">Meter</option>
              {/* Add more options as needed */}
            </select>
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Platform Fee
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter Platform Fee "
              label="PlatformFee:"
              name="PlatformFee"
              value={layoutTwo.PlatformFee}
              onChange={handleChange2}
            />
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Order Items Limit
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter Order Items Limit "
              label="layoutOrderItemsLimit:"
              name="OrderItemsLimit"
              value={layoutTwo.OrderItemsLimit}
              onChange={handleChange2}
            />
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Order Labels
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <select
              id="orderLabel"
              className="form-control"
              value={selectedLabel}
              onChange={handleLabelChange}
            >
              <option value="INSTANT">INSTANT</option>
              <option value="PREORDER">PRE ORDER</option>
              <option value="WISHORDER">WISH ORDER</option>
              <option value="WISHCATERING">WISH CATERING</option>
            </select>
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Fixed Price
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter  FixedPrice "
              label="layoutFixedPrice:"
              name="FixedPrice"
              value={layoutTwo.FixedPrice}
              onChange={handleChange2}
            />
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Status
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <label className="m-3">
              <input
                type="radio"
                value="true"
                checked={isActive}
                onChange={handleStatusChange}
                className="p-2"
              />
              Active
            </label>
            <label>
              <input
                type="radio"
                value="false"
                checked={!isActive}
                onChange={handleStatusChange}
                className="p-2"
              />
              Inactive
            </label>
          </div>
        </div>

        <Button
          //disabled={disable}
          onClick={() => {
            handleSubmit2();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CreatePlan;
