import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";

const TransactionDetailsModel = ({ show, setShow, data }) => {
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("BANK_ACCOUNT");
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [suggestedBanks, setSuggestedBanks] = useState([]);
  //configure react hook form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  //get bank

  const getBank = async () => {
    const res = await axios.get(`${Base_Url}/get-banklist`);
    setBankList(res.data.data);
    console.log(res, "bank-res", bankList);
  };
  useEffect(() => {
    getBank();
  }, []);
  //   getBank();
  //handle submit post
  const onSubmit = async (value) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    // console.log(signal, "debug-plan");
    try {
      const response = await axios.post(
        `${Base_Url}/create-transaction-details`,
        {
          withdraw: data,
          transactionDetails: { ...value, fromBankName: selectedBank },
        },
        {
          signal,
        }
      );
      //   console.log(signal, "debug-plan", response.data.status);
      if (response.data.status === "Success") {
        Swal.fire({
          icon: "success",
          title: "Done",
          timer: 3000,
        });
      }
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error posting data:", error);
        // setError(error.message);
        setLoading(false);
      }
    }
  };
  // Handle input change for bank name
  const handleBankNameChange = (event) => {
    const input = event.target.value.toLowerCase();
    console.log(input);
    const suggestions = bankList.filter((bank) => {
      let bankL = bank.bankName.toLowerCase().includes(input);
      console.log(bankL);
      return bankL;
    });
    setSuggestedBanks(suggestions);
    setSelectedBank(input); // Clear selected bank when input changes
  };
  const handleSuggestionClick = (bankName) => {
    setSelectedBank(bankName);
    setSuggestedBanks([]);
  };
  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                <div className='form-group mb-3'>
                  <label className=''>payment type</label>

                  <select
                    {...register("paymentMethod", {
                      required: true,
                    })}
                    onChange={(e) => setAccountType(e.target.value)}
                    name='paymentMethod'
                    className='form-control input-default'
                  >
                    <option defaultChecked value='BANK_ACCOUNT'>
                      Bank
                    </option>
                    <option value='MOBILE_BANKING'>Mobile Bank</option>
                  </select>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                <div className='form-group mb-3'>
                  <label>Transaction Number</label>
                  <input
                    type='text'
                    className='form-control input-default '
                    placeholder='Transaction Number'
                    label='transactionID'
                    name='transactionID'
                    {...register("transactionID", { required: true })}
                  />
                  {errors.transactionID && (
                    <p className='text-danger'> Title is required</p>
                  )}
                </div>
              </div>
            </div>

            {accountType === "BANK_ACCOUNT" && (
              <div className='row'>
                {/* <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                  <div className='form-group mb-3'>
                    <label>Transaction Number</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Transaction Number'
                      label='transactionID'
                      name='transactionID'
                      {...register("transactionID", { required: true })}
                    />
                    {errors.transactionID && (
                      <p className='text-danger'> Title is required</p>
                    )}
                  </div>
                </div> */}
                <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                  <div className='form-group mb-3'>
                    <label>From Bank name</label>
                    <input
                      type='text'
                      value={selectedBank}
                      className='form-control input-default '
                      placeholder='From Bank name'
                      label='fromBankName'
                      name='fromBankName'
                      onChange={handleBankNameChange}
                      //   {...register("fromBankName", { required: true })}
                    />
                    {suggestedBanks.length > 0 && (
                      <div className='suggestions'>
                        {suggestedBanks.map((bank, index) => (
                          <div
                            key={index}
                            className='suggestion'
                            onClick={() => handleSuggestionClick(bank.bankName)} // Handle suggestion click
                          >
                            {bank.bankName}
                          </div>
                        ))}
                      </div>
                    )}

                    {errors.fromBankName && (
                      <p className='text-danger'> Title is required</p>
                    )}
                  </div>
                </div>

                <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                  <div className='form-group mb-3'>
                    <label>From Account No </label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='From Account Number '
                      label='fromAccountNo'
                      name='fromAccountNo'
                      {...register("fromAccountNo", {
                        required: true,
                      })}
                    />
                    {errors.fromAccountNo && (
                      <p className='text-danger'>This Field is required</p>
                    )}
                  </div>
                </div>
                {/* <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
        <div className='form-group mb-3'>
          <label>Status</label>
          <select
            {...register("status", {
              required: true,
            })}
            className='form-control input-default'
          >
            <option value='true'>Instant</option>
            <option value='false'>Preorder</option>
            <option value='false'>Wish</option>
            <option value='false'>Catering</option>
          </select>
          {errors.status && (
            <p className='text-danger'>This Field is required</p>
          )}
        </div>
      </div> */}
              </div>
            )}

            {accountType === "MOBILE_BANKING" && (
              <div className='row'>
                <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                  <div className='form-group mb-3'>
                    <label>Mobile Number</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='From Mobile Number'
                      label='fromMobileNumber'
                      name='fromMobileNumber'
                      {...register("fromMobileNumber", { required: true })}
                    />
                    {errors.fromBankName && (
                      <p className='text-danger'> Title is required</p>
                    )}
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                  <div className='form-group mb-3'>
                    <label>Mobile Banking Operator </label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='From Account Number '
                      label='fromMobileBankingOperator'
                      name='fromMobileBankingOperator'
                      {...register("fromMobileBankingOperator", {
                        required: true,
                      })}
                    />
                    {errors.fromMobileBankingOperator && (
                      <p className='text-danger'>This Field is required</p>
                    )}
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                  <div className='form-group mb-3'>
                    <label>Mobile Banking Type</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='From Mobile Banking Type'
                      label='fromMobileBankingType'
                      name='fromMobileBankingType'
                      {...register("fromMobileBankingType", {
                        required: true,
                      })}
                    />
                    {errors.fromMobileBankingType && (
                      <p className='text-danger'>This Field is required</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Bank Name </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Bank Name '
                          label='toBankName'
                          name='toBankName'
                          {...register("toBankName", {
                            required: true,
                          })}
                        />
                        {errors.toBankName && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Bank Account number </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Bank Account number '
                          label='To Bank Account number'
                          name='toBankAccountNo'
                          {...register("toBankAccountNo", {
                            required: true,
                          })}
                        />
                        {errors.toBankAccountNo && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Mobile Number </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Mobile Number'
                          label='toMobileNumber'
                          name='toMobileNumber'
                          {...register("toMobileNumber", {
                            required: true,
                          })}
                        />
                        {errors.toMobileNumber && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div> */}

            {/* <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>To Mobile Banking Operator </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='To Mobile Banking Operator '
                          label='To Mobile Banking Operator'
                          name='toMobileBankingOperator'
                          {...register("toMobileBankingOperator", {
                            required: true,
                          })}
                        />
                        {errors.toMobileBankingOperator && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Amount </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Amount '
                          label='Amount'
                          name='amount'
                          {...register("amount", {
                            required: true,
                          })}
                        />
                        {errors.amount && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Currency </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Currency '
                          label='Currency'
                          name='currency'
                          {...register("currency", {
                            required: true,
                          })}
                        />
                        {errors.amount && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div> */}

            <div className='d-flex justify-content-center'>
              <button
                className='btn btn-primary w-100'
                type='submit'
                disabled={loading}
              >
                {loading ? "Loading...." : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShow(false)}>
            Close
          </Button>
          {/* <Button variant='primary' onClick={() => setShow(false)}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransactionDetailsModel;
