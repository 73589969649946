import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import { useForm } from "react-hook-form";

const CreateServiceArea = () => {
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [region, setRegion] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const getCountry = async () => {
    const response = await axios.get(`${Base_Url}/get-country`);
    console.log("get-country", response);
  };
  useEffect(() => {
    getCountry();
  }, []);
  const onSubmit = async (value) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    console.log(signal, "debug-plan");
    try {
      const response = await axios.post(
        `${Base_Url}/create-deliveryplan`,
        value,
        {
          signal,
        }
      );
      console.log(signal, "debug-plan", response.data.status);
      if (response.data.status == "Success") {
        Swal.fire({
          icon: "success",
          title: "Delivery Plan Created",
          timer: 3000,
        });
      }
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error posting data:", error);
        // setError(error.message);
        setLoading(false);
      }
    }
  };
  return (
    <div>
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Create New Service Area</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
                      <div className='form-group mb-3'>
                        <label>Service Area Title</label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Service Area Title'
                          label='serviceAreaTitle'
                          name='serviceAreaTitle'
                          {...register("serviceAreaTitle", { required: true })}
                        />
                        {errors.serviceAreaTitle && (
                          <p className='text-danger'> Title is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
                      <div className='form-group mb-3'>
                        <label>Status</label>
                        <select
                          {...register("status", {
                            required: true,
                          })}
                          className='form-control input-default'
                        >
                          <option value='true'>Instant</option>
                          <option value='false'>Preorder</option>
                          <option value='false'>Wish</option>
                          <option value='false'>Catering</option>
                        </select>
                        {errors.status && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Country </label>

                        <select
                          {...register("countryID", {
                            required: true,
                          })}
                          className='form-control input-default'
                        >
                          <option value='true'>Instant</option>
                          <option value='false'>Preorder</option>
                          <option value='false'>Wish</option>
                          <option value='false'>Catering</option>
                        </select>
                        {errors.countryID && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>

                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Region </label>

                        <select
                          {...register("regionID", {
                            required: true,
                          })}
                          className='form-control input-default'
                        >
                          <option value='true'>Instant</option>
                          <option value='false'>Preorder</option>
                          <option value='false'>Wish</option>
                          <option value='false'>Catering</option>
                        </select>
                        {errors.regionID && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>

                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>City </label>

                        <select
                          {...register("cityID", {
                            required: true,
                          })}
                          className='form-control input-default'
                        >
                          <option value='true'>Instant</option>
                          <option value='false'>Preorder</option>
                          <option value='false'>Wish</option>
                          <option value='false'>Catering</option>
                        </select>
                        {errors.cityID && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Thana </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Thana '
                          label='thanaID'
                          name='thanaID'
                          {...register("thanaID", {
                            required: true,
                          })}
                        />
                        {errors.thanaID && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Zip Code </label>
                        <input
                          type='text'
                          className='form-control input-default '
                          placeholder='Zip Code '
                          label='Base Price'
                          name='zipCode'
                          {...register("zipCode", {
                            required: true,
                          })}
                        />
                        {errors.zipCode && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                      <div className='form-group mb-3'>
                        <label>Radious Coverage </label>
                        <input
                          type='number'
                          className='form-control input-default '
                          placeholder='Price per Distance'
                          label='radiusCoverage'
                          name='radiusCoverage'
                          {...register("radiusCoverage", {
                            required: true,
                          })}
                        />
                        {errors.radiusCoverage && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 '>
                      <div className='form-group mb-3'>
                        <label>Service Area Description</label>
                        <textarea
                          style={{ height: "60px" }}
                          className='form-control input-default'
                          placeholder='Service Area Description'
                          {...register("serviceAreaDesc", {
                            required: true,
                          })}
                        ></textarea>
                        {errors.serviceAreaDesc && (
                          <p className='text-danger'>This Field is required</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-primary w-100'
                      type='submit'
                      disabled={loading}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateServiceArea;
