import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";
import axios from "axios";
import { useEffect } from "react";
import { Base_Url } from "../../../misc";
const CreateAdditionalTaq = () => {
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const [selectedImage, setSelectedImage] = useState([]);
  const [data, setData] = useState({
    categoryName: "",
    categorySlug: "",
  });

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState(true);
  const [additionaltags, setAdditionaltags] = useState([]);

  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  const submit = () => {
    let subdata = {
      tagName: data.categoryName,

      status: currentFruit,
    };

    axios
      .post(`${Base_Url}/create-additionaltags`, subdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-additionaltags`)
      .then((res) => {
        setAdditionaltags(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [additionaltags]);

  const handleDelete = (data) => {
    axios
      .delete(`${Base_Url}/delete-additionaltags/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    // set the state with the array of files
    setSelectedImage(fileList);
  };

  const handleSubmit = () => {
    // e.preventDefault();

    const formData = new FormData();

    formData.append("folder", "Cuisine/" + Seller?.sellerIDInfo[0]._id); // folder name
    selectedImage.forEach((file) => {
      formData.append("cover[]", file);
    });

    fetch(`${Base_Url}/imguploads`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          submit();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ToastContainer />{" "}
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>
                Enter Detials of New Additional Tag
              </h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-group mb-3'>
                    <label>Additional Tag Name</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Type Name'
                      label='Name:'
                      name='categoryName'
                      value={data.categoryName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='row d-flex'>
                    <div className='form-group mb-3 col-md-4'>
                      <label>Status</label>
                      <select
                        defaultValue={"option"}
                        onChange={(event) => changeFruit(event.target.value)}
                        value={changeFruit}
                        id='inputState'
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Choose Status
                        </option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>
                  </div>

                  <div
                    className=' justify-content-center mt-3 mb-5'
                    style={{ marginLeft: "30%" }}
                  >
                    {selectedImage && (
                      <div>
                        {selectedImage.map((item, index) => {
                          console.log(item);
                          return (
                            <img
                              alt='not found'
                              width={"80px"}
                              src={URL.createObjectURL(item)}
                            />
                          );
                        })}
                        <br />
                        <Button
                          style={{ marginLeft: 25 }}
                          onClick={() => setSelectedImage(null)}
                        >
                          Remove
                        </Button>
                      </div>
                    )}

                    <br />
                    <br />

                    <form id='form-file-upload'>
                      <input
                        type='file'
                        id='input-file-upload'
                        multiple
                        onChange={handleFileChange}
                        // onChange={(event) => {
                        //   console.log(event.target.files[0]);
                        //   setSelectedImage(Array.from(event.target.files));
                        // }}
                      />
                      <label id='label-file-upload' htmlFor='input-file-upload'>
                        <div>
                          <p>Drag and drop your file here or</p>
                          <button className='upload-button'>
                            Upload a file
                          </button>
                        </div>
                      </label>
                    </form>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          submit();
        }}
      >
        {" "}
        Save
      </Button>
      <div className='card h-auto' style={{ marginTop: 10 }}>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {additionaltags === undefined ? (
              ""
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th>Tag Name</th>
                    <th>status</th>
                    <th>Action</th>
                    <th className='bg-none'></th>
                    <th className='bg-none'></th>
                  </tr>
                </thead>
                <tbody>
                  {additionaltags.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item.tagName}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>
                                {item.status === true ? "Active" : "Inactive"}
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to='/editaAdditionalTags'
                              state={item}
                              className='btn btn-outline-primary'
                            >
                              Edit
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={"#"}
                              onClick={() => {
                                // handleDelete(item._id);
                                del(item._id);
                              }}
                              className='btn btn-outline-primary'
                            >
                              Delete
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAdditionalTaq;
