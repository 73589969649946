import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";

const ActiveSubCategory = () => {
  const [Prepending, setPrepending] = useState([]);
  useEffect(() => {
    axios
      .get(`${Base_Url}/get-subcategory`)
      .then((res) => {
        setPrepending(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(Prepending);

  const filterByStatus = (arr, status) => {
    return arr.filter((item) => item.status === status);
  };

  const availableItems = filterByStatus(Prepending, "true");
  console.log(availableItems);
  const handleApprove = (id) => {
    let data = {
      status: "false",
    };
    axios
      .post(`${Base_Url}/update-subcategory/${id}`, data)
      .then((res) => {
        if (res.data.status === "Success") {
          window.location.reload();
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  return (
    <>
      <div className='card h-auto'>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {availableItems === undefined ? (
              ""
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th className='bg-none sorting_asc'>
                      <div className='form-check style-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='checkAll'
                          // onClick={() => chackboxFun("all")}
                        />
                      </div>
                    </th>
                    <th>Image</th>
                    <th>categoryName</th>
                    <th>createdBy</th>
                    <th>Action</th>

                    <th className='bg-none'></th>
                    <th className='bg-none'></th>
                  </tr>
                </thead>
                <tbody>
                  {availableItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className='application_sorting_1'>
                          <div className='form-check style-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              // onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <img
                              className='me-3 rounded-circle'
                              src={item.categoryImage}
                              alt='images'
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 className='mb-0'>{item.categoryName}</h5>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <h5 className='mb-0'>{item.createdBy}</h5>
                          </div>
                          {/* <span>2,97 Km</span> */}
                        </td>

                        <td>
                          <span
                            className='badge badge-xl light badge-success'
                            onClick={() => {
                              handleApprove(item._id);
                            }}
                          >
                            <Link>Disapprove</Link>
                          </span>
                        </td>
                        {/* <td>
                          <div>
                            <Link
                              to={"#"}
                              onClick={() => {
                                // del(item._id);
                              }}
                              className="btn btn-outline-primary"
                            >
                              Delete
                            </Link>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveSubCategory;
