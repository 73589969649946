import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import moment from "moment";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import MyPagination from "../Pagination/MyPagination";
const CreateCoupon = () => {
  const [isForReview, setisForReview] = useState(false);
  const [isCouponForAllProduct, setisCouponForAllProduct] = useState(false);
  const [coupon, setCoupon] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [layoutOne, setLayoutOne] = useState({
    CouponName: "",
    layoutCouponAmount: "",
    StartDate: "",
    EndDate: "",
  });

  // Filtering Logic
  useEffect(() => {
    let filtered = coupon;

    if (searchTerm.trim() !== "") {
      filtered = filtered.filter((item) =>
        item?.coupon?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, coupon]);

  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" &&
    newContact.layoutOrderby !== "" &&
    newContact.layoutDisplayLimit !== "" &&
    newContact.layoutCouponAmount !== "" &&
    newContact.layoutTwoName !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  console.log(layoutOne);

  let finalPostdata = {
    isForReview: isForReview,
    isCouponForAllProduct: isCouponForAllProduct,
    couponCode: layoutOne.CouponName,
    couponStartDate: layoutOne.StartDate,
    couponEndDate: layoutOne.EndDate,
    couponAmount: layoutOne.layoutCouponAmount,
    couponIsPercentage: true,
    couponMinPurchase: 3,
  };

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-coupon`)
      .then((res) => {
        setCoupon(res.data.data);
        console.log(coupon);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    axios
      .post(`${Base_Url}/create-coupon`, finalPostdata)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (data) => {
    console.log(data);
    axios
      .delete(`${Base_Url}/delete-coupon/${data}`)
      .then((res) => {
        console.log(res);
        // window.location.reload();
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const PAGE_SIZE = 3;
  // Pagination Logic
  const itemsPerPage = PAGE_SIZE;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (
    filteredData?.length > 0 ? filteredData : coupon
  )?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData?.length : coupon?.length) /
      PAGE_SIZE
  );

  console.log(coupon);
  return (
    <>
      <div className="border rounded p-4">
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Coupon Code
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter a Coupon Code "
              label="CouponName:"
              name="CouponName"
              value={layoutOne.CouponName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Coupon Amount
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="val-username"
              placeholder="Enter Coupon amount "
              label="layoutCouponAmount:"
              name="layoutCouponAmount"
              value={layoutOne.layoutCouponAmount}
              onChange={handleChange}
            />
          </div>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            defaultChecked={isCouponForAllProduct}
            onChange={() => setisCouponForAllProduct(!isCouponForAllProduct)}
          />
          <label class="form-check-label" for="flexCheckDefault">
            Is For All Product
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            defaultChecked={isForReview}
            onChange={() => setisForReview(!isForReview)}
          />
          <label class="form-check-label" for="flexCheckDefault">
            Is for Review
          </label>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Coupon Start Date
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="date"
              className="form-control"
              id="val-username"
              placeholder="Enter a Coupon Code "
              label="StartDate:"
              name="StartDate"
              value={layoutOne.StartDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label className="col-lg-4 col-form-label" htmlFor="val-username">
            Coupon End Date
            <span className="text-danger">*</span>
          </label>
          <div className="col-lg-6">
            <input
              type="date"
              className="form-control"
              id="val-username"
              placeholder="Enter a Coupon Code "
              label="EndDate:"
              name="EndDate"
              value={layoutOne.EndDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button
          disabled={disable}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save Coupon
        </Button>
      </div>

      <div className="mt-4 border rounded p-4">
        <>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Filter by Coupon Code"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control mr-2 p-2 m-2"
              style={{ width: "400px" }}
            />
          </div>
          <div>
            <div className="card h-auto" style={{ marginTop: 10 }}>
              <div className="card-body p-0">
                <div className="table-responsive">
                  {currentItems === undefined ? (
                    ""
                  ) : (
                    <table
                      className="table table-list i-table style-1 mb-4 border-0"
                      id="guestTable-all3"
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Coupon Code</th>
                          <th>Amount</th>
                          <th>Start Date</th>
                          <th>End Date</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="media-bx d-flex py-3 align-items-center">
                                  <div>
                                    {index + 1 + PAGE_SIZE * (currentPage - 1)}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="media-bx d-flex py-3 align-items-center">
                                  <div>{item.couponCode}</div>
                                </div>
                              </td>
                              <td>
                                <div className="media-bx d-flex py-3 align-items-center">
                                  <div>{item?.couponAmount}</div>
                                </div>
                              </td>
                              <td>
                                <div className="media-bx d-flex py-3 align-items-center">
                                  <div>
                                    {moment(item.couponStartDate).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="media-bx d-flex py-3 align-items-center">
                                  <div>
                                    {moment(item.couponEndDate).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex gap-2">
                                  <Link
                                    to={`/EditCoupon/${item._id}`}
                                    state={item}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#d97706",
                                        padding: "5px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <AiFillEdit size={18} color="white" />
                                    </div>
                                  </Link>
                                  <div
                                    onClick={() => {
                                      del(item._id);
                                    }}
                                    style={{
                                      backgroundColor: "#db2777",
                                      padding: "5px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <AiFillDelete size={18} color="white" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}

                  <MyPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default CreateCoupon;
