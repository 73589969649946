import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cropper from "react-easy-crop";
import axios from "axios";
import { useEffect } from "react";
import { Base_Url } from "../../../../misc";
const CreateCuisine = () => {
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const [selectedImage, setSelectedImage] = useState([]);
  const [data, setData] = useState({
    categoryName: "",
    categorySlug: "",
  });

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState();

  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  const submit = () => {
    let subdata = {
      categoryName: data.categoryName,
      categorySlug: data.categorySlug,
      status: currentFruit,
    };

    axios
      .post(`${Base_Url}/create-orderStatus`, subdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    // set the state with the array of files
    setSelectedImage(fileList);
  };

  const handleSubmit = () => {
    // e.preventDefault();

    const formData = new FormData();

    formData.append("folder", "Cuisine/" + Seller?.sellerIDInfo[0]._id); // folder name
    selectedImage.forEach((file) => {
      formData.append("cover[]", file);
    });

    fetch(`${Base_Url}/imguploads`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          submit();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ToastContainer />{" "}
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Enter Detials of New Order Status</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-group mb-3'>
                    <label>cuisine Name</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Order Status Title'
                      label='Name:'
                      name='categoryName'
                      value={data.categoryName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='row d-flex'>
                    <div className='form-group mb-3 col-md-4'>
                      <label>Status</label>
                      <select
                        defaultValue={"option"}
                        onChange={(event) => changeFruit(event.target.value)}
                        value={currentFruit}
                        id='inputState'
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Choose Status
                        </option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>
                    <div
                      className=' justify-content-center mt-3 mb-5'
                      style={{ marginLeft: "30%" }}
                    >
                      {selectedImage && (
                        <div>
                          {selectedImage.map((item, index) => {
                            console.log(item);
                            return (
                              <img
                                alt='not found'
                                width={"80px"}
                                src={URL.createObjectURL(item)}
                              />
                            );
                          })}
                          <br />
                          <Button
                            style={{ marginLeft: 25 }}
                            onClick={() => setSelectedImage(null)}
                          >
                            Remove
                          </Button>
                        </div>
                      )}

                      <br />
                      <br />

                      <form id='form-file-upload'>
                        <input
                          type='file'
                          id='input-file-upload'
                          multiple
                          onChange={handleFileChange}
                          // onChange={(event) => {
                          //   console.log(event.target.files[0]);
                          //   setSelectedImage(Array.from(event.target.files));
                          // }}
                        />
                        <label
                          id='label-file-upload'
                          htmlFor='input-file-upload'
                        >
                          <div>
                            <p>Drag and drop your file here or</p>
                            <button className='upload-button'>
                              Upload a file
                            </button>
                          </div>
                        </label>
                      </form>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          submit();
        }}
      >
        {" "}
        Save
      </Button>
    </>
  );
};

export default CreateCuisine;
