import React, { useState, useEffect } from "react";
import { Col, Row, Card, Dropdown, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { Base_Url } from "../../../../misc";
const EditCirclePage = () => {
  const location = useLocation();
  const item = location.state;
  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState();
  const [cat, setCat] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [SecondselectedOptions, setTwoSelectedOptions] = useState();

  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [layoutOne, setLayoutOne] = useState({
    layoutName: item.circleType,
    layoutLimit: item.circleLimit,
  });

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" && newContact.layoutLimit !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  useEffect(() => {
    if (item?.circleCategoryID.lenght > 0) {
      console.log(item.circleCategoryID);
      getCat("get-category");
    } else if (item?.circleCountryID.lenght > 0) {
      console.log(item.circleCountryID);
      getCat("get-country");
    } else if (item?.circleSellerID.lenght > 0) {
      console.log(item.circleSellerID);
      getCat("get-become-seller");
    }
  }, []);

  const getCat = (url_id) => {
    console.log(url_id);
    axios
      .get(`${Base_Url}/${url_id}`)
      .then((res) => {
        console.log(res.data.data);
        setCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getlabel = (el) => {
    if (el.categoryName !== undefined) {
      return el.categoryName;
    } else if (el.countryName !== undefined) {
      return el.countryName;
    } else if (el.kitchenName !== undefined) {
      return el.kitchenName;
    }
  };

  useEffect(() => {
    if (cat && cat !== undefined) {
      const tttt = [];
      cat.forEach((el) => {
        const data = {
          value: el._id,
          label: getlabel(el),
        };

        tttt.push(data);
        setOptions(tttt);
      });
    }
  }, [cat]);
  console.log(options);

  let finalPostdata = {
    circleType: layoutOne.layoutName,
    circleSellerID: selectedOptions,
    circleLimit: layoutOne.layoutLimit,
  };

  const handleSubmit = () => {
    axios
      .post(`${Base_Url}/update-circle-type-food/${item._id}`, finalPostdata)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <>
        {" "}
        <Row>
          <Col>
            <Card>
              <Card.Header className='d-block'>
                <Card.Title>
                  Section Layout For Best Seller On Circle
                </Card.Title>
                <Card.Text className='m-0 subtitle'>
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username'
                          >
                            Section Title
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <input
                              type='text'
                              className='form-control'
                              id='val-username'
                              name='layoutName'
                              placeholder='Enter a section Title..'
                              defaultValue={layoutOne.layoutName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        {item.circleCategoryID !== undefined ? (
                          <div className='form-group mb-3 row'>
                            <label
                              className='col-lg-4 col-form-label'
                              htmlFor='val-username'
                            >
                              Select Seller
                              <span className='text-danger'>*</span>
                            </label>
                            <div className='col-lg-6'>
                              <Select
                                defaultValue={item.circleCategoryID}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                // defaultValue={selectedOptions}
                                onChange={(item) => setSelectedOptions(item)}
                                classNamePrefix='select'
                                // defaultValue={[Options[4], Options[5]]}
                                isMulti
                                options={options}
                              />
                            </div>
                          </div>
                        ) : "" ? (
                          item.circleCountryID !== undefined ? (
                            <div className='form-group mb-3 row'>
                              <label
                                className='col-lg-4 col-form-label'
                                htmlFor='val-username'
                              >
                                Select Seller
                                <span className='text-danger'>*</span>
                              </label>
                              <div className='col-lg-6'>
                                <Select
                                  defaultValue={item.circleCountryID}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  // defaultValue={selectedOptions}
                                  onChange={(item) => setSelectedOptions(item)}
                                  classNamePrefix='select'
                                  // defaultValue={[Options[4], Options[5]]}
                                  isMulti
                                  options={options}
                                />
                              </div>
                            </div>
                          ) : "" ? (
                            item.circleSellerID !== undefined
                          ) : (
                            <div className='form-group mb-3 row'>
                              <label
                                className='col-lg-4 col-form-label'
                                htmlFor='val-username'
                              >
                                Select Seller
                                <span className='text-danger'>*</span>
                              </label>
                              <div className='col-lg-6'>
                                <Select
                                  defaultValue={item.circleSellerID}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  // defaultValue={selectedOptions}
                                  onChange={(item) => setSelectedOptions(item)}
                                  classNamePrefix='select'
                                  // defaultValue={[Options[4], Options[5]]}
                                  isMulti
                                  options={options}
                                />
                              </div>
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>

            <div className='form-group mb-3 row'>
              <label className='col-lg-4 col-form-label' htmlFor='val-username'>
                Section Displaylimit
                <span className='text-danger'>*</span>
              </label>
              <div className='col-lg-6'>
                <input
                  type='text'
                  className='form-control'
                  id='val-username'
                  name='layoutLimit'
                  placeholder='Enter a section Display Limit..'
                  defaultValue={layoutOne.layoutLimit}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: 50 }}>
          <Button
            onClick={() => {
              handleSubmit();
            }}
          >
            Update
          </Button>
        </div>
      </>
    </>
  );
};

export default EditCirclePage;
