import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
//import {NavLink} from 'react-router-dom';
//import loadable from "@loadable/component";
//import pMinDelay from "p-min-delay";
import { Dropdown, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import BannerSlider from "./Dashboard/BannerSlider";
import CategorySlider from "./Dashboard/CategorySlider";
import PopularDishesSlider from "./Dashboard/PopularDishesSlider";
import RecentOrderSlider from "./Dashboard/RecentOrderSlider";

import Pic1 from "./../../../images/popular-img/review-img/pic-1.jpg";
import Pic2 from "./../../../images/popular-img/review-img/pic-2.jpg";
import Pic3 from "./../../../images/popular-img/review-img/pic-3.jpg";
import BannerPic from "./../../../images/banner-img/pic-2.jpg";
import axios from "axios";
import { Base_Url } from "../../../misc";
import CustomCard from "./CustomCard";

const orderBlog = [
  { id: 1, image: Pic1, number: 1 },
  { id: 2, image: Pic2, number: 1 },
  { id: 3, image: Pic3, number: 1 },
  { id: 4, image: Pic1, number: 1 },
];

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const Home = () => {
  const [dropSelect, setDropSelect] = useState("Other");
  const { changeBackground } = useContext(ThemeContext);
  const [detailsModal, setDetailsModal] = useState(false);
  const [notesModal, setNotesModal] = useState(false);

  //api total data
  const [seller, setSeller] = useState(0);
  const [buyer, setBuyer] = useState(0);
  const [rider, setRider] = useState(0);
  const [totalFood, setTotalFood] = useState(0);
  const [totalFoodCategory, setTotalFoodCategory] = useState(0);
  const [totalRecipe, setTotalRecipe] = useState(0);
  const [totalRecipeCategory, setTotalRecipeCategory] = useState(0);
  const [totalFoodSubCategory, settotalFoodSubCategory] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCoupon, setTotalCoupon] = useState(0);
  const [totalDeliveryPlans, setTotalDeliveryPlans] = useState(0);
  const [totalFoodDiary, setTotalFoodDiary] = useState(0);

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
    //total seller
    axios
      .get(`${Base_Url}/get-become-seller`)
      .then((res) => {
        setSeller(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // total buyer
    axios
      .get(`${Base_Url}/get-users`)
      .then((res) => {
        setBuyer(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // total rider
    axios
      .get(`${Base_Url}/get-rider`)
      .then((res) => {
        setRider(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // total food
    axios
      .get(`${Base_Url}/get-food`)
      .then((res) => {
        setTotalFood(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // total food category
    axios
      .get(`${Base_Url}/get-category`)
      .then((res) => {
        setTotalFoodCategory(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });

    // total food sub category
    axios
      .get(`${Base_Url}/get-subcategory`)
      .then((res) => {
        settotalFoodSubCategory(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // total recipe
    axios
      .get(`${Base_Url}/get-recipe`)
      .then((res) => {
        setTotalRecipe(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // total recipe category
    axios
      .get(`${Base_Url}/get-recipe-category`)
      .then((res) => {
        setTotalRecipeCategory(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
    // total coupon
    axios
      .get(`${Base_Url}/get-coupon`)
      .then((res) => {
        setTotalCoupon(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });

    // total food diary
    axios
      .get(`${Base_Url}/get-coupon`)
      .then((res) => {
        setTotalFoodDiary(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });

    // total delivery plans
    axios
      .get(`${Base_Url}/get-deliveryplans`)
      .then((res) => {
        setTotalDeliveryPlans(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });

    // total orders
    axios
      .get(`${Base_Url}/get-orders`)
      .then((res) => {
        setTotalOrders(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [state, setState] = useReducer(reducer, { orderBlog: orderBlog });
  const handleCountAdd = (e) => {
    let temp = state.orderBlog.map((data) => {
      if (e === data.id) {
        return { ...data, number: data.number + 1 };
      }
      return data;
    });
    setState({ orderBlog: temp });
  };
  const handleCountMinus = (e) => {
    let temp = state.orderBlog.map((data) => {
      if (e === data.id) {
        return {
          ...data,
          number: data.number > 0 ? data.number - 1 : data.number,
        };
      }
      return data;
    });
    setState({ orderBlog: temp });
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="row">
            <div className="col-xl-12 col-sm-12 col-xs-12 col-lg-12">
              <BannerSlider />
            </div>

            <div className="col-xl-12">
              <div className="d-flex align-items-center justify-content-between mb-2 gap">
                {/* <h4 className=" mb-0 cate-title">Category</h4> */}
                <Link to="/favorite-menu" className="text-primary">
                  {/* <i className="fa-solid fa-angle-right ms-2"></i> */}
                </Link>
              </div>
              {/* <CategorySlider /> */}
            </div>
            {/* <div className="col-xl-12">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h4 className=" mb-0 cate-title">Popular Dishes</h4>
                <Link to="/favorite-menu" className="text-primary">
                  View all <i className="fa-solid fa-angle-right ms-2"></i>
                </Link>
              </div>
              <PopularDishesSlider />
            </div>
            <div className="col-xl-12">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h4 className=" mb-0 cate-title">Recent Order</h4>
                <Link to="/favorite-menu" className="text-primary">
                  View all <i className="fa-solid fa-angle-right ms-2"></i>
                </Link>
              </div>
              <RecentOrderSlider />
            </div> */}
          </div>
        </div>
        <div className="col-xl-12 ">
          <div className="row">
            <CustomCard
              key="1"
              title="Monthly Earned"
              subTitle="Earning"
              count="0"
              onClickAction=""
            />
            <CustomCard
              key="2"
              title="Active Seller"
              subTitle="Active Seller"
              count={seller}
              onClickAction="/all-seller"
            />
            <CustomCard
              key="3"
              title="Active Rider"
              subTitle="Active Rider"
              count={rider}
              onClickAction=""
            />

            <CustomCard
              key="4"
              title="Active buyer"
              subTitle="Active buyer"
              count={buyer}
              onClickAction="/user-list"
            />
            <CustomCard
              key="4"
              title="Total Food Online"
              subTitle="Food"
              count={totalFood}
              onClickAction="/food-list"
            />
            <CustomCard
              key="5"
              title="Total Food Category"
              subTitle="Food Category"
              count={totalFoodCategory}
              onClickAction="/categoryList"
            />
            <CustomCard
              key="6"
              title="Total Food Sub Category"
              subTitle="Food Sub Category"
              count={totalFoodSubCategory}
              onClickAction="/SubCategoryList"
            />
            <CustomCard
              key="7"
              title="Total Recipe"
              subTitle="recipe"
              count={totalRecipe}
              onClickAction="/recipe-list"
            />

            <CustomCard
              key="8"
              title="Total Recipe Category"
              subTitle="Recipe Category"
              count={totalRecipeCategory}
              onClickAction="/recipe-category-list"
            />

            <CustomCard
              key="9"
              title="Total Order"
              subTitle="order"
              count={totalOrders}
              onClickAction="/pending-order"
            />

            <CustomCard
              key="10"
              title="Total Coupon"
              subTitle="coupon"
              count={totalCoupon}
              onClickAction="/create-coupon"
            />

            <CustomCard
              key="11"
              title="Total Delivery Plans"
              subTitle="delivery plans"
              count={totalDeliveryPlans}
              onClickAction="/delivery-plans-list"
            />
          </div>
        </div>
      </div>
      <Modal
        id="exampleModal"
        show={detailsModal}
        onHide={setDetailsModal}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Location Details
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setDetailsModal(false)}
          ></button>
        </div>
        <div className="modal-body add-loaction">
          <div className="row">
            <div className="col-xl-12">
              <form>
                <div className="mb-3">
                  <label className="form-label">Location Name</label>
                  <input
                    type="Text"
                    className="form-control"
                    placeholder="HOUSE/FLAT/BLOCK NO."
                  />
                </div>
              </form>
            </div>
            <div className="col-xl-12">
              <form>
                <div className="mb-3">
                  <label className="form-label">LANDMARK</label>
                  <input
                    type="Text"
                    className="form-control"
                    placeholder="LANDMARK"
                  />
                </div>
              </form>
            </div>
            <div className="col-xl-6">
              <form>
                <div className="mb-3">
                  <label className="form-label">Available For Order</label>
                  <input
                    type="Text"
                    className="form-control"
                    placeholder="Yes"
                  />
                </div>
              </form>
            </div>
            <div className="col-xl-6">
              <p className="mb-1">Address type</p>
              {/* <select className="form-control default-select ms-0 py-4 wide" >
								<option>Home</option>
								<option>Office</option>
								<option>Other</option>
							</select> */}
              <Dropdown className="drop-select-blog">
                <Dropdown.Toggle
                  as="div"
                  className="form-control default-select ms-0 py-4 wide i-false"
                >
                  {dropSelect}{" "}
                  <i className="fas fa-chevron-down drop-select-icon"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setDropSelect("Home")}>
                    Home
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setDropSelect("Office")}>
                    Office
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setDropSelect("Other")}>
                    Other
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setDetailsModal(false)}
          >
            Close
          </button>
          <button type="button" className="btn btn-primary">
            Save changes
          </button>
        </div>
      </Modal>
      <Modal
        className="modal fade"
        id="exampleModal2"
        show={notesModal}
        onHide={setNotesModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel2">
            Manage Route Notes
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setNotesModal(false)}
          ></button>
        </div>
        <div className="modal-body add-note">
          <div className="row align-items-center">
            <div className="col-xl-12">
              <form className="mb-3">
                <label className="form-label">Update Type</label>
                <input
                  type="Text"
                  className="form-control"
                  placeholder="Drop Off Occurred"
                />
              </form>
            </div>

            <div className="col-xl-12">
              <form className=" mb-3">
                <label className="form-label">Drop Off Location</label>
                <input
                  type="Text"
                  className="form-control"
                  placeholder="Front Door"
                />
              </form>
            </div>
            <div className="col-xl-12">
              <div className="mb-3">
                <label className="form-label">Notes</label>
                <textarea
                  className="form-control"
                  placeholder="Delivery was successful."
                  id="floatingTextarea"
                ></textarea>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="mb-3">
                <label className="from-label">Address</label>
                <input
                  type="Text"
                  className="form-control"
                  placeholder="Elm Street, 23"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setNotesModal(false)}
          >
            Close
          </button>
          <button type="button" className="btn btn-primary">
            Save changes
          </button>
        </div>
      </Modal>
    </>
  );
};
export default Home;
