import React, { useState, useEffect } from "react";
import { Row, Card, Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
const CreateCountry = () => {
  const [currentFruit, setCurrentFruit] = useState();
  const [country, setCountry] = useState([]);
  const [data, setData] = useState({
    countryName: "",
    countryFlag: "",
    countryCode: "",
    countryPhoneCode: "",
    countryCapital: "",
  });

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.countryName !== "" && newContact.countryFlag !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const submit = () => {
    let subdata = {
      orderStatusTitle: data.categoryName,
      countryName: data.countryName,
      countryFlag: data.countryFlag,
      countryCode: data.countryCode,
      countryPhoneCode: data.countryPhoneCode,
      countryCapital: data.countryCapital,
      status: currentFruit,
    };

    axios
      .post(`${Base_Url}/create-country`, subdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-country`)
      .then((res) => {
        setCountry(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [country]);

  const handleDelete = (data) => {
    axios
      .delete(`${Base_Url}/delete-country/${data}`)
      
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <Row>
        <Card>
          <Card.Title>Enter Country Detials</Card.Title>
          <div className='form-group mb-3'>
            <label>Country Name</label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Name'
              label='Name:'
              name='countryName'
              value={data.countryName}
              onChange={handleChange}
            />
          </div>

          <div className='form-group mb-3'>
            <label>Country Flag</label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Flag'
              label='Name:'
              name='countryFlag'
              value={data.countryFlag}
              onChange={handleChange}
            />
          </div>
          <div className='form-group mb-3'>
            <label>Country Code</label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Code '
              label='Name:'
              name='countryCode'
              value={data.countryCode}
              onChange={handleChange}
            />
          </div>

          <div className='form-group mb-3'>
            <label>Country Phone Code </label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Phone Code'
              label='Name:'
              name='countryPhoneCode'
              value={data.countryPhoneCode}
              onChange={handleChange}
            />
          </div>
          <div className='form-group mb-3'>
            <label>Country Capital </label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Phone Code'
              label='Name:'
              name='countryCapital'
              value={data.countryCapital}
              onChange={handleChange}
            />
          </div>
          <div className='row d-flex'>
            <div className='form-group mb-3 col-md-4'>
              <label>Status</label>
              <select
                defaultValue={"option"}
                onChange={(event) => changeFruit(event.target.value)}
                value={currentFruit}
                id='inputState'
                className='form-control'
              >
                <option value='option' disabled>
                  Choose Status
                </option>
                <option>true</option>
                <option>false</option>
              </select>
            </div>
          </div>
        </Card>
      </Row>

      <Button
        disabled={disable}
        onClick={() => {
          submit();
        }}
      >
        Save
      </Button>

      <div className='card h-auto' style={{ marginTop: 10 }}>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {country === undefined ? (
              ""
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th>countryName</th>
                    <th>countryFlag</th>
                    <th>countryCode</th>
                    <th>countryPhoneCode</th>
                    <th>countryCapital</th>
                    <th>status</th>
                    <th>Action</th>

                    <th className='bg-none'></th>
                    <th className='bg-none'></th>
                  </tr>
                </thead>
                <tbody>
                  {country.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item?.countryName}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              {/* <h5 className="mb-0">{item?.countryFlag}</h5> */}
                              {/* <p className="mb-0">1x </p>  */}
                              <img src={item?.countryFlag} />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item?.countryCode}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item?.countryPhoneCode}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item?.countryCapital}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>
                                {item.status === true ? "Active" : "Inactive"}
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to='/EditCountry'
                              state={item}
                              className='btn btn-outline-primary'
                            >
                              Edit
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={"#"}
                              onClick={() => {
                                // handleDelete(item._id);
                                del(item._id);
                              }}
                              className='btn btn-outline-primary'
                            >
                              Delete
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCountry;
