import React from "react";
import { useEffect } from "react";
import { Base_Url } from "../../../misc";
import { useState } from "react";
import { Button, Pagination, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import Swal from "sweetalert2";
import axios from "axios";
import MyPagination from "../Pagination/MyPagination";
export const PlanList = () => {
  const [plans, setPlans] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const getPlan = () => {
    fetch(`${Base_Url}/get-deliveryplans`)
      .then((res) => res.json())
      .then((data) => setPlans(data.data));
  };
  useEffect(() => {
    getPlan();
  }, []);
  console.log(plans[0]?.applicableForOrderLabels);

  const handleDelete = (id) => {
    axios
      .delete(`${Base_Url}/delete-deliveryplan/${id}`)
      .then((res) => {
        console.log(res.data.data.deletedCount);
        if (res.data.data.deletedCount === 1) {
          const updateData = plans.filter((r) => r._id !== id);
          setPlans(updateData);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
      }
    });
  };

  // Filtering Logic
  useEffect(() => {
    let filtered = plans;

    if (searchTerm.trim() !== "") {
      filtered = filtered.filter((item) =>
        item?.planTitle?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, plans]);

  const [PAGE_SIZE, setPAGE_SIZE] = useState(10);
  // Pagination Logic
  const itemsPerPage = PAGE_SIZE;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (filteredData?.length > 0 ? filteredData : plans)?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData?.length : plans?.length) / PAGE_SIZE
  );

  return (
    <>
      <div
        className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="input-group search-area2 style-1 w-[75%] border d-flex"
          style={{ marginRight: "15px" }}
        >
          <span className="input-group-text p-0">
            <Link to={"#"}>
              <svg
                className="me-1"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                  fill="#FC8019"
                />
              </svg>
            </Link>
          </span>

          <input
            type="text"
            className="form-control p-2 "
            placeholder="Filter by delivery plan"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="input-group ml-2 pl-2 style-1 w-50">
          {/* Search filter */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      </div>
      <div className="card h-auto" style={{ marginTop: 10 }}>
        <div className="card-body p-0">
          <div className="table-responsive">
            {plans === undefined ? (
              ""
            ) : (
              <Table
                className="table table dataTable display"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        borderRight: "1px solid black",
                      }}
                    >
                      No
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>Title</th>
                    <th style={{ borderRight: "1px solid black" }}>Currency</th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Base Price
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Price Per Distance
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Distance Unit
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Platform Fee
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Order Items Limit
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>Status</th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Order Labels
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Fixed Price
                    </th>
                    <th style={{ borderRight: "1px solid black" }}>
                      Created Date
                    </th>
                    <th className="bg-none">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((item, index) => {
                    console.log(item);
                    return (
                      <tr key={index}>
                        <td style={{ borderRight: "0.5px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>
                              {index + 1 + PAGE_SIZE * (currentPage - 1)}
                            </div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.planTitle}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.currency}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.basePrice}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.pricePerDistance}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.distanceUnit}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.platformFee}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.orderItemsLimit}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.status}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{plans?.applicableForOrderLabels}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>{item?.fixedPrice}</div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="media-bx d-flex py-3 align-items-center justify-content-center">
                            <div>
                              {moment(item?.createdDate).format("MMMM Do YYYY")}
                            </div>
                          </div>
                        </td>
                        <td style={{ borderRight: "1px solid black" }}>
                          <div className="d-flex gap-2">
                            <Link
                              to={`/edit-delivery-plans/${item?._id}`}
                              state={item._id}
                            >
                              <div
                                style={{
                                  backgroundColor: "#d97706",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <AiFillEdit size={18} color="white" />
                              </div>
                            </Link>
                            <div
                              onClick={() => {
                                del(item._id);
                              }}
                              style={{
                                backgroundColor: "#db2777",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <AiFillDelete size={18} color="white" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};
