import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Logo from "./../../../../images/ownfood-logo.png";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { AiFillEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
import { Base_Url } from "../../../../misc";

export const KitchenInfo = ({ item,id }) => {

  const { register, handleSubmit, reset, getValues, setValue } = useForm();

  const [isEditing, setIsEditing] = useState(false);
  const [country, setcountry] = useState([]);
  const [city, setcity] = useState([]);
  const [region, setregion] = useState([]);
  const [kitchenData, setKitchenData] = useState([]);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const [selectedCountryAd, setSelectedCountryAd] = useState("");
  const [selectedRegionAd, setSelectedRegionAd] = useState("");

const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setKitchenData((prev) => {
      const data = { ...prev, [name]: value };
      return data;
    });
  };

const handleCountryChangeAdr = (event) => {
    const newValue = event.target.value;

    setSelectedCountryAd(newValue);
  };
  const handleRegionChangeAdr = (event) => {
    const value = event.target.value;
    setSelectedRegionAd(value);
  };


  const handleEdit = () => {
    setIsEditing(true);
  };
  useEffect(() => {
    

    const getCountry = async () => {
      try {
        const countryResponse = await fetch(`${Base_Url}/get-country`);
        const country = await countryResponse.json();
        console.log(country);
        setcountry(country.data);
      } catch (e) {}
    };
    const getRegion = async () => {
      try {
        const regionResponse = await fetch(`${Base_Url}/get-region-state`);
        const region = await regionResponse.json();
        console.log(region);
        setregion(region.data);
      } catch (e) {}
    };
    const getCity = async () => {
      try {
        const cityResponse = await fetch(`${Base_Url}/get-city`);
        const city = await cityResponse.json();
        console.log(city);
        setcity(city.data);
      } catch (e) {}
    };

    getCountry();
    getCity();
    getRegion();
  }, []);
console.log(country)
  return (
    <div className="card-body rounded border mb-4">
       <h3 className="mb-4">
            {"Kitchen Info"} {!isEditing && <AiFillEdit size={18} color="orange" onClick={handleEdit} />}
          </h3>
      <div className="form-group mb-3">
        <label>
          <b>
            Kitchen Name
            {" "}
           
          </b>
          <span className="text-danger">*</span>
        </label>
        <input
          id="kitchenName"
          disabled
          type="text"
          className="form-control input-default "
          placeholder={"enter kitchen name"}
          name="kitchenName"
          defaultValue={item && item?.kitchenName && (item?.kitchenName || "")}
          
        />
      </div>
      <div className="row">
        <div className="col-12">
          <h5>
            Kitchen Address
            {" "}
          </h5>
        </div>

        {item?.countryID && (
          <div className="col-4">
            <label>
              <b>country </b>
            </label>
            <select
              name="countryAddress"
              className="form-control input-default"
            >
              {country.length > 0 &&
                country.map((i) => {
                  if (country && i?._id === item?.countryID) {
                    console.log(i, "afdasdf");
                    return (
                      <option selected value={i?._id}>
                        {i?.countryName}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
        )}

        {item?.regionID && (
          <div className="col-4">
            <label>
              <b>region </b>
            </label>
            <select className="form-control input-default">
              {region.length > 0 &&
                region.map((i) => {
                  if (region && i?._id === item?.regionID) {
                    return (
                      <option selected value={i._id}>
                        {i.regionName}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
        )}

        {item?.cityID && (
          <div className="col-4 mb-3">
            <label>
              <b>city </b>
            </label>
            <select className="form-control input-default">
              {city?.length > 0 &&
                city?.map((i) => {
                  if (city && i?._id === item?.cityID) {
                    return (
                      <option selected value={i._id}>
                        {i.cityName}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
        )}
       
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <div className="">
            <label htmlFor="exampleInputtext" className="form-label">
              <b>
                address{" "}
               {" "}
              </b>
            </label>
            <input
              id="address2"
              disabled
              type="address"
              className="form-control input-default "
              placeholder={"enter address"}
             
              name="address"
              defaultValue={item?.address}
            />
          </div>
        </div>

        <div className="col-6">
          <label>
            <b>
              Thana {" "}
            </b>
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            value={item && item?.policeStation && (item?.policeStation || "")}
            placeholder={"Enter Thana"}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="form-group mb-3 col-6">
          <label>
            <b>
              {" "}
              Nationality
              {" "}
            </b>
            <span className="text-danger">*</span>
          </label>
          <select
            // name='myCountry'
            className="form-control input-default  "
          >
            {country?.length > 0 &&
              country.map((i) => {
                if (country && i._id === item?.sellerNationality) {
                  return (
                    <option selected value={i._id}>
                      {i?.countryName}
                    </option>
                  );
                }
              })}
          </select>
        </div>
        <div className="form-group mb-3 col-6">
          <label>
            <b>
              {" "}
              decent nationality
            </b>
            <span className="text-danger">*</span>
          </label>

          <select
            // name='decentCountry'
            className="form-control input-default "
          >
            {country?.length > 0 &&
              country.map((i) => {
                if (country && i._id === item?.sellerNationalityByBornID) {
                  console.log(i, "afdasdf");
                  return (
                    <option selected value={i._id}>
                      {i.countryName}
                    </option>
                  );
                }
              })}
          </select>
        </div>
      </div>
    </div>
  );
};
