import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
// import { Base_Url } from "../../../Helper/config";
import { Base_Url } from "../../../misc";
// import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import { Button } from "react-bootstrap";
// import { getUserDetails } from "../../../Helper/SessionHelper";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
const EditFood = () => {
  const location = useLocation();
  const item = location.state;
  const [catID, setCatID] = useState([]);
  const [cat, setcat] = useState();
  const [portionId, setPortionId] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [Tag, setTag] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [taq, setAdditionalTaq] = useState([]);

  console.log(item);
  //console.log(selectedImage, "selected image from food update", item);
  const handleImageDeleteFood = (index) => {
    const updatedImages = [...selectedImage];

    updatedImages.splice(index, 1);
    // console.log(selectedImageAmazingDish);
    setSelectedImage(updatedImages);
  };
  // useEffect(()=>{
  //   setSelectedImage(item?.foodImage)
  // },[])
  useEffect(() => {
    axios
      .get(`${Base_Url}/get-additionaltags`)
      .then((res) => {
        setAdditionalTaq(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
    const getCat = (e) => {
      axios
        .get(`${Base_Url}/get-category`)
        .then((res) => setCatID(res.data.data))
        .catch((err) => {
          console.log(err);
        });
    };
    getCat();
    const getPortion = (e) => {
      axios
        .get(`${Base_Url}/get-portionSizeUnit`)
        .then((res) => setPortionId(res.data.data))
        .catch((err) => {
          console.log(err);
        });
    };
    getPortion();
    const getSubcat = (callback) => {
      axios
        .get(`${Base_Url}/get-sub-category-by-single-category/${cat}`)
        .then((res) => {
          console.log(res);
          setSubCat(res.data.data[0]?.subcategoryData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSubcat();
  }, [cat]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setTag((oldArray) => [...oldArray, e.target.value]);
    } else {
      removeCities(e);
      console.log(Tag);
    }
  };
  const removeCities = (e) => {
    setTag([...Tag.filter((Tag) => Tag !== e.target.value)]);
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    setSelectedImage(fileList);
  };
  const [data, setData] = useState({
    name: item?.foodName,
  });
  const handleChange = (e) => {
    console.log("data", data);
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleCreateFood = (e) => {
    e.preventDefault();
    const form = e.target;
    console.log("from", form.name);
    const FoodName = form.name.value;
    console.log("from", FoodName);
    ///const Slug = form.slug.value;
    // const Portion = form.portion.value;
    const Quantity = form.quantity.value;
    const Category = form.category.value;
    const Price = form.price.value;
    const discountPrice = form.discountprice.value;
    const discountPricePercentage = form.foodDiscountPercentage.value;
    const Subcategory = form.subcategory.value;
    const StartDate = form.startdate.value;
    const EndDate = form.enddate.value;
    const FoodMinQty = form.minimumquantity.value;
    const FoodData = {
      // categoryID: Category,
      // subCategoryID: Subcategory,
      // cuisineCollection: { type: Array },
      // sellerImage: { type: String },
      // sellerName: getUserDetails().sellerIDInfo[0].userFullName,
      foodName: FoodName,
      // foodType: { type: String },//Instant /preOrder /wish /catering
      // foodSlug: { type: String },
      // foodImage: [],
      // foodThumb: { type: String },
      // foodVideo: { type: String },
      // foodQty: Quantity,
      // foodPrice: Price,
      // foodDiscountPrice: discountPrice,
      // foodDiscountPercentage: discountPricePercentage,
      // foodDiscountStartDate: StartDate,
      // foodDiscountEndDate: EndDate,
      // foodPortionSize: { type: Number },
      // foodPortionSizeUnit: Portion,
      //  foodMinimumOrderQty: FoodMinQty,
      // // foodAdditionalInfo: { type: String },
      //  foodAdditionalTags: Tag,
      // foodExpiryTime: { type: String }, /n
      // foodAvailableDays: { type: Array }, /n
      // foodAvailableSameDaysEachWeek: { type: Boolean },
      // foodOrderBeforeTime: { type: String },
      // foodRating: { type: Number },
      // status: { type: Boolean },
      // foodLabel: { type: Array }, //instant preorder capitilized n
      // customerRequestedFoodQty: { type: String },
      // customerDesireDeliveryDate: { type: String },
      // customerDesireDeliveryTime: { type: String },
      // createdBy: { type: String },
      // updatedBy: { type: String },
      // BSR: { type: Number },
    };
    // console.log(getUserDetails());
    const imagePromise = ImageUploader("Admin", "Food/", selectedImage).then(
      (data) => {
        if (data.status === "Success") {
          FoodData.foodImage = data.images;
        }
      }
    );

    Promise.resolve(imagePromise).then(async () => {
      console.log(FoodData);
      await axios
        .post(`${Base_Url}/update-food/${item?._id}`, FoodData)
        .then((res) => {
          console.log("update", res.data);
          Swal.fire({
            icon: "success",
            title: "Food Update successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    });
  };
console.log(item);
  return (
    <div className="container d-flex create-food-container">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Edit your Food</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleCreateFood}>
                <div className="form-group mb-3">
                  <label>Food Name</label>
                  <input
                    type="text"
                    className="form-control input-default "
                    placeholder="Food Name"
                    label="Name:"
                    name="name"
                    defaultValue={item?.foodName}
                    // value={data?.name}
                    // onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-6">
                    <label>Food Quantity</label>
                    <input
                      type="number"
                      className="form-control input-default "
                      placeholder="Food Quantity"
                      label="quantity:"
                      name="quantity"
                      defaultValue={item?.foodQty}
                      // value={data?.quantity}
                      // onChange={handleChange}
                    />
                  </div>

                  <div className="form-group mb-3 col-6">
                    <label>Food Price</label>
                    <input
                      type="number"
                      className="form-control input-default "
                      placeholder="Food Price"
                      label="price:"
                      name="price"
                      defaultValue={item?.foodPrice}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                </div>

                <div
                  className="row justify-content-center mt-3 mb-5 "
                  // style={{ marginLeft: "30%" }}
                >
                  <div className="col-12 mb-3">
                    <h3>Previous Food Photo</h3>
                    <div className="row gap-2">
                      {/* <div className=' '> */}

                      <>
                        <div className="col">
                          {item?.foodImage.map((image, index) => {
                            return (
                              <div className="image-box d-flex justify-content-center align-items-center">
                                <div className="img">
                                  <img
                                    alt=""
                                    // className="img-fluid"
                                    src={image?.small?.imageUrl}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>

                      {/* </div> */}
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <h3>Upload Food Photo</h3>
                    <div className="row gap-2">
                      {/* <div className=' '> */}
                      {selectedImage.length <= 6 ? (
                        <>
                          {" "}
                          {[...Array(6)].map((item, index) => (
                            <div className="col">
                              <div className="image-box col justify-content-center align-items-center">
                                {selectedImage[index] &&
                                  selectedImage.length > 0 && (
                                    <div className="img">
                                      <img
                                        alt=""
                                        // className="img-fluid"
                                        src={
                                          selectedImage.length > 0
                                            ? URL.createObjectURL(
                                                selectedImage[index]
                                              )
                                            : `${item?.foodImage[0]?.[0]?.small?.imageUrl}`
                                        }
                                      />
                                      <div className="img-delete">
                                        <AiFillDelete
                                          size={20}
                                          color="red"
                                          onClick={() =>
                                            handleImageDeleteFood(index)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {selectedImage.map((item, index) => (
                            <div className="col">
                              <div className="image-box d-flex justify-content-center align-items-center">
                                {selectedImage[index] &&
                                  selectedImage.length > 0 && (
                                    <div className="img">
                                      <img
                                        alt=""
                                        // className="img-fluid"
                                        src={URL.createObjectURL(
                                          selectedImage[index]
                                        )}
                                      />
                                      <div className="img-delete">
                                        <AiFillDelete
                                          size={20}
                                          color="red"
                                          onClick={() =>
                                            handleImageDeleteFood(index)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {/* </div> */}
                    </div>
                  </div>

                  {/* {selectedImage && (
            <div>
              {selectedImage?.map((item, index) => {
                console.log(item);
                return (
                  <div>
                    <img
                      alt='not found'
                      width={"80px"}
                      src={URL.createObjectURL(item)}
                    />
                    <AiFillDelete
                      onClick={() => handleImageDeleteFood(index)}
                    />
                  </div>
                );
              })}
              <br />
            </div>
          )} */}

                  <br />
                  <br />

                  <div id="form-file-upload ">
                    <input
                      type="file"
                      id="input-file-upload"
                      multiple
                      onChange={handleFileChange}
                      // onChange={(event) => {
                      //   console.log(event.target.files[0]);
                      //   setSelectedImage(Array.from(event.target.files));
                      // }}
                    />
                    <label id="label-file-upload" htmlFor="input-file-upload">
                      <div>
                        <p>Drag and drop your file here or</p>
                        <span className="upload-button">Upload a file</span>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3  col-4">
                    <label>Category </label>
                    <select
                      defaultValue={"option"}
                      name="category"
                      // onClick={getCat}
                      onChange={(event) => setcat(event.target.value)}
                      // value={currentFruit}
                      className="form-control"
                    >
                      <option value="option" disabled>
                        Select Category
                      </option>

                      {catID?.map((item, index) => (
                        <option key={index} value={item?._id}>
                          {item?.categoryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3  col-4">
                    <label>SubCategory </label>
                    <select
                      defaultValue={"option"}
                      name="subcategory"
                      className="form-control"
                    >
                      <option value="option" disabled>
                        Select Sub Category
                      </option>

                      {subcat?.map((item, index) => (
                        <option key={index} value={item?._id}>
                          {item?.categoryName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-3 col-4">
                    <label> Select Portion </label>
                    <select
                      name="portion"
                      defaultValue={"option"}
                      // onClick={getPortion}
                      // onChange={(event) => FPortion(event.target.value)}
                      // value={changePortion}
                      className="form-control"
                    >
                      <option value="option" disabled>
                        Choose Status
                      </option>

                      {portionId.map((item, index) => (
                        <option key={index} value={item?._id}>
                          {item?.sizeUnitName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label>Food Description</label>
                  <textarea
                    type="text"
                    className="form-control input-default "
                    placeholder="Food Description"
                    label="Description:"
                    name="description"
                    style={{ height: 100 }}
                    defaultValue={item?.description}
                    // value={data?.price}
                    // onChange={handleChange}
                  />
                </div>

                {/* <div className='form-group mb-3'>
          <label>Food Image</label>
          <input
            type='text'
            className='form-control input-default '
            placeholder='Food Image'
            label='price:'
            name='image'
            value={data?.image}
            onChange={handleChange}
          />
        </div> */}

                <div className="row">
                  <div className="form-group mb-3 col-4">
                    <label>Food Discount Price</label>
                    <input
                      type="number"
                      className="form-control input-default "
                      placeholder="Food Discount Price"
                      label="discountprice:"
                      name="discountprice"
                      defaultValue={item?.foodDiscountPrice}
                      // value={data?.discountprice}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-4">
                    <label>Food Discount Percentage</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder="Food Discount Percentage"
                      label="foodDiscountPercentage:"
                      name="foodDiscountPercentage"
                      defaultValue={item?.foodDiscountPercentage}
                      // value={data?.foodDiscountPercentage}
                      // onChange={handleChange}
                    />
                  </div>

                  <div className="form-group mb-3 col-4">
                    <label>Food Minimum Order Quantity</label>
                    <input
                      type="number"
                      className="form-control input-default "
                      placeholder="Food Minimum Order Quantity"
                      label="minimumquantity:"
                      name="minimumquantity"
                      defaultValue={item?.foodMinimumOrderQty}
                      // value={data?.minimumquantity}
                      // onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  {/* <div className='form-group mb-3 col-6'>
            <label>Food Discount Start Date</label>
            <input
              type='date'
              className='form-control input-default '
              placeholder='Food Discount Percentage'
              label='startdate:'
              name='startdate'
            />
          </div> */}

                  {/* <div className='  form-group mb-3 col-6'>
            <label>Food Discount End Date</label>
            <input
              type='date'
              className='form-control input-default '
              placeholder='Food Discount Percentage'
              label='enddate:'
              name='enddate'
            />
          </div> */}
                </div>

                <div className="row">
                  <div className=" form-group mb-3 col-4">
                    <label>Food Discount Start Date</label>
                    <input
                      type="date"
                      className="form-control input-default "
                      placeholder="Food Discount Percentage"
                      label="startdate:"
                      name="startdate"
                      defaultValue={item?.foodDiscountStartDate}
                    />
                  </div>

                  <div className="  form-group mb-3 col-4">
                    <label>Food Discount End Date</label>
                    <input
                      type="date"
                      className="form-control input-default "
                      placeholder="Food Discount Percentage"
                      label="enddate:"
                      defaultValue={item?.foodDiscountEndDate}
                      name="enddate"
                    />
                  </div>
                  <div className=" form-group mb-3 col-4">
                    <label>Food type </label>
                    <select
                      name="foodType"
                      className="form-control input-default "
                      defaultValue="instant"
                      // onChange={handleInCa}
                    >
                      <option value="instant">INSTANT</option>
                      <option value="catering"> CATERING</option>
                    </select>
                  </div>
                </div>
                {/* <div className='d-flex justify-content-center'>
                {insCatData === "catering" ? (
                  <DayPicker
                    className='d-flex justify-content-center'
                    mode='range'
                    selected={selectedDate}
                    onSelect={setSelectedDate}
                    // footer={footer}
                  />
                ) : (
                  ""
                )}
              </div> */}

                <div className="row">
                  <div className="form-group mb-3 col-3">
                    <label>Meta Title</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder="Meta Title"
                      label="metaTitle:"
                      name="metaTitle"
                      // defaultValue={item?.seo.metaTitle}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-3">
                    <label>Meta Description</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder="Meta Description"
                      label="metaDescription"
                      name="metaDescription"
                      // defaultValue={item?.seo.metaDescription}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-3">
                    <label>Meta Keywords</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder="Meta Keywords"
                      label="metaKeywords"
                      name="metaKeywords"
                      // defaultValue={item?.seo.metaDescription?.metaKeywords[0]}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="col-3">
                    <label>Meta Image</label>
                    <input
                      type="file"
                      className="form-control input-default "
                      placeholder="Meta Image"
                      label="metaImage"
                      name="metaImage"
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                </div>

                <div>
                  <h3>Additional Tag</h3>
                  <div className="d-flex " style={{ display: "inline-block" }}>
                    {taq.map((item, index) => (
                      <span key={index}>
                        {item?.status === true ? (
                          <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info">
                            <input
                              onChange={(e) => {
                                handleCheck(e);
                              }}
                              type="checkbox"
                              name={item?.tagName}
                              value={item?.tagName}
                              className="form-check-input"
                              id={`customCheckBox${index}`}
                            />

                            <label
                              className="form-check-label"
                              htmlFor={`customCheckBox${index}`}
                            >
                              {item?.tagName}
                            </label>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  </div>
                  <Button style={{ marginLeft: 25 }} type="submit">
                    {" "}
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFood;
