import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Logo from "./../../../images/ownfood-logo.png";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import {
//     useAllCountryQuery,
//     useGetCityByRegionQuery,
//     useGetRegionByCountryQuery,
// } from "../../../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
import { Base_Url } from "../../../misc";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import noImage from "../../../images/no-img-avatar.png";
import { AiFillEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";

const kitchenTab = labels.userDashboard.editProfile.kitchenTab;
const amazingPhotosTab = labels.userDashboard.editProfile.amazingPhotosTab;
const experimentalPhotosTab =
  labels.userDashboard.editProfile.experimentalPhotosTab;
const otherFoodSkillsTab = labels.userDashboard.editProfile.otherFoodSkillsTab;
const myRestrictionTab = labels.userDashboard.editProfile.myRestrictionTab;
const bankTab = labels.userDashboard.editProfile.bankTab;
const mobileBankingTab = labels.userDashboard.editProfile.mobileBankingTab;
const identityTab = labels.userDashboard.editProfile.identityTab;
const kitchenPhotosVideosTab =
  labels.userDashboard.editProfile.kitchenPhotosVideosTab;
const basicInfoTab = labels.userDashboard.editProfile.basicInfoTab;
const Seller_View = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const basicInfoTabTitle = getTranslation(
    "tabTitle",
    currentLanguage,
    basicInfoTab
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCq3_f1JedG3GCgWnXgy3yuji4tjMZvitk",
  });
  const location = useLocation();
  const params = useParams();
  //const item = location.state;
  const [item, setItem] = useState([]);
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);
  const [city, setCity] = useState([]);
  const [sellerData, setSellerData] = useState();

  const [update, setUpdate] = useState("");
  const [show, setShow] = useState(false);
  const handleUpdateDataChange = (event) => {
    setUpdate(event.target.value);
  };

  //console.log(item?.userData[0]?._id);
  const submitUserUpdate = async (name, value) => {
    const data = {};
    data[name] = value;
    let id = item?.userData[0]?._id;
    console.log(id);
    setShow(false);
    try {
      await axios
        .post(`${Base_Url}/update-users/${id}`, data)
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };
  const submitSellerUpdate = async (name, value) => {
    const data = {};
    data[name] = value;
    setShow(false);
    try {
      await axios
        .post(`${Base_Url}/update-become-seller/${params.id}`, data)
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };

  const submitBankDetailsUpdate = async (name, value) => {
    const data = {};
    data[name] = value;
    setShow(false);
    try {
      await axios
        .post(`${Base_Url}/update-become-seller/${params.id}`, data)
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };
  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const sellerResponse = await fetch(
          `${Base_Url}/get-single-become-seller/${params.id}`
        );
        const sellerData = await sellerResponse.json();
        setItem(sellerData.data[0]);
        console.log(sellerData);
      } catch (error) {}
    };
    const fetchCountryData = async () => {
      try {
        const countryResponse = await fetch(`${Base_Url}/get-country`);
        const countryData = await countryResponse.json();
        console.log(countryData);
        setCountry(countryData.data);
      } catch (error) {
        // Handle error
      }
    };

    const fetchRegionData = async () => {
      try {
        const regionResponse = await fetch(`${Base_Url}/get-region-state`);
        const regionData = await regionResponse.json();
        console.log(regionData);
        setRegion(regionData.data);
      } catch (error) {
        // Handle error
      }
    };

    const fetchCityData = async () => {
      try {
        const cityResponse = await fetch(`${Base_Url}/get-city`);
        const cityData = await cityResponse.json();
        //  console.log(cityData);
        setCity(cityData.data);
      } catch (error) {
        // Handle error
      }
    };

    // Call the API functions
    fetchCountryData();
    fetchRegionData();
    fetchCityData();
    fetchSellerData();
  }, []);
  //console.log("country", country);
  const updateSellerData = async (name, value) => {
    try {
      await axios
        .post(`${Base_Url}/update-become-seller/${params.id}`, { name, value })
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };
  console.log(item);

  return (
    <>
      <div className="">
        <div className="card-body border p-4 rounded ">
          <div className="border p-4 rounded">
            <h2 className="">Seller Profile Details</h2>
          </div>
          {/* <hr style={{ height: "2px" }}></hr> */}
          <form>
            <div className="setting-right">
              <div className="">
                {/* <div className="card-header border-0 pb-0">
                  <h4>
                    {getTranslation(
                      "tabDetailsTitle",
                      currentLanguage,
                      kitchenTab
                    )}
                  </h4>
                </div> */}

                <div className="card-body border-0">
                  {/* basic user information section start */}

                  {item?.userData && (
                    <div className="">
                      <div className="card-body rounded border mb-4">
                        <h3 className="mb-4">
                          {basicInfoTabTitle}{" "}
                          <AiFillEdit size={18} color="orange" />
                        </h3>

                        <div className="setting-img d-flex align-items-center mb-4">
                          <div className="avatar-upload d-flex align-items-center">
                            <div className=" change position-relative ">
                              <div className="avatar-preview d-flex ml-2">
                                {console.log(
                                  item?.userData[0]?.userProfilePhoto,
                                  "debug-seller-view"
                                )}
                                {item?.userData[0]?.userProfilePhoto &&
                                item?.userData[0]?.userProfilePhoto.length >
                                  0 ? (
                                  <PhotoProvider>
                                    <div className="m-3 rounded">
                                      <PhotoView
                                        src={
                                          item?.userData[0]?.userProfilePhoto[0]
                                            ?.large?.imageUrl || Logo
                                        }
                                      >
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={
                                            item?.userData[0]
                                              ?.userProfilePhoto[0]?.small
                                              ?.imageUrl || Logo
                                          }
                                          alt=""
                                        />
                                      </PhotoView>
                                    </div>
                                  </PhotoProvider>
                                ) : (
                                  <img
                                    id="saveImageFile"
                                    src={noImage} // Replace this with the actual path to your noImage file
                                    alt="No Image"
                                  />
                                )}{" "}
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                <b>
                                  {getTranslation(
                                    "userName",
                                    currentLanguage,
                                    basicInfoTab
                                  )}{" "}
                                  <AiFillEdit
                                    size={18}
                                    color="orange"
                                    onClick={() => {
                                      setShow(true);
                                      document.getElementById(
                                        "username"
                                      ).disabled = false;
                                    }}
                                  />{" "}
                                  {show && (
                                    <AiOutlineCheck
                                      id="kc-yes"
                                      size={18}
                                      color="green"
                                      onClick={() => {
                                        submitUserUpdate(
                                          "userFullName",
                                          update
                                        );
                                      }}
                                    />
                                  )}
                                  {show && (
                                    <AiOutlineClose
                                      id="kc-no"
                                      size={18}
                                      color="red"
                                      onClick={() => {
                                        setShow(false);
                                        document.getElementById(
                                          "username"
                                        ).disabled = true;
                                      }}
                                    />
                                  )}
                                </b>
                              </label>
                              <input
                                id="username"
                                disabled
                                type="text"
                                className="form-control input-default "
                                placeholder={getTranslation(
                                  "userName",
                                  currentLanguage,
                                  basicInfoTab
                                )}
                                onClick={() => {
                                  setShow(true);
                                  document.getElementById(
                                    "username"
                                  ).disabled = false;
                                }}
                                onChange={handleUpdateDataChange}
                                defaultValue={item?.userData[0]?.userFullName}
                                name="userFullName"
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                <b>
                                  {getTranslation(
                                    "userEmailAddress",
                                    currentLanguage,
                                    basicInfoTab
                                  )}{" "}
                                  <AiFillEdit
                                    size={18}
                                    color="orange"
                                    onClick={() => {
                                      setShow(true);
                                      document.getElementById(
                                        "email"
                                      ).disabled = false;
                                    }}
                                  />{" "}
                                  {show && (
                                    <AiOutlineCheck
                                      id="kc-yes"
                                      size={18}
                                      color="green"
                                      onClick={() => {
                                        submitUserUpdate("email", update);
                                      }}
                                    />
                                  )}
                                  {show && (
                                    <AiOutlineClose
                                      id="kc-no"
                                      size={18}
                                      color="red"
                                      onClick={() => {
                                        setShow(false);
                                        document.getElementById(
                                          "email"
                                        ).disabled = true;
                                      }}
                                    />
                                  )}
                                </b>
                              </label>
                              <input
                                id="email"
                                disabled
                                type="email"
                                className="form-control input-default "
                                placeholder={getTranslation(
                                  "userEmailAddress",
                                  currentLanguage,
                                  basicInfoTab
                                )}
                                onChange={handleUpdateDataChange}
                                name="email"
                                defaultValue={item?.userData[0]?.email}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="setting-input">
                              <div className="setting-input">
                                <label
                                  htmlFor="exampleInputtext"
                                  className="form-label"
                                >
                                  <b>
                                    {getTranslation(
                                      "userMobileNo",
                                      currentLanguage,
                                      basicInfoTab
                                    )}{" "}
                                    <AiFillEdit
                                      size={18}
                                      color="orange"
                                      onClick={() => {
                                        setShow(true);
                                        document.getElementById(
                                          "mobile"
                                        ).disabled = false;
                                      }}
                                    />{" "}
                                    {show && (
                                      <AiOutlineCheck
                                        id="kc-yes"
                                        size={18}
                                        color="green"
                                        onClick={() => {
                                          submitUserUpdate(
                                            "userMobileNo",
                                            update
                                          );
                                        }}
                                      />
                                    )}
                                    {show && (
                                      <AiOutlineClose
                                        id="kc-no"
                                        size={18}
                                        color="red"
                                        onClick={() => {
                                          setShow(false);
                                          document.getElementById(
                                            "mobile"
                                          ).disabled = true;
                                        }}
                                      />
                                    )}
                                  </b>
                                </label>
                                <input
                                  id="mobile"
                                  disabled
                                  type="text"
                                  className="form-control input-default "
                                  placeholder="Mobile Number"
                                  defaultValue={item?.userData[0]?.userMobileNo}
                                  onChange={handleUpdateDataChange}
                                />
                              </div>
                            </div>
                            {/* <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                <b>
                                  {getTranslation(
                                    "address",
                                    currentLanguage,
                                    basicInfoTab
                                  )}{" "}
                                  <AiFillEdit
                                    size={18}
                                    color="orange"
                                    onClick={() => {
                                      setShow(true);
                                      document.getElementById(
                                        "address"
                                      ).disabled = false;
                                    }}
                                  />{" "}
                                  {show && (
                                    <AiOutlineCheck
                                      id="kc-yes"
                                      size={18}
                                      color="green"
                                      onClick={() => {
                                        submitSellerUpdate("address", update);
                                      }}
                                    />
                                  )}
                                  {show && (
                                    <AiOutlineClose
                                      id="kc-no"
                                      size={18}
                                      color="red"
                                      onClick={() => {
                                        setShow(false);
                                        document.getElementById(
                                          "address"
                                        ).disabled = true;
                                      }}
                                    />
                                  )}
                                </b>
                              </label>
                              <input
                                id="address"
                                disabled
                                type="address"
                                className="form-control input-default "
                                placeholder={getTranslation(
                                  "address",
                                  currentLanguage,
                                  basicInfoTab
                                )}
                                onChange={handleUpdateDataChange}
                                name="address"
                                defaultValue={item?.address}
                              />
                            </div> */}
                          </div>
                        </div>
                        {/* <div className='row'>
                          <div className='col-12'>
                            <h5>
                              {getTranslation(
                                "userAddressHeading",
                                currentLanguage,
                                basicInfoTab
                              )}{" "}
                            </h5>
                          </div>
                          <div className='col-4'>
                            <label>
                              {getTranslation(
                                "country",
                                currentLanguage,
                                basicInfoTab
                              )}{" "}
                            </label>
                            <select
                              name='countryAddress'
                              className='form-control input-default'

                            >
                              <option>
                                {getTranslation(
                                  "country",
                                  currentLanguage,
                                  basicInfoTab
                                )}{" "}
                              </option>

                            </select>
                          </div>
                          <div className='col-4'>
                            <label>
                              {getTranslation(
                                "region",
                                currentLanguage,
                                basicInfoTab
                              )}{" "}
                            </label>
                            <select
                              className='form-control input-default'

                            >
                              <option>
                                {getTranslation(
                                  "region",
                                  currentLanguage,
                                  basicInfoTab
                                )}{" "}
                              </option>

                            </select>
                          </div>
                          <div className='col-4'>
                            <label>
                              {getTranslation(
                                "city",
                                currentLanguage,
                                basicInfoTab
                              )}{" "}
                            </label>
                            <select className='form-control input-default'>
                              <option value=''>
                                {getTranslation(
                                  "city",
                                  currentLanguage,
                                  basicInfoTab
                                )}{" "}
                              </option>

                            </select>
                          </div>
                        </div> */}

                        {/* Map &  Address Section Start */}
                        <div className="mt-3">
                          <div className="form-group mb-3 row ">
                            <div className="col-12">
                              {/* <label>
                                {getTranslation(
                                  "pointLocation",
                                  currentLanguage,
                                  basicInfoTab
                                )}{" "}
                              </label> */}
                              {/* <PlacesAutocomplete
                                searchOptions={searchOptions}
                                value={userAddress || ""}
                                onChange={setUserAddress}
                                onSelect={handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div style={{ position: "relative" }}>
                                    <input disabled
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className:
                                          "form-control input-default location-search-input",
                                      })}
                                    />
                                    <div
                                      className='autocomplete-dropdown-container'
                                      style={{
                                        position: "absolute",
                                        zIndex: 2,
                                        width: "100%",
                                        border: "1px solid #e7e7e7",
                                      }}
                                    >
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#1E466A",
                                              color: "white",
                                              cursor: "pointer",
                                              padding: "10px",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                              padding: "10px",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete> */}
                            </div>
                          </div>
                          {/* <GoogleMap
              mapContainerStyle={{ height: "200px", width: "100%" }}
              //onDragEnd={handleDrag}
              center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
              zoom={20}
              onLoad={handleMapLoad}
          >
              <Marker
                  position={coordinates}
                  draggable
                  onDragEnd={handleDrag}
              />
          </GoogleMap> */}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* basic user information section end */}
                  {item?.kitchenImages && (
                    <div className="card ">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            kitchenPhotosVideosTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body d-flex">
                        {console.log(
                          item?.kitchenImages.length,
                          "debug-seller-view"
                        )}
                        {item?.kitchenImages && (
                          // Object.keys(item?.kitchenImages[0]).length > 0 &&
                          <PhotoProvider>
                            {item?.kitchenImages.map((item, index) => (
                              <div className="m-3 rounded">
                                <PhotoView src={item?.large?.imageUrl || Logo}>
                                  <img
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                    }}
                                    src={item?.small?.imageUrl || Logo}
                                    alt=""
                                  />
                                </PhotoView>
                              </div>
                            ))}
                          </PhotoProvider>
                        )}
                      </div>
                    </div>
                  )}
                  {item?.kitchenImages?.length === 0 && "NONE"}

                  <div>
                    <div className="form-group mb-3">
                      <label>
                        <b>
                          {getTranslation(
                            "kitchenName",
                            currentLanguage,
                            kitchenTab
                          )}{" "}
                          <AiFillEdit
                            size={18}
                            color="orange"
                            onClick={() => {
                              setShow(true);
                              document.getElementById(
                                "kitchenName"
                              ).disabled = false;
                            }}
                          />{" "}
                          {show && (
                            <AiOutlineCheck
                              id="kc-yes"
                              size={18}
                              color="green"
                              onClick={() => {
                                submitSellerUpdate("kitchenName", update);
                              }}
                            />
                          )}
                          {show && (
                            <AiOutlineClose
                              id="kc-no"
                              size={18}
                              color="red"
                              onClick={() => {
                                setShow(false);
                                document.getElementById(
                                  "kitchenName"
                                ).disabled = true;
                              }}
                            />
                          )}
                        </b>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        id="kitchenName"
                        disabled
                        type="text"
                        className="form-control input-default "
                        placeholder={getTranslation(
                          "kitchenName",
                          currentLanguage,
                          kitchenTab
                        )}
                        name="kitchenName"
                        defaultValue={
                          item && item?.kitchenName && (item?.kitchenName || "")
                        }
                        onChange={handleUpdateDataChange}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h5>
                          {getTranslation(
                            "kitchenAddressHeading",
                            currentLanguage,
                            kitchenTab
                          )}{" "}
                        </h5>
                      </div>

                      {item?.countryID && (
                        <div className="col-4">
                          <label>
                            <b>
                              {getTranslation(
                                "country",
                                currentLanguage,
                                kitchenTab
                              )}{" "}
                            </b>
                          </label>
                          <select
                            name="countryAddress"
                            className="form-control input-default"
                          >
                            {country.length > 0 &&
                              country.map((i) => {
                                if (country && i?._id === item?.countryID) {
                                  console.log(i, "afdasdf");
                                  return (
                                    <option selected value={i?._id}>
                                      {i?.countryName}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                      )}

                      {item?.regionID && (
                        <div className="col-4">
                          <label>
                            <b>
                              {getTranslation(
                                "region",
                                currentLanguage,
                                kitchenTab
                              )}{" "}
                            </b>
                          </label>
                          <select className="form-control input-default">
                            {region.length > 0 &&
                              region.map((i) => {
                                if (region && i?._id === item?.regionID) {
                                  return (
                                    <option selected value={i._id}>
                                      {i.regionName}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                      )}

                      {item?.cityID && (
                        <div className="col-4 mb-3">
                          <label>
                            <b>
                              {getTranslation(
                                "city",
                                currentLanguage,
                                kitchenTab
                              )}{" "}
                            </b>
                          </label>
                          <select className="form-control input-default">
                            {city?.length > 0 &&
                              city?.map((i) => {
                                if (city && i?._id === item?.cityID) {
                                  return (
                                    <option selected value={i._id}>
                                      {i.cityName}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                      )}
                      {/* {item?.pointLocation && (
                        <GoogleMap
                          mapContainerStyle={{ height: "200px", width: "100%" }}
                          //onDragEnd={handleDrag}
                          center={item?.pointLocation?.coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                          zoom={20}
                          // onLoad={handleMapLoad}
                        >
                          <Marker
                            position={item?.pointLocation?.coordinates}
                            draggable
                          />
                        </GoogleMap>
                      )} */}
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="">
                          <label
                            htmlFor="exampleInputtext"
                            className="form-label"
                          >
                            <b>
                              {getTranslation(
                                "address",
                                currentLanguage,
                                basicInfoTab
                              )}{" "}
                              <AiFillEdit
                                size={18}
                                color="orange"
                                onClick={() => {
                                  setShow(true);
                                  document.getElementById(
                                    "address2"
                                  ).disabled = false;
                                }}
                              />{" "}
                              {show && (
                                <AiOutlineCheck
                                  id="kc-yes"
                                  size={18}
                                  color="green"
                                  onClick={() => {
                                    submitSellerUpdate("address", update);
                                  }}
                                />
                              )}
                              {show && (
                                <AiOutlineClose
                                  id="kc-no"
                                  size={18}
                                  color="red"
                                  onClick={() => {
                                    setShow(false);
                                    document.getElementById(
                                      "address2"
                                    ).disabled = true;
                                  }}
                                />
                              )}
                            </b>
                          </label>
                          <input
                            id="address2"
                            disabled
                            type="address"
                            className="form-control input-default "
                            placeholder={getTranslation(
                              "address",
                              currentLanguage,
                              basicInfoTab
                            )}
                            onChange={handleUpdateDataChange}
                            name="address"
                            defaultValue={item?.address}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <label>
                          <b>
                            {" "}
                            {getTranslation(
                              "policeStation",
                              currentLanguage,
                              kitchenTab
                            )}{" "}
                          </b>
                        </label>
                        <input
                          disabled
                          type="text"
                          class="form-control"
                          value={
                            item &&
                            item?.policeStation &&
                            (item?.policeStation || "")
                          }
                          placeholder={getTranslation(
                            "policeStation",
                            currentLanguage,
                            kitchenTab
                          )}
                        />
                      </div>
                    </div>

                    {/* Map &  Address Section Start */}
                    {/* <div className='mt-3'>
                                <div className='form-group mb-3 row '>
                                    <div className='col-12'>
                                        <label>{getTranslation('pointLocation', currentLanguage,kitchenTab)} </label>
                                        <PlacesAutocomplete
                                            searchOptions={searchOptions}
                                            value={userAddress || ""}
                                            onChange={setUserAddress}
                                            onSelect={handleSelect}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <div style={{ position: "relative" }}>
                                                    <input disabled
                                                        {...getInputProps({
                                                            placeholder: "Search Places ...",
                                                            className: "form-control input-default location-search-input",
                                                        })}
                                                    />
                                                    <div className='autocomplete-dropdown-container' style={{ position: "absolute", zIndex: 2, width: "100%", border: "1px solid #e7e7e7" }}>
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? {
                                                                    backgroundColor: "#1E466A",
                                                                    color: "white",
                                                                    cursor: "pointer",
                                                                    padding: "10px"
                                                                }
                                                                : {
                                                                    backgroundColor: "#ffffff",
                                                                    cursor: "pointer",
                                                                    padding: "10px",
                                                                };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                </div>
                                <GoogleMap
                                    mapContainerStyle={{ height: "200px", width: "100%" }}
                                    //onDragEnd={handleDrag}
                                    center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                                    zoom={20}
                                    onLoad={handleMapLoad}
                                >
                                    <Marker
                                        position={coordinates}
                                        draggable
                                        onDragEnd={handleDrag}
                                    />
                                </GoogleMap>
                            </div> */}

                    <div className="row mt-4">
                      <div className="form-group mb-3 col-6">
                        <label>
                          <b>
                            {" "}
                            {getTranslation(
                              "nationality",
                              currentLanguage,
                              kitchenTab
                            )}{" "}
                          </b>
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          // name='myCountry'
                          className="form-control input-default  "
                        >
                          {country?.length > 0 &&
                            country.map((i) => {
                              if (
                                country &&
                                i._id === item?.sellerNationality
                              ) {
                                return (
                                  <option selected value={i._id}>
                                    {i?.countryName}
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
                      <div className="form-group mb-3 col-6">
                        <label>
                          <b>
                            {" "}
                            {getTranslation(
                              "decentNationality",
                              currentLanguage,
                              kitchenTab
                            )}
                          </b>
                          <span className="text-danger">*</span>
                        </label>

                        <select
                          // name='decentCountry'
                          className="form-control input-default "
                        >
                          {country?.length > 0 &&
                            country.map((i) => {
                              if (
                                country &&
                                i._id === item?.sellerNationalityByBornID
                              ) {
                                console.log(i, "afdasdf");
                                return (
                                  <option selected value={i._id}>
                                    {i.countryName}
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* amazing dish Section start */}

                  {item?.dishesMedia && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {" "}
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            amazingPhotosTab
                          )}{" "}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row align-content-end">
                          <div className="col-12">
                            {item?.dishesMedia[0] &&
                              item?.dishesMedia[0]?.photo && (
                                <PhotoProvider>
                                  {item?.dishesMedia[0]?.photo?.map(
                                    (item, index) => (
                                      <div className="m-3 rounded">
                                        <PhotoView
                                          src={item?.large?.imageUrl || Logo}
                                        >
                                          <img
                                            style={{
                                              height: "100px",
                                              width: "100px",
                                            }}
                                            src={item?.small?.imageUrl || Logo}
                                            alt=""
                                          />
                                        </PhotoView>
                                      </div>
                                    )
                                  )}
                                </PhotoProvider>
                              )}

                            {item?.dishesMedia?.length === 0 && "NONE"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* amazing dish Section end */}

                  {/* experimental food  Section end */}

                  {item?.myExperimentalFoodMedia && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {" "}
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            experimentalPhotosTab
                          )}{" "}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row align-content-end">
                          <div className="col-12">
                            {item?.myExperimentalFoodMedia && (
                              <PhotoProvider>
                                {item?.myExperimentalFoodMedia[0]?.photo?.map(
                                  (item, index) => (
                                    <div className="m-3 rounded">
                                      <PhotoView
                                        src={item?.large?.imageUrl || Logo}
                                      >
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={item?.small?.imageUrl || Logo}
                                          alt=""
                                        />
                                      </PhotoView>
                                    </div>
                                  )
                                )}
                              </PhotoProvider>
                            )}
                            {item?.myExperimentalFoodMedia?.length === 0 &&
                              "NONE"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* experimental food Section end */}

                  {/* others food skill Section start */}
                  {item?.otherFoodSkillsMedia && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            otherFoodSkillsTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row align-content-end">
                          <div className="col-12">
                            {item?.otherFoodSkillsMedia && (
                              <PhotoProvider>
                                {item?.otherFoodSkillsMedia[0]?.photo?.map(
                                  (item, index) => (
                                    <div className="m-3 rounded">
                                      <PhotoView
                                        src={item?.large?.imageUrl || Logo}
                                      >
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={item?.small?.imageUrl || Logo}
                                          alt=""
                                        />
                                      </PhotoView>
                                    </div>
                                  )
                                )}
                              </PhotoProvider>
                            )}
                            {item?.otherFoodSkillsMedia?.length === 0 && "NONE"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* others food skill Section end */}

                  {/* my MyRestrictions Section start */}

                  {item?.myRestrictions && item?.myRestrictions.length > 0 && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {" "}
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            myRestrictionTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <ul className="checkbox-group">
                              {item?.myRestrictions?.map((item, index) => (
                                <li>
                                  <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info d-flex">
                                    <input
                                      disabled
                                      type="checkbox"
                                      value={item}
                                      className="form-check-input"
                                      id={`customCheckBox_${index}`}
                                      checked
                                      // checked={Tag && Tag.includes(item.tagName)}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor={`customCheckBox_${index}`}
                                    >
                                      {item}
                                    </label>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* my MyRestrictions Section end */}

                  {/* bank details section start */}
                  {item?.bankDetails && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            bankTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              <b>
                                {" "}
                                {getTranslation(
                                  "accountName",
                                  currentLanguage,
                                  bankTab
                                )}{" "}
                                <AiFillEdit
                                  size={18}
                                  color="orange"
                                  onClick={() => {
                                    setShow(true);
                                    document.getElementById(
                                      "accountName"
                                    ).disabled = false;
                                  }}
                                />{" "}
                                {show && (
                                  <AiOutlineCheck
                                    id="kc-yes"
                                    size={18}
                                    color="green"
                                    onClick={() => {
                                      submitSellerUpdate("bankAccName", update);
                                    }}
                                  />
                                )}
                                {show && (
                                  <AiOutlineClose
                                    id="kc-no"
                                    size={18}
                                    color="red"
                                    onClick={() => {
                                      setShow(false);
                                      document.getElementById(
                                        "accountName"
                                      ).disabled = true;
                                    }}
                                  />
                                )}
                              </b>
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              id="accountName"
                              type="text"
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "accountName",
                                currentLanguage,
                                bankTab
                              )}
                              onChange={handleUpdateDataChange}
                              name="bankAccName"
                              defaultValue={item?.bankDetails[0]?.bankAccName}
                              disabled
                            />
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "accountNo",
                                currentLanguage,
                                bankTab
                              )}{" "}
                              <AiFillEdit
                                size={18}
                                color="orange"
                                onClick={() => {
                                  setShow(true);
                                  document.getElementById(
                                    "accountNo"
                                  ).disabled = false;
                                }}
                              />{" "}
                              {show && (
                                <AiOutlineCheck
                                  id="kc-yes"
                                  size={18}
                                  color="green"
                                  onClick={() => {
                                    submitSellerUpdate("bankAccNo", update);
                                  }}
                                />
                              )}
                              {show && (
                                <AiOutlineClose
                                  id="kc-no"
                                  size={18}
                                  color="red"
                                  onClick={() => {
                                    setShow(false);
                                    document.getElementById(
                                      "accountNo"
                                    ).disabled = true;
                                  }}
                                />
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              id="accountNo"
                              type="text"
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "accountNo",
                                currentLanguage,
                                bankTab
                              )}
                              onChange={handleUpdateDataChange}
                              name="bankAccNo"
                              defaultValue={item?.bankDetails[0]?.bankAccNo}
                              disabled
                            />
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "bankName",
                                currentLanguage,
                                bankTab
                              )}{" "}
                              <AiFillEdit
                                size={18}
                                color="orange"
                                onClick={() => {
                                  setShow(true);
                                  document.getElementById(
                                    "bankName"
                                  ).disabled = false;
                                }}
                              />{" "}
                              {show && (
                                <AiOutlineCheck
                                  id="kc-yes"
                                  size={18}
                                  color="green"
                                  onClick={() => {
                                    submitSellerUpdate("bankName", update);
                                  }}
                                />
                              )}
                              {show && (
                                <AiOutlineClose
                                  id="kc-no"
                                  size={18}
                                  color="red"
                                  onClick={() => {
                                    setShow(false);
                                  }}
                                />
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <select className="form-control">
                              <option>{item?.bankDetails[0]?.bankName}</option>
                            </select>
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "branchName",
                                currentLanguage,
                                bankTab
                              )}{" "}
                              <AiFillEdit
                                size={18}
                                color="orange"
                                onClick={() => {
                                  setShow(true);
                                  document.getElementById(
                                    "branchName"
                                  ).disabled = false;
                                }}
                              />{" "}
                              {show && (
                                <AiOutlineCheck
                                  id="kc-yes"
                                  size={18}
                                  color="green"
                                  onClick={() => {
                                    submitSellerUpdate(
                                      "bankBranchName",
                                      update
                                    );
                                  }}
                                />
                              )}
                              {show && (
                                <AiOutlineClose
                                  id="kc-no"
                                  size={18}
                                  color="red"
                                  onClick={() => {
                                    setShow(false);
                                    document.getElementById(
                                      "branchName"
                                    ).disabled = true;
                                  }}
                                />
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              id="branchName"
                              disabled
                              type="text"
                              onChange={handleUpdateDataChange}
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "branchName",
                                currentLanguage,
                                bankTab
                              )}
                              name="bankBranchName"
                              defaultValue={
                                item?.bankDetails[0]?.bankBranchName
                              }
                            />
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "routingNo",
                                currentLanguage,
                                bankTab
                              )}{" "}
                              <AiFillEdit
                                size={18}
                                color="orange"
                                onClick={() => {
                                  setShow(true);
                                  document.getElementById(
                                    "routingNo"
                                  ).disabled = false;
                                }}
                              />{" "}
                              {show && (
                                <AiOutlineCheck
                                  id="kc-yes"
                                  size={18}
                                  color="green"
                                  onClick={() => {
                                    submitSellerUpdate("branchName", update);
                                  }}
                                />
                              )}
                              {show && (
                                <AiOutlineClose
                                  id="kc-no"
                                  size={18}
                                  color="red"
                                  onClick={() => {
                                    setShow(false);
                                    document.getElementById(
                                      "routingNo"
                                    ).disabled = true;
                                  }}
                                />
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              id="routingNo"
                              disabled
                              type="text"
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "routingNo",
                                currentLanguage,
                                bankTab
                              )}
                              onChange={handleUpdateDataChange}
                              name="bankRoutingNo"
                              defaultValue={item?.bankDetails[0]?.bankRoutingNo}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* bank details section end */}

                  {/* mobile bank section start */}
                  {item?.mobileBanking && (
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header border-0 pb-0">
                          <h3>
                            {getTranslation(
                              "tabDetailsTitle",
                              currentLanguage,
                              mobileBankingTab
                            )}
                          </h3>
                        </div>
                        <div className="card-body">
                          {item?.mobileBanking &&
                            item?.mobileBanking.length > 0 && (
                              <>
                                <div className="row">
                                  <div className="col-5">
                                    <label>
                                      <b>
                                        {" "}
                                        {getTranslation(
                                          "selectProvider",
                                          currentLanguage,
                                          mobileBankingTab
                                        )}{" "}
                                        <AiFillEdit
                                          size={18}
                                          color="orange"
                                          onClick={() => {
                                            setShow(true);
                                            document.getElementById(
                                              "selectProvider"
                                            ).disabled = false;
                                          }}
                                        />{" "}
                                        {show && (
                                          <AiOutlineCheck
                                            id="kc-yes"
                                            size={18}
                                            color="green"
                                            onClick={() => {
                                              submitSellerUpdate(
                                                "selectProvider",
                                                update
                                              );
                                            }}
                                          />
                                        )}
                                        {show && (
                                          <AiOutlineClose
                                            id="kc-no"
                                            size={18}
                                            color="red"
                                            onClick={() => {
                                              setShow(false);
                                              document.getElementById(
                                                "selectProvider"
                                              ).disabled = true;
                                            }}
                                          />
                                        )}
                                      </b>
                                    </label>
                                  </div>
                                  <div className="col-5">
                                    <label>
                                      <b>
                                        {" "}
                                        {getTranslation(
                                          "selectMobileNo",
                                          currentLanguage,
                                          mobileBankingTab
                                        )}{" "}
                                        <AiFillEdit
                                          size={18}
                                          color="orange"
                                          onClick={() => {
                                            setShow(true);
                                            document.getElementById(
                                              "selectMobileNo"
                                            ).disabled = false;
                                          }}
                                        />{" "}
                                        {show && (
                                          <AiOutlineCheck
                                            id="kc-yes"
                                            size={18}
                                            color="green"
                                            onClick={() => {
                                              submitSellerUpdate(
                                                "selectMobileNo",
                                                update
                                              );
                                            }}
                                          />
                                        )}
                                        {show && (
                                          <AiOutlineClose
                                            id="kc-no"
                                            size={18}
                                            color="red"
                                            onClick={() => {
                                              setShow(false);
                                              document.getElementById(
                                                "selectMobileNo"
                                              ).disabled = true;
                                            }}
                                          />
                                        )}
                                      </b>
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  {item?.mobileBanking.map((item) => (
                                    <div className="row mt-2">
                                      <div className="form-group col-5">
                                        <input
                                          id="selectProvider"
                                          disabled
                                          type="text"
                                          className="form-control"
                                          placeholder={getTranslation(
                                            "selectMobileNo",
                                            currentLanguage,
                                            mobileBankingTab
                                          )}
                                          onChange={handleUpdateDataChange}
                                          defaultValue={
                                            item?.operatorName || ""
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-5">
                                        <input
                                          id="selectMobileNo"
                                          disabled
                                          type="text"
                                          className="form-control"
                                          placeholder={getTranslation(
                                            "selectMobileNo",
                                            currentLanguage,
                                            mobileBankingTab
                                          )}
                                          onChange={handleUpdateDataChange}
                                          defaultValue={
                                            item?.mobileNumber || ""
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* mobile bank section end */}

                  {/* identity section start */}

                  {item?.userData && item?.userData[0]?.Identity && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h4>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            identityTab
                          )}
                        </h4>
                      </div>
                      <div className="card-body">
                        <div>
                          {/* <hr className="my-5" /> */}
                          {item?.userData[0]?.Identity &&
                            item?.userData[0]?.Identity[0]?.type && (
                              <div className="row align-items-center">
                                <div className="col-12">
                                  <div className="form-group mb-3">
                                    <label>
                                      <b>
                                        {" "}
                                        {getTranslation(
                                          "selectIdType",
                                          currentLanguage,
                                          identityTab
                                        )}{" "}
                                      </b>
                                    </label>
                                    <select
                                      name="countryAddress"
                                      className="form-control input-default"
                                    >
                                      <option selected>
                                        {item?.userData[0]?.Identity[0]?.type}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                          {item?.userData[0]?.Identity &&
                            item?.userData[0]?.Identity[0]?.Number && (
                              <div className="form-group mb-3">
                                <label>
                                  <b>
                                    {" "}
                                    {getTranslation(
                                      "idNumber",
                                      currentLanguage,
                                      identityTab
                                    )}{" "}
                                    <AiFillEdit
                                      size={18}
                                      color="orange"
                                      onClick={() => {
                                        setShow(true);
                                        document.getElementById(
                                          "idNumber"
                                        ).disabled = false;
                                      }}
                                    />{" "}
                                    {show && (
                                      <AiOutlineCheck
                                        id="kc-yes"
                                        size={18}
                                        color="green"
                                        onClick={() => {
                                          submitSellerUpdate(
                                            "idNumber",
                                            update
                                          );
                                        }}
                                      />
                                    )}
                                    {show && (
                                      <AiOutlineClose
                                        id="kc-no"
                                        size={18}
                                        color="red"
                                        onClick={() => {
                                          setShow(false);
                                          document.getElementById(
                                            "idNumber"
                                          ).disabled = true;
                                        }}
                                      />
                                    )}
                                  </b>
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="idNumber"
                                  disabled
                                  type="text"
                                  className="form-control input-default "
                                  onChange={handleUpdateDataChange}
                                  placeholder={getTranslation(
                                    "idNumberPlaceholder",
                                    currentLanguage,
                                    identityTab
                                  )}
                                  defaultValue={
                                    item?.userData[0]?.Identity[0]?.Number
                                  }
                                />
                              </div>
                            )}

                          {item?.userData[0]?.Identity &&
                            item?.userData[0]?.Identity[0]?.Img[0] && (
                              <PhotoProvider>
                                {item?.userData[0]?.Identity[0]?.Img.map(
                                  (item, index) => (
                                    <div className="m-3 rounded">
                                      <PhotoView
                                        src={item?.large?.imageUrl || Logo}
                                      >
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={item?.small?.imageUrl || Logo}
                                          alt=""
                                        />
                                      </PhotoView>
                                    </div>
                                  )
                                )}
                              </PhotoProvider>
                            )}
                        </div>

                        {/* <div className="col-12 mt-3">
                          <div className="setting-input">
                            <label
                              htmlFor="exampleInputtext"
                              className="form-label"
                            >
                              {getTranslation(
                                "idDateOfBirth",
                                currentLanguage,
                                identityTab
                              )}
                            </label>
                            <input disabled
                              type="text"
                              className="form-control input-default "
                              placeholder="Date of Birth"
                              {...register("userDateOfBirth", {
                                required: true,
                                onChange: handleInputChange,
                              })}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}

                  {/* identity section end */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Seller_View;
