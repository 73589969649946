import React from "react";
import { Row, Col, Dropdown, Card } from "react-bootstrap";
const StyleEight = () => {


  
  return (
    <div>
      <h4>Design Section Eight (One Column by Four)</h4>
      <Row>
        <Col>
          <Card>
            <Card.Header className="d-block">
              <Card.Title>Section Layout Design (Three Column)</Card.Title>
              <Card.Text className="m-0 subtitle">
                Fill Your Section Data
              </Card.Text>
            </Card.Header>

            <h3 style={{ marginLeft: 30 }}>First Column Design</h3>
            <Row>
              <Col>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Section Title
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            placeholder="Enter a section Title.."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Section Category
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            placeholder="Enter a section Category.."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Section Orderby
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            placeholder="Enter a section Orderby.."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Section Displaylimit
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            placeholder="Enter a section Display Limit.."
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StyleEight;
