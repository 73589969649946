import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Pagination } from "react-bootstrap";
import MyPagination from "../Pagination/MyPagination";

const CategoryList = () => {
  const [category, setCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [dropValue, setDropValue] = useState("Order by");
  const [filter, setfilter] = useState("filter by");
  const [apiPagination, setApiPagination] = useState("Sort by");

  // Filtering Logic
  useEffect(() => {
    let filtered = category;

    if (searchTerm.trim() !== "") {
      filtered = filtered.filter((item) =>
        item?.categoryName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, category]);
  const [PAGE_SIZE, setPAGE_SIZE] = useState(15);
  // Pagination Logic
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentItems = (
    filteredData.length > 0 ? filteredData : category
  ).slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData.length : category.length) /
      PAGE_SIZE
  );

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  useEffect(() => {
    chackboxFun(); // Call this function if needed within useEffect

    axios
      .get(`${Base_Url}/get-category`)
      .then((res) => {
        setCategory(res.data.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); // Include any dependencies here if needed

  const handleDelete = (data) => {
    axios
      .delete(`${Base_Url}/delete-category/${data}`)
      .then((res) => {
        console.log(res);
        setCategory(category.filter((item) => item._id !== data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <div
        className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="input-group search-area2 style-1 w-[75%] border d-flex"
          style={{ marginRight: "15px" }}
        >
          <span className="input-group-text p-0">
            <Link to={"#"}>
              <svg
                className="me-1"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                  fill="#FC8019"
                />
              </svg>
            </Link>
          </span>

          <input
            type="text"
            className="form-control p-2 "
            placeholder="Filter by Category Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="input-group ml-2 pl-2 style-1 w-50">
          {/* Search filter */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      </div>
      <div className="card h-auto">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table
              className="table table-list i-table style-1 mb-4 border-0"
              id="guestTable-all3"
            >
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Image</th>

                  <th>categoryName</th>
                  <th>Status</th>
                  {/* <th>createdBy</th> */}
                  <th className="bg-none">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={item._id}>
                    <td>
                      <div className="media-bx d-flex py-3 align-items-center">
                        {index + 1 + PAGE_SIZE * (currentPage - 1)}
                      </div>
                    </td>
                    <td>
                      <div className="media-bx d-flex py-3 align-items-center">
                        <img
                          className="me-3 rounded-circle"
                          src={item.categoryImage}
                          alt="images"
                        />
                      </div>
                    </td>
                    <td>
                      <div>{item.categoryName}</div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        {item?.status ? (
                          <i className="fa fa-circle text-success me-1"></i>
                        ) : (
                          <i className="fa fa-circle text-warning me-1"></i>
                        )}
                      </div>
                    </td>

                    {/* <td>
                          <div className="d-flex align-items-center">
                            {item?.createdBy ? item.createdBy : "N/A"}
                          </div>
                        </td> */}

                    <td>
                      <div className="d-flex gap-2">
                        <Link
                          to={`/edit-food-category/${item?._id}`}
                          state={item}
                        >
                          <div
                            style={{
                              backgroundColor: "#d97706",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          >
                            <AiFillEdit size={18} color="white" />
                          </div>
                        </Link>
                        <div
                          onClick={() => {
                            del(item._id);
                          }}
                          style={{
                            backgroundColor: "#db2777",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          <AiFillDelete size={18} color="white" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
