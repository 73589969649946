import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";
import axios from "axios";
import { Base_Url } from "../../../misc";

const CreateDesignation = () => {
  const [designation, setDesgination] = useState();

  const [data, setData] = useState({
    name: "",
  });

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState("option");
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.name !== "" ? setdisable(false) : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  const submit = () => {
    let subdata = {
      name: data.name,
      status: currentFruit,
    };
    console.log(subdata);
    axios
      .post(`${Base_Url}/create-designation`, subdata)
      .then((res) => {
        console.log(res.data);
        // window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-designation`)
      .then((res) => {
        setDesgination(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [designation]);

  const handleDelete = (data) => {
    axios
      .delete(`${Base_Url}/delete-designation/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Enter Detials of New Designation</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-group mb-3'>
                    <label>Name</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Name'
                      label='Name:'
                      name='name'
                      value={data.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-group mb-3 col-md-4'>
                    <label>Status</label>
                    <select
                      defaultValue={"option"}
                      onChange={(event) => changeFruit(event.target.value)}
                      value={currentFruit}
                      id='inputState'
                      className='form-control'
                    >
                      <option value='option' disabled>
                        Choose Status
                      </option>
                      <option value='true'>true</option>
                      <option value='false'>False</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          submit();
        }}
      >
        {" "}
        Save
      </Button>

      <>
        {" "}
        <div className='card h-auto' style={{ marginTop: 10 }}>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              {designation === undefined ? (
                ""
              ) : (
                <table
                  className='table table-list i-table style-1 mb-4 border-0'
                  id='guestTable-all3'
                >
                  <thead>
                    <tr>
                      <th>name</th>

                      <th>status</th>

                      <th>Action</th>
                      <th className='bg-none'></th>
                      <th className='bg-none'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {designation.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='media-bx d-flex py-3  align-items-center'>
                              <div>
                                <h5 className='mb-0'>{item.name}</h5>
                                {/* <p className="mb-0">1x </p>  */}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className='media-bx d-flex py-3  align-items-center'>
                              <div>
                                <h5 className='mb-0'>
                                  {item.status === true ? "Active" : "InActive"}
                                </h5>
                                {/* <p className="mb-0">1x </p>  */}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Link
                                to='/editDesignation'
                                state={item}
                                className='btn btn-outline-primary'
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  // handleDelete(item._id);
                                  del(item._id);
                                }}
                                className='btn btn-outline-primary'
                              >
                                Delete
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default CreateDesignation;
