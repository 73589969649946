import axios from "axios";
import React, { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { useLocation, useParams } from "react-router-dom";
import { Base_Url } from "../../../misc";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
const WithDrawDetails = () => {
  const location = useLocation();
  const [item, setItem] = useState(location.state);

  // const { register, handleSubmit, reset, getValues, setValue } = useForm();
  const params = useParams();

  useEffect(() => {
    // setItem(location.state);
    getwithdrawDetails();
  }, []);
  console.log(item);
  console.log(item, "withdraw-details");
  const getwithdrawDetails = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/get-single-withdrawal/${params.id}`
      );
      if (response.data.status === "Success") {
        setItem(response.data.data[0]);
        // reset({ ...response.data.data[0] });
      }
    } catch (e) {}
  };

  const {
    withdrwalAmount,
    balanceDuringWithrawal,
    bankAccountDetails,
    userId,
    orderID,
    rejectReasons,
    status,
    createdDate,
    updatedDate,
  } = item;
  const handleStatus = async (status, data) => {
    console.log(data);
    if (status === "ACCEPT") {
      const res = await axios.post(
        `${Base_Url}/update-withdrawal/${data._id}`,
        {
          status: 1,
        }
      );
      if (res.data.status === "fail" && res.data.data.modifiedCount > 0) {
        Swal.fire({ icon: "error", title: "updated " });
      } else if (res.data.status === "Success") {
        Swal.fire({ icon: "success", title: "Successful" });
      }
      console.log(res);
    } else {
      Swal.fire({
        title: "write reason",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Done",
        showLoaderOnConfirm: true,
        preConfirm: async (text) => {
          try {
            const result = await axios.post(
              `${Base_Url}/update-withdrawal/${data._id}`,
              {
                status: 2,
                rejectReasons: [text],
              }
            );
          } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({ icon: "info", title: "rejected" });
        }
      });
    }
    console.log(status);
  };
  console.log(bankAccountDetails, createdDate, "withdraw-details");
  return (
    <div>
      <div>
        <div className='card'>
          <div className='card-header border-0 pb-0'>
            <h3>Withdrwal Information</h3>
          </div>
          <div className='card-body'>
            <div className='row align-items-center'>
              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Withdrawal Amount</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankAccName'
                  value={withdrwalAmount}
                />
              </div>
              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Balance During Withrawal</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankAccNo'
                  value={balanceDuringWithrawal}
                />
              </div>

              <div className='card'>
                <div className='card-header border-0 pb-0'>
                  <h3>Bank Account Details</h3>
                </div>
                <div className='card-body'>
                  <div className='row align-items-center'>
                    <div className='col-xl-4 mb-3'>
                      <h6 className='mb-0'>
                        <b> {"Account Name:"} </b>
                      </h6>
                    </div>
                    <div className='col-xl-8 mb-3'>
                      <input
                        disabled
                        type='text'
                        className='form-control input-default '
                        placeholder={bankAccountDetails[0]?.bankAccName}
                        name='bankAccName'
                        value={bankAccountDetails[0]?.bankAccName}
                      />
                    </div>
                    <div className='col-xl-4 mb-3'>
                      <h6 className='mb-0'>Bank Account No:</h6>
                    </div>
                    <div className='col-xl-8 mb-3'>
                      <input
                        disabled
                        type='text'
                        className='form-control input-default '
                        placeholder={bankAccountDetails[0]?.bankAccNo}
                        name='bankAccNo'
                        value={bankAccountDetails[0]?.bankAccNo}
                      />
                    </div>
                    <div className='col-xl-4 mb-3'>
                      <h6 className='mb-0'>{"Bank Name: "}</h6>
                    </div>
                    <div className='col-xl-8 mb-3'>
                      <input
                        disabled
                        type='text'
                        className='form-control input-default '
                        placeholder={bankAccountDetails[0]?.bankName}
                        name='bankAccNo'
                        value={bankAccountDetails[0]?.bankName}
                      />
                    </div>
                    <div className='col-xl-4 mb-3'>
                      <h6 className='mb-0'>{"Bank Routing No.: "}</h6>
                    </div>

                    <div className='col-xl-8 mb-3'>
                      <input
                        disabled
                        type='text'
                        className='form-control input-default '
                        placeholder={bankAccountDetails[0]?.bankRoutingNo}
                        name='bankAccNo'
                        value={bankAccountDetails[0]?.bankRoutingNo}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>{"Status: "}</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  placeholder={status}
                  name='bankAccNo'
                  value={status ? "active" : "not active"}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>{"Created Date: "}</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='date'
                  className='form-control input-default '
                  placeholder={status}
                  name='bankAccNo'
                  value={moment(createdDate).format("YYYY-MM-DD")}
                />
              </div>
              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>{"Updated Date: "}</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='date'
                  className='form-control input-default '
                  placeholder={status}
                  name='bankAccNo'
                  value={moment(updatedDate).format("YYYY-MM-DD")}
                />
              </div>

              <div className='col-12'>
                <div className=''>
                  <Button onClick={() => handleStatus("ACCEPT", item)}>
                    ACCEPT
                  </Button>
                  <Button
                    onClick={() => handleStatus("REJECT", item)}
                    className='ms-4'
                  >
                    REJECT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithDrawDetails;
