import { logDOM } from "@testing-library/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Base_Url } from "../../../misc";
import { Pagination } from "react-bootstrap";

const AllDeliveryPlan = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(5);
  const [limit, setLimit] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [deliveryPlans, setDeliveryPlans] = useState([]);
  const [error, setError] = useState("");
  const [sortBy, setSortBy] = useState(""); // State for sorting by
  const [sortOrder, setSortOrder] = useState("");
  const fetchData = async () => {
    try {
      const response = await axios.get(`${Base_Url}/get-deliveryplans`, {
        params: {
          page: currentPage,
          limit: limit,
          searchTerm,
          sortBy: sortBy,
          sortOrder: sortOrder,
          //   status,
          //   startDate,
          //   endDate,
        },

      });
      console.log(response.data, "api-deliveryplan");
      if (response.data.status == "Success") {
        setDeliveryPlans(response.data.data);
        setPage(response.data.totalData / limit);
      }

      setError("");
    } catch (error) {
      console.error("An error occurred while fetching delivery plans:", error);
      setError(
        "An error occurred while fetching delivery plans. Please try again."
      );
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, limit, searchTerm, sortBy, sortOrder]);
  return (
    <div>
      <div className='card h-auto'>
        <div className='row mt-2 p-3'>
          <div className='col-12 col-sm-12 col-xs-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 form-group'>
            {" "}
            <input
              className='form-control input-default '
              type='text'
              placeholder='search anything'
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='col-4 col-sm-4 col-xs-4 col-md-1 col-lg-1 col-xl-1 col-xxl-1 form-group'>
            <div className='d-flex align-items-center'>
              <span>Size</span>
              <input
                type='number'
                className='form-control input-default '
                onChange={(e) => setLimit(e.target.value)}
              />
            </div>
          </div>

          <div className='col-4 col-sm-4 col-xs-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 form-group'>
            <select
              value={sortBy}
              onChange={handleSortByChange}
              className='form-control input-default '
            >
              <option value=''>Select field to sort by</option>
              <option value='platformFee'>Platform Fee</option>
              <option value='orderItemsLimit'>Order Items Limit</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div className='col-4 col-sm-4 col-xs-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 form-group'>
            <select
              value={sortOrder}
              onChange={handleSortOrderChange}
              className='form-control input-default '
            >
              <option value=''>Select sort order</option>
              <option value='asc'>Ascending</option>
              <option value='desc'>Descending</option>
            </select>
          </div>
        </div>
        <div className='card-body p-0'>
          <div className='table-responsive'>

            {deliveryPlans === undefined ? (
              "Loading..."
            ) : (
              <>
                <table
                  className='table table-list i-table style-1 mb-4 border-0'
                  id='guestTable-all3'
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Plan Title</th>
                      <th>Base Price</th>
                      <th>Order Items Limit</th>
                      <th>Action </th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                  {deliveryPlans.length < 1 && <p>No result found</p>}
                    {deliveryPlans.map((item, index) => (
                      <tr key={index+1}>
                        <td>{index+1}</td>
                        <td>{item?.planTitle}</td>
                        <td>{item?.basePrice}</td>
                        <td>{item?.orderItemsLimit}</td>

                        <td>
                          <div className='d-flex gap-2'>
                            <Link to={`/edit-delivery-plan/${item?._id}`} state={item}>
                              <div
                                style={{
                                  backgroundColor: "#d97706",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <AiFillEdit size={18} color='white' />
                              </div>
                            </Link>
                            <div
                              onClick={() => {
                                // confirmDelete(item._id);
                              }}
                              style={{
                                backgroundColor: "#db2777",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <AiFillDelete size={18} color='white' />
                            </div>
                            {/* <div>
                              <Link
                                to={`/food-details/${item?._id}`}
                                state={item?._id}
                              >
                                <AiFillEye size={30} color="orange" />
                              </Link>
                            </div> */}
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={`/edit-delivery-plan/${item?._id}`}
                              state={item?._id}
                            >
                              <AiFillEye size={30} color='orange' />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <Pagination>
            {Array.from({ length: page }, (_, index) => (
              <Pagination.Item
                key={index}
                //   active={page === index}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default AllDeliveryPlan;
