import React, { useState, useEffect } from "react";
import { Base_Url } from "../../../misc";
import { useParams } from "react-router-dom";

import { Modal, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import avater1 from "../../../images/avatar/1.jpg";
import product1 from "../../../images/product/1.jpg";
import product2 from "../../../images/product/2.jpg";
import product3 from "../../../images/product/3.jpg";
import product4 from "../../../images/product/4.jpg";
import tab1 from "../../../images/tab/1.jpg";
import tab2 from "../../../images/tab/2.jpg";
import tab3 from "../../../images/tab/3.jpg";
import tab4 from "../../../images/tab/4.jpg";
import PageTitle from "../../layouts/PageTitle";
//import StarRating from './../ProductList/StarRating';

const Food_View = () => {
  const [reviewToggle, setReviewToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [foodData, setFoodData] = useState(null);
  const params = useParams();
  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${Base_Url}/get-single-food/${params.id}`); // Replace with your actual API endpoint
      const data = await response.json();
      setFoodData(data.data[0].Rows[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!foodData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {/* <PageTitle motherMenu="Layout" activeMenu="Blank" /> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-3 col-lg-6  col-md-6 col-xxl-5 ">
                  {/* Tab panes */}
                  <Tab.Container defaultActiveKey="first">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <img
                          className="img-fluid"
                          src={foodData?.foodImage[0]?.large?.imageUrl}
                          alt=""
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <img
                          className="img-fluid"
                          src={foodData?.foodImage[1]?.large?.imageUrl}
                          alt=""
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <img
                          className="img-fluid"
                          src={foodData?.foodImage[2]?.large?.imageUrl}
                          alt=""
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="four">
                        <img
                          className="img-fluid"
                          src={foodData?.foodImage[3]?.large?.imageUrl}
                          alt=""
                        />
                      </Tab.Pane>
                    </Tab.Content>
                    <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
                      {/* Nav tabs */}
                      <Nav
                        as="ul"
                        className="nav slide-item-list mt-3"
                        role="tablist"
                      >
                        <Nav.Item as="li">
                          <Nav.Link as="a" eventKey="first" to="#first">
                            <img
                              className="img-fluid"
                              src={foodData?.foodImage[0]?.small?.imageUrl}
                              alt=""
                              width={50}
                            />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as="a" eventKey="second" to="#second">
                            <img
                              className="img-fluid"
                              src={foodData?.foodImage[1]?.small?.imageUrl}
                              alt=""
                              width={50}
                            />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as="a" eventKey="third" to="#third">
                            <img
                              className="img-fluid"
                              src={foodData?.foodImage[2]?.small?.imageUrl}
                              alt=""
                              width={50}
                            />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as="a" to="#for" eventKey="four">
                            <img
                              className="img-fluid"
                              src={foodData?.foodImage[3]?.small?.imageUrl}
                              alt=""
                              width={50}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Tab.Container>
                </div>
                {/*Tab slider End*/}

                <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                  <div className="product-detail-content">
                    {/*Product details*/}
                    <div className="new-arrival-content pr">
                      <h4>{foodData?.foodName}</h4>

                      {/* <div className="comment-review star-rating">
                        <ul>
                          {" "}
                          <li>
                            {" "}
                            <i className="fa fa-star" />
                          </li>{" "}
                          <li>
                            <i className="fa fa-star" />
                          </li>{" "}
                          <li>
                            <i className="fa fa-star" />
                          </li>{" "}
                          <li>
                            <i className="fas fa-star-half-alt" />
                          </li>{" "}
                          <li>
                            <i className="fas fa-star-half-alt" />
                          </li>
                        </ul>
                        <span className="review-text">(34 reviews) / </span>
                        <Link
                          onClick={() => setReviewToggle(true)}
                          className="product-review"
                          to="/ecom-product-detail"
                          data-toggle="modal"
                          data-target="#reviewModal"
                        >
                          Write a review?
                        </Link>
                      </div> */}

                      <div className="d-table mb-2">
                        <p className="price float-left d-block">
                          {foodData?.foodPrice} {" bdt"}
                        </p>
                      </div>
                      <p>
                        {" "}
                        {/* Availability:{" "} */}
                        Food Quantity:{" "}
                        <span className="item">
                          {" "}
                          {foodData?.foodQty}
                          {/* In stock <i className="fa fa-shopping-basket" /> */}
                        </span>
                      </p>
                      {/* <p>
                        {" "}
                        Food Type: <span className="item">{foodData?.foodType}</span>{" "}
                      </p>
                      <p>
                        Brand: <span className="item">Lee</span>
                      </p> */}

                      <p>
                        Type:&nbsp;&nbsp;
                        <span className="badge badge-success light me-1">
                          {foodData?.foodType}
                        </span>
                        {/* <span className="badge badge-success light me-1">
                          clothes
                        </span>
                        <span className="badge badge-success light me-1">
                          shoes
                        </span>
                        <span className="badge badge-success light me-1">
                          dresses
                        </span> */}
                      </p>

                      <p className="text-content">
                        {/* There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look even slightly believable. If you are going to
                        use a passage of Lorem Ipsum, you need to be sure there
                        isn't anything embarrassing. */}
                        {foodData?.description}
                      </p>
                      <div className="d-flex align-items-end flex-wrap mt-4">
                        {/*Quantity start*/}

                        {/*Quanatity End*/}
                        <div className="shopping-cart  mb-2 me-3">
                          <Link
                            className="btn btn-primary"
                            to={`/sellerdetails/${foodData?.sellerID}`}
                          >
                            View Seller Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* review */}
        <Modal
          show={reviewToggle}
          onHide={setReviewToggle}
          className="modal fade"
          id="reviewModal"
        >
          <>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Review</h5>
                <button
                  type="button"
                  onClick={() => setReviewToggle(false)}
                  className="btn-close"
                  data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setReviewToggle(false);
                  }}
                >
                  <div className="text-center mb-4">
                    <img
                      className="img-fluid rounded"
                      width={78}
                      src={avater1}
                      alt="DexignZone"
                    />
                  </div>
                  <div className="form-group">
                    <div className="rating-widget mb-4 text-center">
                      {/* Rating Stars Box */}
                      <div className="rating-stars">
                        <ul
                          id="stars"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <li>
                            <i className="fa fa-star me-1" />
                          </li>{" "}
                          <li>
                            <i className="fa fa-star me-1" />
                          </li>{" "}
                          <li>
                            <i className="fa fa-star me-1" />
                          </li>{" "}
                          <li>
                            <i className="fa fa-star me-1" />
                          </li>{" "}
                          <li>
                            <i className="fas fa-star-half-alt" />
                          </li>{" "}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <textarea
                      className="form-control"
                      placeholder="Comment"
                      rows={5}
                      defaultValue={""}
                    />
                  </div>
                  <button className="btn btn-success btn-block">RATE</button>
                </form>
              </div>
            </div>
          </>
        </Modal>
      </div>
    </>
  );
};

export default Food_View;
