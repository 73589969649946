import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Base_Url } from "../../../misc";
const EditDesignation = () => {
  const location = useLocation();
  const item = location.state;

  const [data, setData] = useState({
    name: item.name,
  });
  console.log(data);
  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState("option");
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;
    newContact.name !== "" ? setdisable(false) : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  const submit = () => {
    let subdata = {
      name: data.name,
      status: currentFruit,
    };
    console.log(subdata);
    axios
      .post(`${Base_Url}/update-designation/${item._id}`, subdata)
      .then((res) => {
        console.log(res.data);
        // window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {" "}
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Update Designation</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-group mb-3'>
                    <label>Name</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Name'
                      label='Name:'
                      name='name'
                      defaultValue={item?.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-group mb-3 col-md-4'>
                    <label>Status</label>
                    <select
                      defaultValue={item.status}
                      onChange={(event) => changeFruit(event.target.value)}
                      //   value={currentFruit}
                      id='inputState'
                      className='form-control'
                    >
                      <option value='option' disabled>
                        Choose Status
                      </option>
                      <option value='true'>true</option>
                      <option value='false'>False</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          submit();
        }}
      >
        {" "}
        Update
      </Button>
    </>
  );
};

export default EditDesignation;
