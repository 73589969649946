import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "./upload.css";
import axios from "axios";
import { format } from "date-fns";
// import { DayPicker } from "react-day-picker";
// import "react-day-picker/dist/style.css";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Swal from "sweetalert2";

import { Base_Url } from "../../../misc";
import { getUserDetails } from "../../../Helper/SessionHelper";

import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";

import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
//import { setLoading } from "../../../Redux/feature/ImageLoader/ImageLoaderSlice";
//import Loader from "../../Common/Loader";
//import UploaderModel from "../../Common/UploaderModel/UploaderModel";
import { useForm } from "react-hook-form";
// import ImageUploader from "../../../ImageUploader/ImageUploader";

import labels from "./food.json";

const FoodEdit = () => {
  const params = useParams();

  const animatedComponents = makeAnimated();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [catID, setCatID] = useState([]);
  const [cat, setcat] = useState();
  const [portionId, setPortionId] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [Tag, setTag] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [taq, setAdditionalTaq] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [insCatData, setInsCat] = useState("");
  const [metaImage, setMetaImage] = useState([]);
  const [availableDaysData, setAvailableDays] = useState();
  const DaysData = [
    { value: 1, label: "SATURDAY" },
    { value: 2, label: "SUNDAY" },
    { value: 3, label: "MONDAY" },
    { value: 4, label: "TUESDAY" },
    { value: 5, label: "WEDNESDAY" },
    { value: 6, label: "THURSDAY" },
    { value: 7, label: "FRIDAY" },
  ];

  //      meta image handle
  //--------------------------------------
  const handleMetaImage = (event) => {
    console.log("meta image");
    const fileList = Array.from(event.target.files);
    setMetaImage(fileList);
    console.log(metaImage, fileList, "meta image");
  };

  //----------------------------------------
  const handleImageDeleteFood = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);
    // console.log(selectedImageAmazingDish);
    setSelectedImage(updatedImages);
  };
  console.log(selectedDate, "date select");
  useEffect(() => {
    axios
      .get(`${Base_Url}/get-additionaltags`)
      .then((res) => {
        setAdditionalTaq(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
    const getCat = (e) => {
      axios
        .get(`${Base_Url}/get-category`)
        .then((res) => setCatID(res.data.data))
        .catch((err) => {
          console.log(err);
        });
    };
    getCat();
    const getPortion = (e) => {
      axios
        .get(`${Base_Url}/get-portionSizeUnit`)
        .then((res) => setPortionId(res.data.data))
        .catch((err) => {
          console.log(err);
        });
    };
    getPortion();
    const getSubcat = () => {
      axios
        .get(`${Base_Url}/get-sub-category-by-single-category/${cat}`)
        .then((res) => {
          console.log(res);
          setSubCat(res.data.data[0]?.subcategoryData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSubcat();
  }, [cat]);

  const handleCheck = (e) => {
    console.log(e.target.checked, "chk");
    if (e.target.checked) {
      setTag((oldArray) => [...oldArray, e.target.value]);
    } else {
      removeCities(e);
      console.log(Tag);
    }
  };
  const removeCities = (e) => {
    setTag([...Tag.filter((Tag) => Tag !== e.target.value)]);
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    setSelectedImage([...selectedImage, ...fileList]);
  };

  //start handle instant catering on change method
  const handleInCa = (e) => {
    const value = e.target.value;
    console.log(value);
    setInsCat(value);
  };

  //end handle instant catering on change method
  const dispatch = useDispatch();
  //start create food method
  const handleCreateFood = async (value) => {
    // e.preventDefault();
    // const form = e.target;
    const FoodName = value.name;
    // const Slug = value.slug;
    const Portion = value.portion;
    const Quantity = value.quantity;
    const Category = value.category;
    const Price = value.price;
    const discountPrice = value.discountprice;
    const Subcategory = value.subcategory;
    const StartDate = value.startdate;
    const EndDate = value.enddate;
    const FoodMinQty = value.minimumquantity;
    const foodType = value.foodType;
    const description = value.description;
    const metaTitle = value.metaTitle;
    const metaDescription = value.metaDescription;
    const metaKeyword = value.metaKeywords;
    const AvailableSameDaysEachWeek = value.foodAvailableSameDaysEachWeek;

    // const metaimage = value.metaImage;
    // console.log(metaimage, "from meta image");

    const FoodData = {
      // sellerID: getUserDetails().sellerIDInfo[0]?._id,

      categoryID: Category,
      subCategoryID: Subcategory,
      // cuisineCollection: { type: Array },
      // sellerImage: { type: String },
      //  sellerName: getUserDetails().sellerIDInfo[0]?.userFullName,
      foodName: FoodName,
      description: description,
      foodType: foodType, //Instant /preOrder /wish /catering
      // foodSlug: { type: String },
      foodImage: [],
      // foodThumb: { type: String },
      // foodVideo: { type: String },
      foodQty: Quantity,
      foodPrice: Price,
      foodDiscountPrice: discountPrice,
      foodDiscountPercentage: discountPrice,
      foodDiscountStartDate: StartDate,
      foodDiscountEndDate: EndDate,
      // foodPortionSize: { type: Number },
      foodPortionSizeUnit: Portion,
      foodMinimumOrderQty: FoodMinQty,
      // foodAdditionalInfo: { type: String },
      foodAdditionalTags: Tag,
      seo: {
        metaTitle: metaTitle,
        metaDescription: metaDescription,
        metaKeywords: metaKeyword,
        metaImage: [],
      },
      // foodExpiryTime: { type: String }, /n
      foodAvailableDays: availableDaysData,
      foodAvailableSameDaysEachWeek: AvailableSameDaysEachWeek,
      // foodOrderBeforeTime: { type: String },
      // foodRating: { type: Number },
      // status: { type: Boolean },
      // foodLabel: { type: Array }, //instant preorder capitilized n
      // customerRequestedFoodQty: { type: String },
      // customerDesireDeliveryDate: { type: String },
      // customerDesireDeliveryTime: { type: String },
      // createdBy: { type: String },
      // updatedBy: { type: String },
      // BSR: { type: Number },
    };
    console.log(FoodData, "msgfood");
    if (metaImage) {
      console.log(metaImage);
      // dispatch(setLoading(true));
      await ImageUploader("admin", "SEO/MetaIMage/", metaImage).then((data) => {
        if (data.status === "Success") {
          console.log(data, "meta image");
          FoodData.seo.metaImage = data.images;
        } else {
          // dispatch(setLoading(false));
        }
      });
    }
    //  dispatch(setLoading(true));
    // console.log(getUserDetails(getUserDetails(),"Meta/",));
    const imagePromise = ImageUploader("admin", "Food/", selectedImage).then(
      (data) => {
        if (data.status === "Success") {
          FoodData.foodImage = data.images;
          // dispatch(setLoading(false));
        }
      }
    );

    Promise.resolve(imagePromise).then(async () => {
      await axios
        .post(`${Base_Url}/update-food/${params.id}`, FoodData)
        .then((res) => {
          console.log(res.data, "ll");
          //  dispatch(setLoading(false));
          if (res.data.status === "Success") {
            Swal.fire({
              icon: "success",
              title: "Food created successfully!!!",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Food does not Create!!!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    });
  };
  // const data = onUploadProgress()
  // const StaticDesign = () => {
  //   return (
  //     <div>
  //       <div className='image-box'>1</div>
  //     </div>
  //   );
  // };

  //   const loader = useSelector((state) => state.ImageLoader.loading);

  //   let percentage = useSelector((state) => state.ImageLoader.percentage);

  return (
    <>
      {/* <UploaderModel loader={loader} /> */}
      {/* {loader === true ? (
        <Loader />
      ) : ( */}
      <div className="container d-flex create-food-container">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{labels.title.bn}</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit(handleCreateFood)}>
                  <div className="form-group mb-3">
                    <label>{labels.foodName.bn}</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={labels.foodName.bn}
                      label="Name:"
                      name="name"
                      {...register("name", { required: true })}
                      // value={data?.name}
                      // onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="text-danger">Food Name is required</span>
                    )}
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-lg-6">
                      <label>{labels.foodQuantity.bn}</label>
                      <input
                        type="number"
                        className="form-control input-default "
                        placeholder={labels.foodQuantity.bn}
                        label="quantity:"
                        // name='quantity'
                        {...register("quantity", { required: true })}
                        // value={data?.quantity}
                        // onChange={handleChange}
                      />
                      {errors.quantity && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>

                    <div className="form-group mb-3 col-lg-6">
                      <label>{labels.foodPrice.bn}</label>
                      <input
                        type="number"
                        className="form-control input-default "
                        placeholder={labels.foodPrice.bn}
                        label="price:"
                        // name='price'
                        {...register("price", { required: true })}
                        // value={data?.price}
                        // onChange={handleChange}
                      />
                      {errors.price && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    className="row justify-content-center mt-3 mb-5 "
                    // style={{ marginLeft: "30%" }}
                  >
                    <div className="col-12">
                      <h3>{labels.foodPhoto.bn}</h3>
                      <div className="row gap-2">
                        {/* <div className=' '> */}
                        {selectedImage.length <= 6 ? (
                          <>
                            {" "}
                            {[...Array(6)].map((item, index) => (
                              <div className="col">
                                <div className="image-box col justify-content-center align-items-center">
                                  {selectedImage[index] &&
                                    selectedImage.length > 0 && (
                                      <div className="img">
                                        <img
                                          alt=""
                                          // className="img-fluid"
                                          src={URL.createObjectURL(
                                            selectedImage[index]
                                          )}
                                        />
                                        <div className="img-delete">
                                          <AiFillDelete
                                            size={20}
                                            color="red"
                                            onClick={() =>
                                              handleImageDeleteFood(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {selectedImage.map((item, index) => (
                              <div className="col">
                                <div className="image-box d-flex justify-content-center align-items-center">
                                  {selectedImage[index] &&
                                    selectedImage.length > 0 && (
                                      <div className="img">
                                        <img
                                          alt=""
                                          // className="img-fluid"
                                          src={URL.createObjectURL(
                                            selectedImage[index]
                                          )}
                                        />
                                        <div className="img-delete">
                                          <AiFillDelete
                                            size={20}
                                            color="red"
                                            onClick={() =>
                                              handleImageDeleteFood(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                        {/* </div> */}
                      </div>
                    </div>

                    <div>
                      {selectedImage.length < 5 ? (
                        <>
                          {Array(5).map((item) => (
                            <staticDesign />
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* {selectedImage && (
                <div>
                  {selectedImage?.map((item, index) => {
                    console.log(item);
                    return (
                      <div>
                        <img
                          alt='not found'
                          width={"80px"}
                          src={URL.createObjectURL(item)}
                        />
                        <AiFillDelete
                          onClick={() => handleImageDeleteFood(index)}
                        />
                      </div>
                    );
                  })}
                  <br />
                </div>
              )} */}

                    <br />
                    <br />

                    <div id="form-file-upload">
                      <input
                        type="file"
                        id="input-file-upload"
                        multiple
                        onChange={handleFileChange}
                        // onChange={(event) => {
                        //   console.log(event.target.files[0]);
                        //   setSelectedImage(Array.from(event.target.files));
                        // }}
                      />
                      <label id="label-file-upload" htmlFor="input-file-upload">
                        <div>
                          <p>Drag and drop your file here or</p>
                          <span className="upload-button">Upload a file</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mb-3  col-lg-4">
                      <label>{labels.category.bn}</label>
                      <select
                        defaultValue={"option"}
                        // name='category'
                        {...register("category", { required: true })}
                        // onClick={getCat}
                        onChange={(event) => setcat(event.target.value)}
                        // value={currentFruit}
                        className="form-control"
                      >
                        <option value="option" disabled>
                          Select Category
                        </option>

                        {catID?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.categoryName}
                          </option>
                        ))}
                      </select>
                      {errors.category && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>
                    <div className="form-group mb-3  col-lg-4">
                      <label>{labels.subCat.bn}</label>
                      <select
                        defaultValue={"option"}
                        // name='subcategory'
                        className="form-control"
                        {...register("subcategory", { required: true })}
                      >
                        <option value="option" disabled>
                          Select Sub Category
                        </option>

                        {subcat?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.categoryName}
                          </option>
                        ))}
                      </select>
                      {errors.subcategory && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>

                    <div className="form-group mb-3 col-lg-4">
                      <label>{labels.portion.bn}</label>
                      <select
                        // name='portion'
                        {...register("portion", { required: true })}
                        defaultValue={"option"}
                        // onClick={getPortion}
                        // onChange={(event) => FPortion(event.target.value)}
                        // value={changePortion}
                        className="form-control"
                      >
                        <option value="option" disabled>
                          Choose Status
                        </option>

                        {portionId.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.sizeUnitName}
                          </option>
                        ))}
                      </select>
                      {errors.portion && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label>{labels.foodDescription.bn}</label>
                    <textarea
                      type="text"
                      className="form-control input-default "
                      placeholder={labels.foodDescription.bn}
                      label="Description:"
                      // name='description'
                      {...register("description", { required: false })}
                      style={{ height: 100 }}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>

                  {/* <div className='form-group mb-3'>
              <label>Food Image</label>
              <input
                type='text'
                className='form-control input-default '
                placeholder='Food Image'
                label='price:'
                name='image'
                value={data?.image}
                onChange={handleChange}
              />
            </div> */}

                  <div className="row">
                    <div className="form-group mb-3 col-lg-4">
                      <label>{labels.foodDiscountPrice.bn}</label>
                      <input
                        type="number"
                        className="form-control input-default "
                        placeholder={labels.foodDiscountPrice.bn}
                        label="discountprice:"
                        name="discountprice"
                        // value={data?.discountprice}
                        // onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3 col-lg-4">
                      <label>{labels.foodDiscountPercentage.bn}</label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder={labels.foodDiscountPercentage.bn}
                        label="foodDiscountPercentage:"
                        name="foodDiscountPercentage"
                        // value={data?.foodDiscountPercentage}
                        // onChange={handleChange}
                      />
                    </div>

                    <div className="form-group mb-3 col-lg-4">
                      <label>{labels.foodMinOrderQuan.bn}</label>
                      <input
                        type="number"
                        className="form-control input-default "
                        placeholder={labels.foodMinOrderQuan.bn}
                        label="minimumquantity:"
                        name="minimumquantity"
                        // value={data?.minimumquantity}
                        // onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className='form-group mb-3 col-6'>
                <label>Food Discount Start Date</label>
                <input
                  type='date'
                  className='form-control input-default '
                  placeholder='Food Discount Percentage'
                  label='startdate:'
                  name='startdate'
                />
              </div> */}

                    {/* <div className='  form-group mb-3 col-6'>
                <label>Food Discount End Date</label>
                <input
                  type='date'
                  className='form-control input-default '
                  placeholder='Food Discount Percentage'
                  label='enddate:'
                  name='enddate'
                />
              </div> */}
                  </div>

                  <div className="row">
                    <div className=" form-group mb-3 col-lg-4">
                      <label>{labels.discountStart.bn}</label>
                      <input
                        type="date"
                        className="form-control input-default "
                        placeholder={labels.discountStart.bn}
                        label="startdate:"
                        name="startdate"
                      />
                    </div>

                    <div className="  form-group mb-3 col-lg-4">
                      <label>{labels.discountEnd.bn}</label>
                      <input
                        type="date"
                        className="form-control input-default "
                        placeholder={labels.discountEnd.bn}
                        label="enddate:"
                        // name='enddate'
                        {...register("enddate", { required: false })}
                      />
                    </div>
                    <div className=" form-group mb-3 col-lg-4">
                      <label>{labels.foodType.bn} </label>
                      <select
                        // name='foodType'
                        {...register("foodType", { required: true })}
                        className="form-control input-default "
                        defaultValue="instant"
                        onChange={handleInCa}
                      >
                        <option value="instant">INSTANT</option>
                        <option value="catering"> CATERING</option>
                      </select>
                      {errors.foodType && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {insCatData === "catering" ? (
                      <div className="row">
                        <div className="col-lg-8">
                          <Select
                            // value={selectedOptions}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            // defaultValue={selectedOptions}
                            onChange={(item) => setAvailableDays(item)}
                            classNamePrefix="select"
                            // defaultValue={[Options[4], Options[5]]}
                            isMulti
                            options={DaysData}
                          />
                        </div>
                        <div className="col">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              defaultChecked
                              role="switch"
                              id="flexSwitchCheckDefault"
                              {...register("foodAvailableSameDaysEachWeek", {
                                required: false,
                              })}
                            />
                            <label
                              class="form-check-label"
                              for="flexSwitchCheckDefault"
                            >
                              Apply same days all weak
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    <div className="form-group mb-3 col-lg-3">
                      <label>{labels.metatitle.bn}</label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder={labels.metatitle.bn}
                        label="metaTitle:"
                        // name='metaTitle'
                        {...register("metaTitle", { required: false })}
                        // value={data?.price}
                        // onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3 col-lg-3">
                      <label>{labels.metaDescription.bn}</label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder={labels.metaDescription.bn}
                        label="metaDescription"
                        // name='metaDescription'
                        {...register("metaDescription", { required: false })}
                        // value={data?.price}
                        // onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3 col-lg-3">
                      <label>{labels.metaKeywords.bn}</label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder={labels.metaKeywords.bn}
                        label="metaKeywords"
                        // name='metaKeywords'
                        {...register("metaKeywords", { required: false })}
                        // value={data?.price}
                        // onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-3">
                      <label>{labels.metaImg.bn}</label>
                      <input
                        type="file"
                        className="form-control input-default "
                        placeholder={labels.metaImg.bn}
                        label="metaImage"
                        name="metaImage"
                        // value={data?.price}
                        // onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div>
                    <h3>Additional Tag</h3>
                    <div
                      className="d-flex addTag"
                      style={{ display: "inline-block" }}
                    >
                      {taq.map((item, index) => (
                        <span key={index}>
                          {item.status === true ? (
                            <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info">
                              <input
                                onChange={(e) => {
                                  handleCheck(e);
                                }}
                                type="checkbox"
                                name={item.tagName}
                                // {...register(`${item.tagName}`, {
                                //   // required: true,
                                // })}
                                value={item.tagName}
                                className="form-check-input"
                                id={`customCheckBox${index}`}
                              />

                              <label
                                className="form-check-label"
                                htmlFor={`customCheckBox${index}`}
                              >
                                {item.tagName}
                              </label>
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      ))}
                    </div>
                    <Button style={{ marginLeft: 25 }} type="submit">
                      {" "}
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default FoodEdit;
