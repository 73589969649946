import React, { useState, useEffect } from "react";
import { Col, Row, Card, Dropdown, Button } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { Base_Url } from "../../../../misc";
const BestSeller = () => {
  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState();
  const [cat, setCat] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [SecondselectedOptions, setTwoSelectedOptions] = useState();

  const created = () => {
    Swal.fire({
      icon: "success",
      title: "Style has Created",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [layoutOne, setLayoutOne] = useState({
    layoutName: "",
    layoutLimit: "",
  });

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...layoutOne };
    newContact[e.target.name] = e.target.value;

    newContact.layoutName !== "" && newContact.layoutLimit !== ""
      ? setdisable(false)
      : setdisable(true);
    setLayoutOne(newContact);
  };

  const getCat = () => {
    axios
      .get(`${Base_Url}/get-become-seller`)
      .then((res) => {
        setCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCat();
  }, []);
  useEffect(() => {
    if (cat && cat !== undefined) {
      const tttt = [];
      cat.forEach((el) => {
        const data = { value: el._id, label: el.kitchenName };

        tttt.push(data);
        setOptions(tttt);
      });
    }
  }, [cat]);
  console.log(selectedOptions);

  let finalPostdata = {
    circleType: layoutOne.layoutName,
    circleSellerID: selectedOptions,
    circleLimit: layoutOne.layoutLimit,
  };

  const handleSubmit = () => {
    axios
      .post(`${Base_Url}/create-circle-type-food`, finalPostdata)
      .then((res) => {
        console.log("sucess", res.data);
        created();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {" "}
      <Row>
        <Col>
          <Card>
            <Card.Header className='d-block'>
              <Card.Title>Section Layout For Best Seller On Circle</Card.Title>
              <Card.Text className='m-0 subtitle'>
                Fill Your Section Data
              </Card.Text>
            </Card.Header>
            <Row>
              <Col>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Section Title
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <input
                            type='text'
                            className='form-control'
                            id='val-username'
                            name='layoutName'
                            placeholder='Enter a section Title..'
                            value={layoutOne.layoutName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className='form-group mb-3 row'>
                        <label
                          className='col-lg-4 col-form-label'
                          htmlFor='val-username'
                        >
                          Select Seller
                          <span className='text-danger'>*</span>
                        </label>
                        <div className='col-lg-6'>
                          <Select
                            value={selectedOptions}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            // defaultValue={selectedOptions}
                            onChange={(item) => setSelectedOptions(item)}
                            classNamePrefix='select'
                            // defaultValue={[Options[4], Options[5]]}
                            isMulti
                            options={options}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>

          <div className='form-group mb-3 row'>
            <label className='col-lg-4 col-form-label' htmlFor='val-username'>
              Section Displaylimit
              <span className='text-danger'>*</span>
            </label>
            <div className='col-lg-6'>
              <input
                type='text'
                className='form-control'
                id='val-username'
                name='layoutLimit'
                placeholder='Enter a section Display Limit..'
                value={layoutOne.layoutLimit}
                onChange={handleChange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: 50 }}>
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default BestSeller;
