import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Pagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Base_Url } from "../../../misc";
import axios from "axios";
import moment from "moment";
import { AiFillEye, AiFillEdit, AiFillDelete } from "react-icons/ai";

import Swal from "sweetalert2";
import MyPagination from "../Pagination/MyPagination";
import PageTitle from "../../layouts/PageTitle";

const BuyerList = () => {
  const [Buyer, setBuyer] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [buyerApprovalFilter, setBuyerApprovalFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [dropValue, setDropValue] = useState("Order by");
  const [filter, setfilter] = useState("filter by");
  const [apiPagination, setApiPagination] = useState("Sort by");
  const [PAGE_SIZE, setPAGE_SIZE] = useState(20);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${Base_Url}/get-users`);
        if (response.data.status === "Success") {
          setBuyer(response.data.data);
        } else {
          setBuyer([]);
        }
        console.log(response.data, "user response");
      } catch (e) {}
    };
    getUser();
  }, []);

  const handlesort = (value) => {
    if (value == "all") {
      // setfilter1("All");
      setFilteredData([...Buyer]);
      // setfilter1("All");
    } else if (value === "active") {
      // setfilter1("Active");
      const sortedItems = Buyer.filter((item) => item?.status === "APPROVED");

      setFilteredData(sortedItems);
      // setfilter1("Active");
    } else if (value === "inactive") {
      // setfilter1("InActive");
      const sortedItems = Buyer.filter((item) => item?.status === "PENDING");

      setFilteredData(sortedItems);
      // setfilter1("InActive");
    }
  };

  const handleBlock = (id, message) => {
    axios
      .post(`${Base_Url}/update-users/${id}`, {
        status: "PENDING",
        // blockNote: message,
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = Buyer.filter((r) => r._id !== id);
          setBuyer(updateData);
          Swal.fire({
            icon: "success",
            title: "Buyer Deactivated Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const block = async (_id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      input: "textarea",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deactivate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result);

        // console.log(blockNote);
        handleBlock(_id, result.value);
      }
    });
  };

  const unBlock = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Activate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnblock(_id);
      }
    });
  };

  const handleUnblock = (id) => {
    axios
      .post(`${Base_Url}/update-users/${id}`, {
        status: "APPROVED",
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = Buyer.filter((r) => r._id !== id);
          setBuyer(updateData);
          Swal.fire({
            icon: "success",
            title: "Buyer Activated Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
      }
    });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${Base_Url}/delete-users/${id}`)
      .then((res) => {
        console.log(res.data.data.deletedCount);
        if (res.data.data.deletedCount === 1) {
          const updateData = Buyer.filter((r) => r._id !== id);
          setBuyer(updateData);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Filtering Logic
  useEffect(() => {
    let filtered = Buyer;
    setCurrentPage(1);
    if (searchTerm.trim() !== "") {
      filtered = filtered.filter(
        (item) =>
          item.userFullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.userMobileNo.includes(searchTerm) ||
          item.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (buyerApprovalFilter !== "all") {
      filtered = filtered.filter((item) => item.status === buyerApprovalFilter);
    }

    setFilteredData(filtered);
  }, [searchTerm, Buyer, buyerApprovalFilter]);

  // Pagination Logic
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentItems = (filteredData.length > 0 ? filteredData : Buyer).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData.length : Buyer.length) / PAGE_SIZE
  );

  return (
    <div>
      <PageTitle activeMenu="Buyer List" motherMenu="Buyer List" />
      <div
        className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="input-group search-area2 style-1 w-[75%] border d-flex"
          style={{ marginRight: "15px" }}
        >
          <span className="input-group-text p-0">
            <Link to={"#"}>
              <svg
                className="me-1"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                  fill="#FC8019"
                />
              </svg>
            </Link>
          </span>

          <input
            type="text"
            className="form-control p-2 "
            placeholder="Filter by name, email, phone"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="input-group ml-2 pl-2 style-1 w-50">
          {/* Search filter */}
          <Dropdown
            className="notification-select "
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {dropValue}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setDropValue("Date")}
                eventKey="Activity"
              >
                Date
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setDropValue("Ratings")}
                eventKey="Activity"
              >
                Ratings
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Filter by */}
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              Sort By Status
              {/* {filter1} */}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => sortAll()} eventKey="Activity">
                All
              </Dropdown.Item>

              <Dropdown.Item
                onClick={(e) => sortInactive()}
                eventKey="Activity"
              >
                Inactive
              </Dropdown.Item>

              <Dropdown.Item onClick={(e) => sortActive()} eventKey="Activity">
                Active
              </Dropdown.Item>
            </Dropdown.Menu> */}

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(e) => handlesort("all")}
                // eventKey="Activity"
              >
                All
              </Dropdown.Item>

              <Dropdown.Item
                onClick={(e) => handlesort("inactive")}
                // eventKey="Activity"
              >
                Inactive
              </Dropdown.Item>

              <Dropdown.Item
                onClick={(e) => handlesort("active")}
                // eventKey="Activity"
              >
                Active
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/*  Sort by */}
          {/* <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {apiPagination}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                1-50
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                51-100
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                101-150
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("151-200")}
                eventKey="Activity"
              >
                151-200
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              <i
                className="fa-solid fa-location-dot mx-2"
                style={{ color: "orange" }}
              />{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                Thana
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                Region
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                City
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      </div>

      <div className="card h-auto" style={{ marginTop: 2 }}>
        <div className="card-body p-0">
          <div className="table-responsive">
            {Buyer === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Status</th>
                    <th>Join Date</th>
                    <th>Roles</th>
                    <th>Action</th>

                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="media-bx d-flex py-3 align-items-center">
                            <div>
                              {index + 1 + PAGE_SIZE * (currentPage - 1)}
                              {/* <p className="mb-0">1x </p> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3 align-items-center">
                            <div>
                              {item?.userFullName}
                              {/* <p className="mb-0">1x </p> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3 align-items-center">
                            <div>
                              {item?.userMobileNo}
                              {/* <p className="mb-0">1x </p> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3 align-items-center">
                            <div>
                              {item?.address}
                              {/* <p className="mb-0">1x </p> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3 align-items-center">
                            <div>
                              <td>
                                {item?.status === "APPROVED" ? (
                                  <>
                                    {""}
                                    Active
                                  </>
                                ) : item?.status === "PENDING" ? (
                                  <>
                                    {/* <i className="fa fa-circle text-warning me-1"></i>{" "} */}
                                    Inactive
                                  </>
                                ) : (
                                  <>
                                    {/* <i className='fa fa-circle text-success me-1'></i> Success */}
                                  </>
                                )}
                              </td>
                              {/* <p className="mb-0">1x </p> */}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div>
                            {moment(item?.registrationDate).format(
                              "MMMM Do YYYY"
                            )}
                          </div>
                        </td>

                        <td>
                          <div>
                            <span className="btn btn-primary m-1 p-2">B</span>
                            {console.log("Mash " + item?.sellerStatus)}
                            {item?.sellerStatus === "ACCEPTED" ? (
                              <span className="text-sm btn btn-success m-1 p-2">
                                S
                              </span>
                            ) : (
                              ""
                            )}

                            {item?.sellerStatus === "PENDING" ? (
                              <span className="text-sm btn btn-success m-1 p-2">
                                L
                              </span>
                            ) : (
                              ""
                            )}

                            {/* {item?.sellerStatus === "DRAFT" ? (
                              <span className="text-sm btn btn-success m-1 p-2">
                                D
                              </span>
                            ) : (
                              ""
                            )} */}

                            {/* <span className="text-sm btn btn-danger m-1 p-2">
                              R
                            </span> */}
                            {item?.riderStatus === "ACCEPTED" ? (
                              <span className="text-sm btn btn-danger m-1 p-2">
                                R
                              </span>
                            ) : (
                              ""
                            )}

                            {item?.riderStatus === "PENDING" ? (
                              <span className="text-sm btn btn-danger m-1 p-2">
                                LR
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <DropdownButton
                              as={ButtonGroup}
                              title="Select"
                              id="bg-nested-dropdown"
                            >
                              <Dropdown.Item eventKey="1">
                                {item.status === "APPROVED" && (
                                  <div
                                    onClick={() => {
                                      block(item._id);
                                    }}
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    Deactivate
                                  </div>
                                )}

                                {item.status === "PENDING" && (
                                  <div
                                    onClick={() => {
                                      unBlock(item._id);
                                    }}
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    Activate
                                  </div>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="2">
                                <Link
                                  to={`/buyer-view/${item?._id}`}
                                  state={item?._id}
                                >
                                  View
                                </Link>
                              </Dropdown.Item>

                              <Dropdown.Item eventKey="3">
                                <Link
                                  to={`/buyer-edit/${item?._id}`}
                                  state={item?._id}
                                >
                                  Edit
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="4">
                                <div
                                  onClick={() => {
                                    del(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Delete
                                </div>
                              </Dropdown.Item>
                            </DropdownButton>
                            {/* {item.status === true && (
                                <button
                                  onClick={() => {
                                    block(item._id);
                                  }}
                                  style={{
                                    backgroundColor: "#db2777",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    border: 0,
                                    color: "white",
                                  }}
                                >
                                  Block
                                </button>
                              )}

                              {item.status === false && (
                                <button
                                  onClick={() => {
                                    unBlock(item._id);
                                  }}
                                  style={{
                                    backgroundColor: "#db2777",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    border: 0,
                                    color: "white",
                                  }}
                                >
                                  Unblock
                                </button>
                              )}
                              <button
                                onClick={() => {
                                  del(item._id);
                                }}
                                style={{
                                  backgroundColor: "#db2777",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  border: 0,
                                  color: "white",
                                }}
                              >
                                Delete
                              </button> */}
                          </div>
                        </td>

                        <td>
                          <p>This is a demo comment.</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerList;
