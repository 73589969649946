import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Base_Url } from "../../../misc";
const EditPortion = () => {
  const location = useLocation();
  const item = location.state;
  const [data, setData] = useState({
    categoryName: item.sizeUnitName,
  });

  const [portionSize, setportionSize] = useState([]);

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState("option");

  console.log(currentFruit);
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const submit = () => {
    let subdata = {
      sizeUnitName: data.categoryName,
      status: currentFruit,
    };
    console.log(subdata);
    axios
      .post(`${Base_Url}/update-portionSizeUnit/${item._id}`, subdata)

      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  return (
    <>
      <ToastContainer />{" "}
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Enter Detials of New Portion</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-group mb-3'>
                    <label>Size Unit Name</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Type Name'
                      label='Name:'
                      name='categoryName'
                      defaultValue={item?.sizeUnitName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='row d-flex'>
                    <div className='form-group mb-3 col-md-4'>
                      <label>Status</label>
                      <select
                        defaultValue={item?.status}
                        onChange={(event) => changeFruit(event.target.value)}
                        // value={currentFruit}
                        id='inputState'
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Choose Status
                        </option>
                        <option value={"true"}>true</option>
                        <option value={"false"}>false</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          submit();
        }}
      >
        {" "}
        Save
      </Button>
    </>
  );
};

export default EditPortion;
