import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay } from "swiper";
import axios from "axios";
import "swiper/css";
import { useEffect } from "react";
import { Base_Url } from "../../../../misc";

const MenuCategorySlider = () => {
  const [seller, setSeller] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${Base_Url}/get-become-seller`)
      .then((res) => {
        setSeller(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, []);
  return (
    <>
      <Swiper
        className='mySwiper-2'
        //speed= {1200}
        slidesPerView={3}
        spaceBetween={20}
        loop={true}
        // autoplay= {{
        //    delay: 1200,
        // }}
        modules={[Autoplay]}
        breakpoints={{
          360: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1480: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1600: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1920: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
        }}
      >
        {seller &&
          seller?.map((item, index) => {
            return (
              <SwiperSlide>
                <div
                  className='cate-bx text-center'
                  key={index}
                  onClick={() => {
                    navigate("/FoodBySeller", { state: { item } });
                  }}
                >
                  <div className='card'>
                    <div className='card-body'>
                      <img
                        src={item.sellerProfilePhoto}
                        style={{ width: 500, height: 120 }}
                        className='img-fluid'
                      />

                      <h6 className='mb-0 font-w500'>{item.kitchenName}</h6>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
};
export default MenuCategorySlider;
