import React from "react";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
export const TestImageUploader = () => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [url, setUrl] = useState(null);
  const handleImageDeleteFood = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);
    // console.log(selectedImageAmazingDish);
    console.log(selectedImage);
    setSelectedImage(updatedImages);
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    setSelectedImage([...selectedImage, ...fileList]);
  };

  const imagePromise = ImageUploader("Admin", "Food/", selectedImage).then(
    (data) => {
      if (data.status === "Success") {
        //  FoodData.foodImage = data.images;
        // dispatch(setLoading(false));
        // setUrl(data.images[0].orginalImageUrl);
        // console.log(url);
      }
    }
  );
  return (
    <div>
      <div
        className="row justify-content-center mt-3 mb-5 "
        // style={{ marginLeft: "30%" }}
      >
        <div className="col-12">
          {/* <h3>{labels.foodPhoto.bn}</h3> */}
          <div className="row gap-2">
            {/* <div className=' '> */}
            {selectedImage.length <= 6 ? (
              <>
                {" "}
                {[...Array(6)].map((item, index) => (
                  <div className="col">
                    <div className="image-box col justify-content-center align-items-center">
                      {selectedImage[index] && selectedImage.length > 0 && (
                        <div className="img">
                          <img
                            alt=""
                            className="img-fluid"
                            src={URL.createObjectURL(selectedImage[index])}
                          />
                          <div className="img-delete">
                            <AiFillDelete
                              size={20}
                              color="red"
                              onClick={() => handleImageDeleteFood(index)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {selectedImage.map((item, index) => (
                  <div className="col">
                    <div className="image-box d-flex justify-content-center align-items-center">
                      {selectedImage[index] && selectedImage.length > 0 && (
                        <div className="img">
                          <img
                            alt=""
                            // className="img-fluid"
                            src={URL.createObjectURL(selectedImage[index])}
                          />
                          <div className="img-delete">
                            <AiFillDelete
                              size={20}
                              color="red"
                              onClick={() => handleImageDeleteFood(index)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {/* </div> */}
          </div>
        </div>

        <div>
          {selectedImage.length < 5 ? (
            <>
              {Array(5).map((item) => (
                <staticDesign />
              ))}
            </>
          ) : (
            ""
          )}
        </div>
        {/* {selectedImage && (
                <div>
                  {selectedImage?.map((item, index) => {
                    console.log(item);
                    return (
                      <div>
                        <img
                          alt='not found'
                          width={"80px"}
                          src={URL.createObjectURL(item)}
                        />
                        <AiFillDelete
                          onClick={() => handleImageDeleteFood(index)}
                        />
                      </div>
                    );
                  })}
                  <br />
                </div>
              )} */}

        <br />
        <br />

        <div id="form-file-upload">
          <input
            type="file"
            id="input-file-upload"
            multiple
            onChange={handleFileChange}
            // onChange={(event) => {
            //   console.log(event.target.files[0]);
            //   setSelectedImage(Array.from(event.target.files));
            // }}
          />
          <label id="label-file-upload" htmlFor="input-file-upload">
            <div>
              <p>Drag and drop your file here or</p>
              <span className="upload-button">Upload a file</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};
