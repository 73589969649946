import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import getTranslation from "../../../Helper/getTranslationUtility";

import labels from "../../../translationData/editProfile.json";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Base_Url } from "../../../misc";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Swal from "sweetalert2";
import { PhotoProvider, PhotoView } from "react-photo-view";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";
import { toast } from "react-toastify";

const kitchenTab = labels.userDashboard.editProfile.kitchenTab;
const amazingPhotosTab = labels.userDashboard.editProfile.amazingPhotosTab;
const experimentalPhotosTab =
  labels.userDashboard.editProfile.experimentalPhotosTab;
const otherFoodSkillsTab = labels.userDashboard.editProfile.otherFoodSkillsTab;
const myRestrictionTab = labels.userDashboard.editProfile.myRestrictionTab;
const bankTab = labels.userDashboard.editProfile.bankTab;
const mobileBankingTab = labels.userDashboard.editProfile.mobileBankingTab;
const identityTab = labels.userDashboard.editProfile.identityTab;
const kitchenPhotosVideosTab =
  labels.userDashboard.editProfile.kitchenPhotosVideosTab;
const basicInfoTab = labels.userDashboard.editProfile.basicInfoTab;

const token = localStorage.getItem("Token");
console.log(token);
const BuyerEdit = () => {
  // declare all state
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedImage(fileList);
  };
  const [BuyerData, setBuyerData] = useState({});
  const [CountryData, setCountryData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [RegionData, setRegionData] = useState([]);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [selectedCountryAd, setSelectedCountryAd] = useState("");
  const [selectedRegionAd, setSelectedRegionAd] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const basicInfoTabTitle = getTranslation(
    "tabTitle",
    currentLanguage,
    basicInfoTab
  );

  const handleMapLoad = (event) => {
    if (
      BuyerData &&
      BuyerData.coordinate &&
      BuyerData.coordinate.latitude &&
      BuyerData.coordinate.longitude
    ) {
      setCoordinates({
        lat: BuyerData.coordinate.latitude,
        lng: BuyerData.coordinate.longitude,
      });
    }
  };

  console.log("Mash" + BuyerData);

  const handleDrag = (event) => {
    setCoordinates({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setCoordinates({
      lat: ll.lat,
      lng: ll.lng,
    });
    setUserAddress(value);
  };
  const searchOptions = {
    componentRestrictions: { country: ["BD"] },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setBuyerData((prev) => {
      const data = { ...prev, [name]: value };
      return data;
    });
  };

  const handleCountryChangeAdr = (event) => {
    const newValue = event.target.value;

    setSelectedCountryAd(newValue);
  };
  const handleRegionChangeAdr = (event) => {
    const value = event.target.value;
    setSelectedRegionAd(value);
  };
  const params = useParams();
  const { register, handleSubmit, reset, getValues, setValue } = useForm();

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}/get-single-users/${params.id}`
        );
        if (response.data.status === "Success") {
          setBuyerData(response.data.data[0]);
          reset({ ...response.data.data[0] });
          setCoordinates({
            lat: parseFloat(response.data.data[0].coordinate.latitude),
            lng: parseFloat(response.data.data[0].coordinate.longitude),
          });
          setUserAddress(response.data.data[0].address);
        }
      } catch (e) {}
    };

    const getCountry = async () => {
      try {
        const countryResponse = await fetch(`${Base_Url}/get-country`);
        const countryData = await countryResponse.json();
        console.log(countryData);
        setCountryData(countryData.data);
      } catch (e) {}
    };
    const getRegion = async () => {
      try {
        const regionResponse = await fetch(`${Base_Url}/get-region-state`);
        const regionData = await regionResponse.json();
        console.log(regionData);
        setRegionData(regionData.data);
      } catch (e) {}
    };
    const getCity = async () => {
      try {
        const cityResponse = await fetch(`${Base_Url}/get-city`);
        const cityData = await cityResponse.json();
        console.log(cityData);
        setCityData(cityData.data);
      } catch (e) {}
    };

    getUserDetails();
    getCountry();
    getCity();
    getRegion();
  }, [params.id]);

  console.log(getValues(), coordinates);
  const handleBecomeSeller = async () => {
    const formData = new FormData();

    formData.append("folder", "Admin/Buyer"); // folder name
    selectedImage?.forEach((file) => {
      formData.append("cover[]", file);
    });

    fetch(`${Base_Url}/imguploads`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then(async function (data) {
        console.log(data, "ll");
        let subdata = {
          ...getValues(),
          address: userAddress,
          userProfilePhoto: data?.images[0],
        };

        if (!isFileUploading) {
          await axios
            .post(`${Base_Url}/update-users/${params.id}`, subdata)
            .then((res) => {
              console.log(subdata);
              if (res.status === 200) {
                toast.success("Buyer Updated", {
                  position: "bottom-center",
                  autoClose: 5000,
                  // hideProgressBar: false,
                  // closeOnClick: true,
                  // pauseOnHover: true,
                  // draggable: true,
                  // progress: undefined,
                  // theme: "light",
                });
                //   navigate("/recipe-category-list");
              }
            })
            .catch((err) => {
              console.log(err);
              toast.error("❌ Error Notify !", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      });

    // const data = {
    //   ...getValues(),
    //   address: userAddress,
    // };
    // console.log(data);
    // const response = await axios.post(
    //   `${Base_Url}//update-users/${params.id}`,
    //   data
    // );
    // if (
    //   response.data.status === "Success" &&
    //   response.data.data.acknowledged === true
    // ) {
    //   Swal.fire({
    //     title: "Updated SuccessFul!",

    //     icon: "success",
    //   });
    // }
  };

  console.log(BuyerData)
  return (
    <div>
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-body">
              <div className="border rounded p-4 mb-4">
                <h3 className="mb-4">{basicInfoTabTitle}</h3>
              </div>

              <p className="fs-18">
                {" "}
                {getTranslation("profilePhoto", currentLanguage, basicInfoTab)}
              </p>
              
              <div className="setting-img d-flex align-items-center mb-4">
                <div className="avatar-upload d-flex align-items-center">
                  <div className=" change position-relative d-flex">
                    <div className="avatar-preview">
                      <PhotoProvider>
                        <div className="m-3 rounded">
                          <PhotoView
                            src={
                              BuyerData.userProfilePhoto &&
                              BuyerData.userProfilePhoto[0] &&
                              BuyerData.userProfilePhoto[0].orginalImageUrl
                            }
                          >
                            <img
                              style={{
                                height: "100px",
                                width: "100px",
                              }}
                              src={
                                BuyerData.userProfilePhoto &&
                                BuyerData.userProfilePhoto[0] &&
                                BuyerData.userProfilePhoto[0].orginalImageUrl
                              }
                              alt=""
                            />
                          </PhotoView>
                        </div>
                      </PhotoProvider>
                    </div>
                    <div className="change-btn d-flex align-items-center flex-wrap">
                      {/* <input type='file' onChange={fileHandler} className="form-control" id="imageUpload" accept=".png, .jpg, .jpeg" /> */}
                      {/* <label htmlFor='imageUpload' className='dlab-upload'>
                        Choose File
                      </label> */}
                      {/* <Link to={"#"} className="btn remove-img ms-2" onClick={() => RemoveImage()}>  {getTranslation('profilePhotoRemove', currentLanguage,basicInfoTab)}   </Link> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row d-flex">
                <div className="col-xl-7 mb-3">
                  <form id="form-file-upload">
                    <input
                      type="file"
                      id="input-file-upload"
                      // multiple
                      onChange={handleFileChange}
                    />
                    <label id="label-file-upload" htmlFor="input-file-upload">
                      <div>
                        <p>Drag and drop your file here or</p>
                        <span className="upload-button">Upload a file</span>
                      </div>
                    </label>
                  </form>
                </div>
                <div className="col-xl-3 mb-3">
                  {selectedImage && (
                    <div>
                      {selectedImage?.map((item, index) => {
                        return (
                          <>
                            <img
                              className="img-fluid"
                              alt="Category Image"
                              width={""}
                              src={URL.createObjectURL(item)}
                            />
                            <br />
                            <Button
                              style={{ marginLeft: 25, marginTop: 15 }}
                              onClick={() => setSelectedImage(null)}
                            >
                              Remove
                            </Button>
                          </>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="setting-input">
                    <label htmlFor="exampleInputtext" className="form-label">
                      {getTranslation(
                        "userName",
                        currentLanguage,
                        basicInfoTab
                      )}{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={getTranslation(
                        "userName",
                        currentLanguage,
                        basicInfoTab
                      )}
                      name="userFullName"
                      {...register("userFullName", {
                        required: true,
                        onChange: handleInputChange,
                      })}

                      // defaultValue={BuyerData}
                    />
                  </div>
                  <div className="setting-input">
                    <label htmlFor="exampleInputtext" className="form-label">
                      {getTranslation(
                        "userEmailAddress",
                        currentLanguage,
                        basicInfoTab
                      )}{" "}
                    </label>
                    <input
                      type="email"
                      className="form-control input-default "
                      placeholder={getTranslation(
                        "userEmailAddress",
                        currentLanguage,
                        basicInfoTab
                      )}
                      name="email"
                      {...register("email", {
                        required: true,
                        onChange: handleInputChange,
                      })}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="setting-input">
                    <div className="setting-input">
                      <label htmlFor="exampleInputtext" className="form-label">
                        {getTranslation(
                          "userMobileNo",
                          currentLanguage,
                          basicInfoTab
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder="Mobile Number"
                        name="userMobileNo"
                        {...register("userMobileNo", {
                          required: true,
                          onChange: handleInputChange,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5>
                    {getTranslation(
                      "userAddressHeading",
                      currentLanguage,
                      basicInfoTab
                    )}{" "}
                  </h5>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("country", currentLanguage, basicInfoTab)}{" "}
                  </label>
                  <select
                    name="countryAddress"
                    className="form-control input-default"
                    {...register("countryID", {
                      required: true,
                      onChange: (e) => {
                        handleCountryChangeAdr(e);
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option>
                      {getTranslation("country", currentLanguage, basicInfoTab)}{" "}
                    </option>
                    {/* {console.log(CountryData, "checked")} */}
                    {CountryData &&
                      CountryData?.map((item) => {
                        // console.log(
                        //   BuyerData?.countryID === item?._id,
                        //   "checked",
                        //   BuyerData?.countryID,
                        //   item?._id
                        // );
                        if (BuyerData && BuyerData?.countryID === item?._id) {
                          return (
                            <option selected value={item._id}>
                              {console.log(
                                BuyerData?.countryID,
                                "checked",
                                BuyerData?.countryID === item?._id
                              )}
                              {item.countryName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item._id}>{item.countryName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("region", currentLanguage, basicInfoTab)}{" "}
                  </label>
                  <select
                    className="form-control input-default"
                    {...register("regionID", {
                      required: true,
                      onChange: (e) => {
                        handleRegionChangeAdr(e);
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option>
                      {getTranslation("region", currentLanguage, basicInfoTab)}{" "}
                    </option>
                    {console.log(BuyerData?.regionID, "hi its bijon")}
                    {RegionData &&
                      RegionData.map((item) => {
                        if (BuyerData && BuyerData?.regionID === item?._id) {
                          return (
                            <option selected value={item._id}>
                              {item.regionName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item._id}>{item.regionName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("city", currentLanguage, basicInfoTab)}{" "}
                  </label>
                  <select
                    className="form-control input-default"
                    {...register("cityID", {
                      required: true,
                      onChange: (e) => {
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option value="">
                      {getTranslation("city", currentLanguage, basicInfoTab)}{" "}
                    </option>
                    {CityData?.map((item) => {
                      if (BuyerData && BuyerData.cityID === item._id) {
                        return (
                          <option selected value={item._id}>
                            {item.cityName}
                          </option>
                        );
                      } else {
                        return (
                          <option value={item._id}>{item.cityName}</option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>

              {/* Map &  Address Section Start */}
              <div className="mt-3">
                <div className="form-group mb-3 row ">
                  <div className="col-12">
                    <label>
                      {getTranslation(
                        "pointLocation",
                        currentLanguage,
                        basicInfoTab
                      )}{" "}
                    </label>
                    <PlacesAutocomplete
                      searchOptions={searchOptions}
                      value={userAddress || ""}
                      onChange={setUserAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div style={{ position: "relative" }}>
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className:
                                "form-control input-default location-search-input",
                            })}
                          />
                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              width: "100%",
                              border: "1px solid #e7e7e7",
                            }}
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#1E466A",
                                    color: "white",
                                    cursor: "pointer",
                                    padding: "10px",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: "10px",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <GoogleMap
                  mapContainerStyle={{ height: "200px", width: "100%" }}
                  //onDragEnd={handleDrag}
                  center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                  zoom={20}
                  onLoad={handleMapLoad}
                >
                  <Marker
                    position={coordinates}
                    draggable
                    onDragEnd={handleDrag}
                  />
                </GoogleMap>
              </div>
              <div className="col-12 mt-3">
                <Button type="submit" className="w-100">
                  {getTranslation("saveButton", currentLanguage, basicInfoTab)}{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BuyerEdit;
