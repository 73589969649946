import React, { useState } from "react";

const MyPagination = ({ currentPage, totalPages, paginate }) => {
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const showPageNumber = (pageNumber) => {
    if (pageNumber === currentPage) {
      return (
        <button
          key={pageNumber}
          className="previous-button"
          style={{ backgroundColor: "#FC8019", color: "white" }}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      );
    } else if (pageNumber > 0 && pageNumber <= totalPages) {
      return (
        <button
          key={pageNumber}
          className="previous-button mt-2"
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      );
    }
    return null;
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (currentPage > endPage) {
      setStartPage(startPage + 10);
      setEndPage(endPage + 10);
    }
    if (currentPage < startPage) {
      setStartPage(startPage - 10);
      setEndPage(endPage - 10);
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(showPageNumber(i));
    }
    return pageNumbers;
  };

  return (
    <div className="text-center mb-3">
      <div className="filter-pagination mt-3">
        <button className="previous-button" onClick={() => paginate(1)}>
          {"<<"}
        </button>
        <button
          className="previous-button"
          onClick={() => {
            if (currentPage > 1) paginate(currentPage - 1);
          }}
        >
          Previous
        </button>
        {renderPageNumbers()}
        <button
          className="next-button"
          onClick={() => {
            if (currentPage < totalPages) paginate(currentPage + 1);
          }}
        >
          Next
        </button>
        <button className="next-button" onClick={() => paginate(totalPages)}>
          {">>"}
        </button>
      </div>
    </div>
  );
};

export default MyPagination;
