import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
const FoodManagement = () => {
  const [subcategoryList, setSubCategoryList] = useState();

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-food`)
      .then((res) => {
        setSubCategoryList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [subcategoryList]);

  const handleDelete = (data) => {
    axios
      .delete(`${Base_Url}/delete-food/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <div className='card h-auto' style={{ marginTop: 10 }}>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {subcategoryList === undefined ? (
              ""
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th>foodImage</th>
                    <th>foodName</th>
                    <th>foodSlug</th>
                    <th>foodPrice</th>
                    <th>status</th>

                    <th>Action</th>
                    <th className='bg-none'></th>
                    <th className='bg-none'></th>
                  </tr>
                </thead>
                <tbody>
                  {subcategoryList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <img src={item.foodImage} />
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item.foodName}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>Food Slug</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item.foodPrice}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>
                                {item.status === true ? "Active" : "InActive"}
                              </h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to='#'
                              state={item._id}
                              className='btn btn-outline-primary'
                            >
                              Edit
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={"#"}
                              onClick={() => {
                                // handleDelete(item._id);
                                del(item._id);
                              }}
                              className='btn btn-outline-primary'
                            >
                              Delete
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FoodManagement;
