import React from "react";
import { useNavigate } from "react-router-dom";

const CustomCard = ({ title, subTitle, count, onClickAction }) => {
  const navigate = useNavigate();
  return (
    <div
      className="col-lg-4 col-md-6"
      onClick={() => {
        navigate(onClickAction);
      }}
    >
      <div className="card dlab-bg dlab-position">
        <div className="card-header border-0 pb-0">
          <h4 className="cate-title">{title}</h4>
        </div>
        <div className="card-body pt-0 pb-2">
          <div className="card bg-primary blance-card">
            <div className="card-body">
              <h4 className="mb-0">{subTitle}</h4>
              <h2>{count}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
