import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";
import axios from "axios";
import { useEffect } from "react";
import { Base_Url } from "../../../misc";
const CreateOrderStatus = () => {
  const [data, setData] = useState({
    categoryName: "",
    categorySlug: "",
  });

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState();
  const [orderstatus, setOrderstatus] = useState([]);

  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  const submit = () => {
    let subdata = {
      orderStatusTitle: data.categoryName,
      status: currentFruit,
    };

    axios
      .post(`${Base_Url}/create-orderStatus`, subdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-orderStatus`)
      .then((res) => {
        setOrderstatus(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderstatus]);

  const handleDelete = (data) => {
    axios
      .delete(`${Base_Url}/delete-orderStatus/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <ToastContainer />{" "}
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Enter Detials of New Order Status</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-group mb-3'>
                    <label>Order Status Title</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Order Status Title'
                      label='Name:'
                      name='categoryName'
                      value={data.categoryName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='row d-flex'>
                    <div className='form-group mb-3 col-md-4'>
                      <label>Status</label>
                      <select
                        defaultValue={"option"}
                        onChange={(event) => changeFruit(event.target.value)}
                        value={currentFruit}
                        id='inputState'
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Choose Status
                        </option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          submit();
        }}
      >
        {" "}
        Save
      </Button>
      <div className='card h-auto' style={{ marginTop: 10 }}>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {orderstatus === undefined ? (
              ""
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th>sizeUnitName</th>
                    <th>status</th>
                    <th>Action</th>
                    <th className='bg-none'></th>
                    <th className='bg-none'></th>
                  </tr>
                </thead>
                <tbody>
                  {orderstatus.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>{item.orderStatusTitle}</h5>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <h5 className='mb-0'>
                                {item.status === true ? "Active" : "Inactive"}
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to='/EditOrderStatus'
                              state={item}
                              className='btn btn-outline-primary'
                            >
                              Edit
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={"#"}
                              onClick={() => {
                                // handleDelete(item._id);
                                del(item._id);
                              }}
                              className='btn btn-outline-primary'
                            >
                              Delete
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrderStatus;
