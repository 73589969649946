import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "./upload.css";
import axios from "axios";
import { useEffect } from "react";
import { Base_Url } from "../../../misc";

const CreateFood = () => {
  // eslint-disable-next-line no-use-before-define
  // const Base_Url = Base_Url;
  const [data, setData] = useState({
    name: "",
    Slug: "",
    price: "",
    quantity: "",
    discountprice: "",
    foodDiscountPercentage: "",
    startdate: "",
    enddate: "",
    portionsize: "",
    minimumquantity: "",
  });

  const [disable, setdisable] = useState(true);
  const [currentFruit, setCurrentFruit] = useState("option");
  const [currentPortion, setCurrentPortion] = useState();
  const [catID, setCatID] = useState([]);
  const [cat, setcat] = useState();
  const [portionId, setPortionId] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.name !== "" &&
    newContact.Slug !== "" &&
    newContact.quantity !== "" &&
    newContact.price !== "" &&
    newContact.discountprice !== "" &&
    newContact.foodDiscountPercentage !== "" &&
    newContact.startdate !== "" &&
    newContact.enddate !== "" &&
    newContact.portionsize !== "" &&
    newContact.minimumquantity !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };

  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };
  const [taq, setAdditionalTaq] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-additionaltags`)
      .then((res) => {
        setAdditionalTaq(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, []);

  const getCat = (e) => {
    axios
      .get(`${Base_Url}/get-category`)
      .then((res) => setCatID(res.data.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const getPortion = (e) => {
    axios
      .get(`{Base_Url}/get-portionSizeUnit`)
      .then((res) => setPortionId(res.data.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubcat = (e) => {
    axios
      .get(`${Base_Url}/get-sub-category-by-single-category/${catID}`)
      .then((res) => console.log(res.data.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const Fcat = (newcat) => {
    console.log(newcat);
    setcat(newcat);
  };
  console.log(cat);
  const [Portion, setPortion] = useState();
  const FPortion = (p) => {
    setPortion(p);
  };
  const changePortion = (newPortion) => {
    setCurrentPortion(newPortion);
  };

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const [Tag, setTag] = useState([]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setTag((oldArray) => [...oldArray, e.target.value]);
    } else {
      removeCities(e);
      console.log(Tag);
    }
  };
  const removeCities = (e) => {
    setTag([...Tag.filter((Tag) => Tag !== e.target.value)]);
  };

  const submit = () => {
    let fdata = {
      foodName: data.name,
      foodSlug: data.Slug,
      foodQty: data.quantity,
      foodPrice: data.price,
      sellerID: Seller?.sellerIDInfo[0]._id,
      foodDiscountPrice: data.discountprice,
      foodDiscountPercentage: data.foodDiscountPercentage,
      foodDiscountStartDate: data.startdate,
      foodDiscountEndDate: data.enddate,
      foodPortionSize: data.portionsize,
      foodAdditionalTags: [Tag],
    };
    axios
      .post(`${Base_Url}/create-food`, fdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Created", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    // set the state with the array of files
    setSelectedImage(fileList);
  };

  const handleSubmit = () => {
    // e.preventDefault();

    const formData = new FormData();

    formData.append("folder", "Food/" + Seller?.sellerIDInfo[0]._id); // folder name
    selectedImage.forEach((file) => {
      formData.append("cover[]", file);
    });

    fetch(Base_Url/ + "imguploads", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          submit();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Enter Detials of New Food</h4>
            </div>
            <div className='card-body'>
              <div className='basic-form'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-group mb-3'>
                    <label>Food Name</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Food Name'
                      label='Name:'
                      name='name'
                      value={data.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='form-group mb-3'>
                    <label>Food Slug</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Food Slug'
                      label='Slug:'
                      name='Slug'
                      value={data.Slug}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='form-group mb-3'>
                    <label>Food Quantity</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Food Quantity'
                      label='quantity:'
                      name='quantity'
                      value={data.quantity}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className=' justify-content-center mt-3 mb-5'
                    style={{ marginLeft: "30%" }}
                  >
                    {selectedImage && (
                      <div>
                        {selectedImage.map((item, index) => {
                          console.log(item);
                          return (
                            <img
                              alt='not found'
                              width={"80px"}
                              src={URL.createObjectURL(item)}
                            />
                          );
                        })}
                        <br />
                        <Button
                          style={{ marginLeft: 25 }}
                          onClick={() => setSelectedImage(null)}
                        >
                          Remove
                        </Button>
                      </div>
                    )}

                    <br />
                    <br />

                    <form id='form-file-upload'>
                      <input
                        type='file'
                        id='input-file-upload'
                        multiple
                        onChange={handleFileChange}
                        // onChange={(event) => {
                        //   console.log(event.target.files[0]);
                        //   setSelectedImage(Array.from(event.target.files));
                        // }}
                      />
                      <label id='label-file-upload' htmlFor='input-file-upload'>
                        <div>
                          <p>Drag and drop your file here or</p>
                          <button className='upload-button'>
                            Upload a file
                          </button>
                        </div>
                      </label>
                    </form>
                  </div>

                  <div className='d-flex justify-content-center gap-3'>
                    <div className='form-group mb-3 col-md-4'>
                      <label>Category </label>
                      <select
                        defaultValue={"option"}
                        onClick={getCat}
                        onChange={(event) => Fcat(event.target.value)}
                        value={changeFruit}
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Choose Status
                        </option>

                        {catID.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.categoryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className='form-group mb-3 col-md-4'>
                      <label> Select Portion </label>
                      <select
                        defaultValue={"option"}
                        onClick={getPortion}
                        onChange={(event) => FPortion(event.target.value)}
                        value={changePortion}
                        className='form-control'
                      >
                        <option value='option' disabled>
                          Choose Status
                        </option>

                        {portionId.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.sizeUnitName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className='form-group mb-3 col-md-4'>
                    <label>SubCategory </label>
                    <select
                      defaultValue={"option"}
                      onClick={getSubcat}
                      onChange={(event) => Fcat(event.target.value)}
                      value={changeFruit}
                      className='form-control'
                    >
                      <option value='option' disabled>
                        Choose Status
                      </option>

                      {subcat.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {item.categoryName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className='form-group mb-3'>
                    <label>Food Price</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Food Price'
                      label='price:'
                      name='price'
                      value={data.price}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='form-group mb-3'>
                    <label>Food Discount Price</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Food Discount Price'
                      label='discountprice:'
                      name='discountprice'
                      value={data.discountprice}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='form-group mb-3'>
                    <label>Food Discount Percentage</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Food Discount Percentage'
                      label='foodDiscountPercentage:'
                      name='foodDiscountPercentage'
                      value={data.foodDiscountPercentage}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='Col-12 d-flex gap-3'>
                    <div className=' form-group mb-3'>
                      <label>Food Discount Start Date</label>
                      <input
                        type='date'
                        className='form-control input-default '
                        placeholder='Food Discount Percentage'
                        label='startdate:'
                        name='startdate'
                        value={data.startdate}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='  form-group mb-3'>
                      <label>Food Discount End Date</label>
                      <input
                        type='date'
                        className='form-control input-default '
                        placeholder='Food Discount Percentage'
                        label='enddate:'
                        name='enddate'
                        value={data.enddate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='form-group mb-3'>
                    <label>Food Minimum Order Quantity</label>
                    <input
                      type='text'
                      className='form-control input-default '
                      placeholder='Food Minimum Order Quantity'
                      label='minimumquantity:'
                      name='minimumquantity'
                      value={data.minimumquantity}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <h3>Additonal Tag</h3>
                    <div
                      className='d-flex '
                      style={{ display: "inline-block" }}
                    >
                      {taq.map((item, index) => {
                        return (
                          <span key={index}>
                            {item.status === true ? (
                              <span className='form-check custom-checkbox mb-3 ms-3 checkbox-info'>
                                <input
                                  onChange={(e) => {
                                    handleCheck(e);
                                  }}
                                  type='checkbox'
                                  value={item.tagName}
                                  className='form-check-input'
                                  id='customCheckBox2'
                                  required
                                />

                                <label
                                  className='form-check-label'
                                  htmlFor='customCheckBox2'
                                >
                                  {item.tagName}
                                </label>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{ marginLeft: 25 }}
        onClick={() => {
          // submit();
          handleSubmit();
        }}
      >
        {" "}
        Save
      </Button>
    </>
  );
};
export default CreateFood;
