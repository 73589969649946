import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Pagination } from "react-bootstrap";
import MyPagination from "../Pagination/MyPagination";

function dateFormat(value) {
  let dateData = new Date(value);
  // Format the date as dd/mm/yyyy
  const formattedDate = dateData.toLocaleDateString("en-GB"); // 'en-GB' for the dd/mm/yyyy format
  return formattedDate;
}
const HOW_DO_WE_DO = () => {
  const [category, setCategory] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [PAGE_SIZE, setPAGE_SIZE] = useState(5);
  // Filtering Logic
  useEffect(() => {
    let filtered = category;

    if (searchTerm.trim() !== "") {
      filtered = filtered.filter((item) => {
        let details = item?.Details?.slice(0, 15);
        return details?.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    setFilteredData(filtered);
  }, [searchTerm, category]);

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  useEffect(() => {
    //chackboxFun()

    axios
      .get(`${Base_Url}/get-how-do-we-do`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (data) => {
    axios
      .delete(`${Base_Url}/delete-how-do-we-do/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  console.log(category);

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  // Pagination Logic
  const itemsPerPage = PAGE_SIZE;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (
    filteredData?.length > 0 ? filteredData : category
  )?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData?.length > 0 ? filteredData?.length : category?.length) /
      PAGE_SIZE
  );
  return (
    <>
      <div
        className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="input-group search-area2 style-1 w-[75%] border d-flex"
          style={{ marginRight: "15px" }}
        >
          <span className="input-group-text p-0">
            <Link to={"#"}>
              <svg
                className="me-1"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                  fill="#FC8019"
                />
              </svg>
            </Link>
          </span>

          <input
            type="text"
            className="form-control p-2 "
            placeholder="Filter by how do we do"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="input-group ml-2 pl-2 style-1 w-50">
          {/* Search filter */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          />
        </div>
      </div>
      <div className="card h-auto">
        <div className="card-body p-0">
          <div className="table-responsive">
            {category === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    {/*  <th className='bg-none sorting_asc'>
                       <div className='form-check style-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='checkAll'
                          onClick={() => chackboxFun("all")}
                        />
                      </div> 
                    </th>*/}
                    <th>ID.</th>
                    <th>Title</th>
                    <th>Posted By</th>
                    <th>Video </th>
                    <th>Before</th>
                    <th>After </th>
                    <th>Date</th>
                    <th className="bg-none"></th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <p>{item?.id}</p>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <p>{item?.title}</p>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <p>
                              {item?.postedBy?.userFullName}
                              <br />
                              {item?.postedBy?.kitchenName}
                            </p>

                            <div> </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <span className="badge badge-primary badge-pill ">
                              <Link
                                className="text-white"
                                to={item.VideoURL}
                                target="_blank"
                              >
                                <i className="fa-solid fa-video"></i>
                              </Link>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={
                                item.beforeImage[0]?.orginalImageUrl ||
                                item.beforeImage
                              }
                              alt="images"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={
                                item.afterImage[0]?.orginalImageUrl ||
                                item.afterImage
                              }
                              alt="images"
                            />
                          </div>
                        </td>
                        <td>{dateFormat(item.createdDate)}</td>
                        <td>
                          <div>
                            <h4 className="text-primary">{item.role}</h4>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <Link
                              to={`/HowdowedoEdit/${item?._id}`}
                              state={item}
                            >
                              <div
                                style={{
                                  backgroundColor: "#d97706",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <AiFillEdit size={18} color="white" />
                              </div>
                            </Link>
                            <div
                              onClick={() => {
                                del(item._id);
                              }}
                              style={{
                                backgroundColor: "#db2777",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <AiFillDelete size={18} color="white" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HOW_DO_WE_DO;
