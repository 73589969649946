import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Card, Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
const EditCountry = () => {
  const location = useLocation();
  const item = location.state;
  const [currentFruit, setCurrentFruit] = useState();

  const [data, setData] = useState({
    countryName: item.countryName,
    countryFlag: item.countryFlag,
    countryCode: item.countryCode,
    countryPhoneCode: item.countryPhoneCode,
    countryCapital: item.countryCapital,
  });

  const [disable, setdisable] = useState(true);

  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    setData(newContact);
  };

  const submit = () => {
    let subdata = {
      orderStatusTitle: data.categoryName,
      countryName: data.countryName,
      countryFlag: data.countryFlag,
      countryCode: data.countryCode,
      countryPhoneCode: data.countryPhoneCode,
      countryCapital: data.countryCapital,
      status: currentFruit,
    };

    axios
      .post(`${Base_Url}/update-country/${item._id}`, subdata)
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("Subcategory Created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.data.data.keyPattern.categorySlug === 1) {
          toast.error("Slug Should be unique", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeFruit = (newFruit) => {
    setCurrentFruit(newFruit);
  };

  return (
    <>
      {" "}
      <Row>
        <Card>
          <Card.Title>Update Country Detials</Card.Title>
          <div className='form-group mb-3'>
            <label>Country Name</label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Name'
              label='Name:'
              name='countryName'
              defaultValue={item?.countryName}
              onChange={handleChange}
            />
          </div>

          <div className='form-group mb-3'>
            <label>Country Flag</label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Flag'
              label='Name:'
              name='countryFlag'
              defaultValue={item.countryFlag}
              onChange={handleChange}
            />
          </div>
          <div className='form-group mb-3'>
            <label>Country Code</label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Code '
              label='Name:'
              name='countryCode'
              defaultValue={item.countryCode}
              onChange={handleChange}
            />
          </div>

          <div className='form-group mb-3'>
            <label>Country Phone Code </label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Phone Code'
              label='Name:'
              name='countryPhoneCode'
              defaultValue={item.countryPhoneCode}
              onChange={handleChange}
            />
          </div>
          <div className='form-group mb-3'>
            <label>Country Capital </label>
            <input
              type='text'
              className='form-control input-default '
              placeholder='Country Phone Code'
              label='Name:'
              name='countryCapital'
              defaultValue={item.countryCapital}
              onChange={handleChange}
            />
          </div>
          <div className='row d-flex'>
            <div className='form-group mb-3 col-md-4'>
              <label>Status</label>
              <select
                defaultValue={item?.status}
                onChange={(event) => changeFruit(event.target.value)}
                // value={currentFruit}
                id='inputState'
                className='form-control'
              >
                <option value='option' disabled>
                  Choose Status
                </option>
                <option>true</option>
                <option>false</option>
              </select>
            </div>
          </div>
        </Card>
      </Row>
      <Button
        onClick={() => {
          submit();
        }}
      >
        Update
      </Button>
    </>
  );
};

export default EditCountry;
