import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Logo from "./../../../images/ownfood-logo.png";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import {
//     useAllCountryQuery,
//     useGetCityByRegionQuery,
//     useGetRegionByCountryQuery,
// } from "../../../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
import { Base_Url } from "../../../misc";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import noImage from "../../../images/no-img-avatar.png";
import { AiFillEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
import { BasicInfo } from "./SellerPageComponent/BasicInfo";
import { KitchenInfo } from "./SellerPageComponent/KitchenInfo";

const kitchenTab = labels.userDashboard.editProfile.kitchenTab;
const amazingPhotosTab = labels.userDashboard.editProfile.amazingPhotosTab;
const experimentalPhotosTab =
  labels.userDashboard.editProfile.experimentalPhotosTab;
const otherFoodSkillsTab = labels.userDashboard.editProfile.otherFoodSkillsTab;
const myRestrictionTab = labels.userDashboard.editProfile.myRestrictionTab;
const bankTab = labels.userDashboard.editProfile.bankTab;
const mobileBankingTab = labels.userDashboard.editProfile.mobileBankingTab;
const identityTab = labels.userDashboard.editProfile.identityTab;
const kitchenPhotosVideosTab =
  labels.userDashboard.editProfile.kitchenPhotosVideosTab;
const basicInfoTab = labels.userDashboard.editProfile.basicInfoTab;
const SellerPage = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const basicInfoTabTitle = getTranslation(
    "tabTitle",
    currentLanguage,
    basicInfoTab
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCq3_f1JedG3GCgWnXgy3yuji4tjMZvitk",
  });
  const location = useLocation();
  const params = useParams();
  //const item = location.state;
  const [item, setItem] = useState([]);
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);
  const [city, setCity] = useState([]);
  const [sellerData, setSellerData] = useState();

  const [update, setUpdate] = useState("");
  const [show, setShow] = useState(false);
  const handleUpdateDataChange = (event) => {
    setUpdate(event.target.value);
  };

  //console.log(item?.userData[0]?._id);
  const submitUserUpdate = async (name, value) => {
    const data = {};
    data[name] = value;
    let id = item?.userData[0]?._id;
    console.log(id);
    setShow(false);
    try {
      await axios
        .post(`${Base_Url}/update-users/${id}`, data)
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };
  const submitSellerUpdate = async (name, value) => {
    const data = {};
    data[name] = value;
    setShow(false);
    try {
      await axios
        .post(`${Base_Url}/update-become-seller/${params.id}`, data)
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };

  const submitBankDetailsUpdate = async (name, value) => {
    const data = {};
    data[name] = value;
    setShow(false);
    try {
      await axios
        .post(`${Base_Url}/update-become-seller/${params.id}`, data)
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };
  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const sellerResponse = await fetch(
          `${Base_Url}/get-single-become-seller/${params.id}`
        );
        const sellerData = await sellerResponse.json();
        setItem(sellerData.data[0]);
        console.log(sellerData);
      } catch (error) {}
    };
    const fetchCountryData = async () => {
      try {
        const countryResponse = await fetch(`${Base_Url}/get-country`);
        const countryData = await countryResponse.json();
        console.log(countryData);
        setCountry(countryData.data);
      } catch (error) {
        // Handle error
      }
    };

    const fetchRegionData = async () => {
      try {
        const regionResponse = await fetch(`${Base_Url}/get-region-state`);
        const regionData = await regionResponse.json();
        console.log(regionData);
        setRegion(regionData.data);
      } catch (error) {
        // Handle error
      }
    };

    const fetchCityData = async () => {
      try {
        const cityResponse = await fetch(`${Base_Url}/get-city`);
        const cityData = await cityResponse.json();
        //  console.log(cityData);
        setCity(cityData.data);
      } catch (error) {
        // Handle error
      }
    };

    // Call the API functions
    fetchCountryData();
    fetchRegionData();
    fetchCityData();
    fetchSellerData();
  }, []);
  //console.log("country", country);
  const updateSellerData = async (name, value) => {
    try {
      await axios
        .post(`${Base_Url}/update-become-seller/${params.id}`, { name, value })
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            icon: "success",
            title: "Seller data updated successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // Handle error
    }
  };
  console.log(item);

  return (
    <>
      <div>
        <div className="card-body border p-4 rounded">
          <div className="border p-4 rounded">
            <h2>Seller Profile Details</h2>
          </div>
          <form>
            <div className="setting-right">
              <div>
                <BasicInfo item={item} />
              </div>
              <div>
                <KitchenInfo item={item}/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SellerPage;
