import React from "react";
import { useEffect } from "react";
import { Base_Url } from "../../../misc";
import { useState } from "react";
import { Button, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { AiFillEye } from "react-icons/ai";
import MyPagination from "../Pagination/MyPagination";
export default function ReadyOrder() {
  const [orders, setOrders] = useState([]);
  const [riders, setRiders] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const getOrder = () => {
    fetch(`${Base_Url}/get-order-for-assign-rider`)
      .then((res) => res.json())
      .then((data) => setOrders(data.data));
  };

  useEffect(() => {
    getOrder();
  }, []);
  console.log(orders);

  useEffect(() => {
    setCurrentPage(1);
    let filtered = orders.filter((item) =>
      item?.orderNumber?.toLowerCase().includes(searchTerm)
    );

    // Check if there are no matching results and searchTerm is not empty
    if (searchTerm.trim() !== "" && filtered.length === 0) {
      filtered = [];
    }

    setFilteredData(filtered);
  }, [searchTerm, orders]);

  const [PAGE_SIZE, setPAGE_SIZE] = useState(20);
  // Pagination Logic
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentItems = (
    filteredData?.length > 0 ? filteredData : orders
  )?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData?.length > 0 ? filteredData?.length : orders?.length) /
      PAGE_SIZE
  );
  return (
    <>
      <div
        className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="input-group search-area2 style-1 w-[75%] border d-flex"
          style={{ marginRight: "15px" }}
        >
          <span className="input-group-text p-0">
            <Link to={"#"}>
              <svg
                className="me-1"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                  fill="#FC8019"
                />
              </svg>
            </Link>
          </span>

          <input
            type="text"
            className="form-control p-2 "
            placeholder="Filter by order"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="input-group ml-2 pl-2 style-1 w-50">
          {/* Search filter */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      </div>
      <div className="card h-auto" style={{ marginTop: 10 }}>
        <div className="card-body p-0">
          <div className="table-responsive">
            {orders === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Label</th>
                    <th>Status</th>
                    <th>Order Number</th>
                    <th>Total</th>
                    <th>Order Date</th>
                    <th>Payment Date</th>
                    {/* <th className='bg-none'>Assign</th> */}
                    <th className="bg-none">View details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => {
                    console.log(item);
                    return (
                      <tr key={index}>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p className="mb-0">
                                {index + 1 + PAGE_SIZE * (currentPage - 1)}
                              </p>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p className="mb-0">{item?.OrderLabel}</p>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p className="mb-0">{item?.orderStatus}</p>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p className="mb-0">{item?.orderNumber}</p>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p className="mb-0">{item?.orderTotalAmount}</p>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p className="mb-0">
                                {moment(item?.orderDate).format("MMMM Do YYYY")}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <p className="mb-0">
                                {moment(item?.paymentDate).format(
                                  "MMMM Do YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </td>
                        {/* <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <p className='mb-0'>

                                  <td>
                                    <div>
                                      <Button

                                        // onClick={()=>
                                        // //  handleApproved(item._id)
                                        // }
                                        className='btn btn-outline-primary'
                                      >
                                        Find Rider
                                      </Button>
                                    </div>
                                  </td>

                              </p>
                             
                            </div>
                          </div>
                        </td> */}

                        <td>
                          <Link
                            to={`/orderDetails/${item?._id}`}
                            // state={{ from: item }}>
                            state={"ready"}
                          >
                            <AiFillEye
                              size={45}
                              color="orange"
                              className="text-center"
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
}
