import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { Button } from "react-bootstrap";

import { Base_Url } from "../../../misc";
import { getUserDetails } from "../../../Helper/SessionHelper";
import Swal from "sweetalert2";
import labels from "./recipe.json";
import { useLocation, useParams } from "react-router-dom";



const FoodDairy = () => {

  const location = useLocation();
  const item = location.state;
  const params = useParams();

  const [EditorData, setEditorData] = useState();
  const [data, setData] = useState({
    title: "",
    seoMetaTitle:"",
    seoMetaDescription:"",
    seoMetaKeywords:""
  });
  const [diaryImageHandle, setDiaryImageHandle] = useState({});
  const [disable, setdisable] = useState(true);

  console.log(typeof currentFruit);

  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };


  const handleSeoMetaTitle = (e)=>{
      const newMetaData = {...data}
      newMetaData[e.target.name] = e.target.value;
      setData(newMetaData);

  }

  const handleSeoMetaDescription = (e)=>{

  }

  const handleSeoMetaKeywords=(e)=>{

  }

  const [selectedMetaImage, setSelectedMetaImage] = useState([]);
  const handleMetaFileChange = (event) => {
    const metaFile = Array.from(event.target.files);
    setSelectedMetaImage(metaFile);
  };

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const submit = async () => {
    console.log('mgs',data)
    let subdata = {

      userID: getUserDetails()._id,
      blogTitle: data.title,
      blogSlug: data.title,
      blogDesc: EditorData,
      blogImage: [],
      
    };

    // await ImageUploader(Seller, "Diary/seo", selectedMetaImage)
    const formData = new FormData();

    formData.append("folder", "Diary/" + "admin");

    formData.append("cover", diaryImageHandle);
    console.log(diaryImageHandle);
    fetch(`${Base_Url}/imguploads`, {
      method: "POST",
      body: formData,}
    )
    .then((data) => data.json())
    .then(async (response) => {
      console.log(response);
      subdata.blogImage = response?.images[0];
      if (response.status === 'Success') {
        console.log(response.status, subdata, "hhh");
        await axios
          .post(`${Base_Url}/update-food-diary/${params.id}`, subdata)
          .then((res) => {
            console.log(res.data);
            Swal.fire({

              icon: "success",
              title: "Food Diary updated successfully!!!",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }

    })
      .then((data) => data.json())
      .then(async (response) => {
        console.log(response);
        subdata.blogImage = response?.images[0];
        if (response.status === 'Success') {
          console.log(response.status, subdata, "hhh");
          await axios
            .post(`${Base_Url}/update-food-diary/${params.id}`, subdata)
            .then((res) => {
              console.log(res.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Food Diary updated successfully!!!",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div class='row'>
        <div class='col-xl-12 col-xxl-12'>
          <div class='card'>
            <div class='card-header'>
              <h4 class='card-title'>{item?.blogTitle}</h4>
            </div>
            <div class='card-body custom-ekeditor'>
              <div className='form-group mb-3'>
                <label>Title </label>
                <input
                  type='text'
                  className='form-control input-default '
                  placeholder='Enter Your Title'
                  label='Name:'
                  name='title'
                  value={data.title}
                  onChange={handleChange}
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='formFile' className='form-label'>
                  Upload a Thumbnail
                </label>
                <input
                  className='form-control'
                  type='file'
                  onChange={(e) => setDiaryImageHandle(e.target.files[0])}
                  id='formFile'
                />
              </div>
              {/* <h2>Using CKEditor 5 build in React</h2> */}
              <CKEditor
                editor={ClassicEditor}
                config={{
                  removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"],
                }}
                //   data={form.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorData(data);
                  // setForm((prev) => ({ ...prev, description: data }));
                  // console.log({ event, editor, data });
                }}
              />


              <div className="row d-flex">
                  <div className="col-xl-2">
                    <div className="contact-title">
                      <h4 className="mb-xl-0 mb-2">SEO </h4>
                      <span>
                        সার্চ ইঞ্জিন অপ্টিমাইজেশান (Search Engine Optimization)
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-10">
                    <div className="setting-input mb-3">
                      <label className="form-label">
                        {labels.metaTitle.en}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={labels.metaTitle.en}
                        name="metaTitle"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">
                        {labels.metaDescription.en}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={labels.metaDescription.en}
                        name="metaDescription"
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">
                        {labels.metaKeywords.en}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={labels.metaKeywords.en}
                        name="metaKeywords"
                      />
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="form-label">
                          {labels.metaImage.en}
                        </label>

                        <input
                          type="file"
                          className="form-control input-default "
                          placeholder={labels.metaImage.en}
                          label="metaImage"
                          name="metaImage"
                          // value={data?.price}
                          onChange={handleMetaFileChange}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        {selectedMetaImage && (
                          <div>
                            {selectedMetaImage?.map((item, index) => {
                              return (
                                <>
                                  <img
                                    className="img-fluid"
                                    alt="Meta Image"
                                    width={""}
                                    src={URL.createObjectURL(item)}
                                  />
                                  <br />
                                  <Button
                                    style={{ marginLeft: 25 }}
                                    onClick={() => setSelectedMetaImage(null)}
                                  >
                                    Remove
                                  </Button>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
            </div>


          </div>
        </div>
        <Button
          onClick={() => {
            submit();
          }}
        >
          {" "}
          Save{" "}
        </Button>
      </div>
    </>
  );
};

export default FoodDairy;
